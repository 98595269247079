import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MAKE_A_CALL_KEYS } from '../../../adapter-interfaces/src';
import { Logger, downloadMobileLogs, getLocalStorageItem, getMSTeamsContext } from '../../../logger/src';
import {
  WebexMobileSearchContacts,
  WebexMobileSearchContactsHandle
} from '../WebexMobileSearchContacts/WebexMobileSearchContacts';
import { CallButtons } from '../components/CallButtons';
import { NumberPad } from '../components/NumberPad';
import { useMakeCall } from '../hooks/useMakeCall';
import useWebexClasses from '../hooks/useWebexClasses';
import { WebexTokenEnum } from "../../../../ms-teams-sdk/service/MsTeamsAuthenticationService";


import { ButtonCircle, ButtonPill, Flex, IconNext } from '@momentum-ui/react-collaboration';
import { useTranslation } from 'react-i18next';
import './WebexMobileDialpad.scss';
// import { CLIENT_TYPE_IOS, CLIENT_TYPE_IPADOS, navigateToWebexUrl } from '../utils/commonUtils';
import { pages } from '@microsoft/teams-js';
import { IWebexIntContact } from '@webex/component-adapter-interfaces/dist/esm/src';
import { submitMetrics } from '../../src/services/WebexMetricsService';
import { IChannelData } from '../utils/CallBackUrl';
import { INTERNAL_OPERATIONS, MobileDialpad, MobileOpenWebexClickedMetric, audioCall, crossLaunchCallMetric, videoCall } from '../utils/MetricUtils';
import { navigateToWebexUrl } from '../utils/commonUtils';

type Props = {
  style?: React.CSSProperties;
  src: string;
  user?: IWebexIntContact[];
  channelData?: IChannelData;
  clientType: string;
};

/**
 * Full dialpad with searchable input, number pad, and calling buttons
 *
 * @param root0
 * @param root0.style
 * @returns {JSX.Element} React component
 */
export const WebexMobileDialpad = ({style = undefined, src, user, channelData, clientType}: Props) => {
  const [cssClasses, sc] = useWebexClasses('dialpad-widget');
  const searchContactsRef = useRef<WebexMobileSearchContactsHandle>(null);
  const [callAddress, setCallAddress] = useState<string>('');
  const { t } = useTranslation();
  const [callDisabled, setCallDisabled] = useState<boolean>(false);
  const [makeACallToken, setMakeACallToken] = useState<string>('');
  const [makeCall] = useMakeCall();
  const onButtonPress = (value: string) => {
    searchContactsRef.current?.appendValueToInput(value);
  };

  const backSpaceAction = () => {
  searchContactsRef.current?.backSpaceAction();
  }; 
  
  const callButtonPressAction = () => {
    searchContactsRef.current?.callButtonPressAction();
  };

  const onInputChange = useCallback(
    (input: string) => {
      setCallDisabled(false);
      setCallAddress(input);
    },
    [setCallDisabled, setCallAddress]
  );

  const onClickOpenWebex = () => {
    const url = navigateToWebexUrl;
    const webexUrl = new URL(url);
    window.open(webexUrl.href);
    submitMetrics(
      {
        metricEvent: MobileOpenWebexClickedMetric
      }
    );
  }
  const fetchClientType = async () => {
    const context = await getMSTeamsContext();
    const webex_orgId = getLocalStorageItem<string>(WebexTokenEnum.ORG_ID);
    if(context?.page?.subPageId) {
      const botObject = context.page.subPageId.split(" ");
      if(webex_orgId == "b6ee0aee-bcce-4324-a3c5-4243a7837c84" && botObject[0] === "BotService") {
        Logger.info(`Deep linking happened via Bot Service: ${context.page.subPageId}`);
        makeCrossLaunchCall(botObject[1], botObject[2].toLowerCase() === 'true');
      }
      else {
        Logger.info(`Deep linking not happened via Bot Service: ${context.page.subPageId}`); 
      }
    }
    else {
      Logger.info(`Deep linking failed: ${context.page.subPageId}`);
    }
  }

  useEffect(() => {
    fetchClientType();
  }, []);


  const makeCrossLaunchCall = (address: string, video?: boolean) => {
    if(address) {
      console.log(address);
      if(address.toLowerCase() === INTERNAL_OPERATIONS.SAVE.toLowerCase()) {
        downloadMobileLogs();
      }
      else {
        address = address.replace(/ /g, "");
        const url = getCallingUrl(address, video); 
        const callUrl = new URL(url);
        // To Be Removed Later once cross launch callback support available for android
        // if (clientType == CLIENT_TYPE_IOS || clientType == CLIENT_TYPE_IPADOS) {
        if(src === 'chatExtension') {
          pages.backStack.navigateBack();
        }
        // }
        Logger.info(
          `Making ${video ? 'video' : 'audio'} call to url ${url}`
        );
        window.open(callUrl.href);
        submitMetrics(
          {
            metricEvent: crossLaunchCallMetric,
            callMadeSection: `${MobileDialpad}`,
            callType: `${video? videoCall : audioCall}` 
          }
        );
        callButtonPressAction();
    }
  }
};

  const getCallingUrl =  (address: string, video?: boolean) => {
    const url = 'webextel://login';
    const callUrl = new URL(url);
    callUrl.searchParams.append('telephone', address);
    const crossLaunchMain = `msteams://teams.microsoft.com/l/entity/${process.env.INTEGRATION_APP_ID}/webexCallTab?context=%7B%22subEntityId%22%3A%22mobileDialpad%22%7D`;
    let crossLaunchExtension = `msteams://teams.microsoft.com`;
    if(channelData && channelData.channelId) {
      crossLaunchExtension = `msteams://teams.microsoft.com/l/channel/${channelData.channelId}/${channelData.channelName}?groupId=${channelData.groupId}&tenantId=${channelData.tenantId}`
    }
    else if(user && user.length > 1) {
      crossLaunchExtension = "msteams://teams.microsoft.com/l/chat/0/0?users=";
      user.map((user)=> (
        crossLaunchExtension = (crossLaunchExtension + user.emailAddresses[0].address + ',')
      ));
      crossLaunchExtension = crossLaunchExtension.slice(0, -1);
    }
    
    // TODO: Add cross launch back MS Teams
    // callUrl.searchParams.append('x-callback-url', `${src ==='main' ? crossLaunchMain : crossLaunchExtension}`);
    callUrl.searchParams.append('x-source', "MS Teams Integration");
    // callUrl.searchParams.append('x-success', `${src ==='main' ? crossLaunchMain : crossLaunchExtension}`);
    // callUrl.searchParams.append('x-cancel', `${src ==='main' ? crossLaunchMain : crossLaunchExtension}`);
    callUrl.searchParams.append('autoJoin', "true");
    callUrl.searchParams.append('videoCall', `${video ? "true" : "false"}`);
    callUrl.searchParams.append('mutedCall', "false");
    callUrl.searchParams.append('audioOnlyCall', "false");

    Logger.info(`Calling URL: ${callUrl.href}`);
    return callUrl.href;
}
  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(MAKE_A_CALL_KEYS.MAKE_A_CALL_TOKEN);
      if (stringValue) {
        setMakeACallToken(stringValue);
      } else {
        setMakeACallToken('');
      }
    };
    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  return (
    <div className={cssClasses} style={style}>
      <WebexMobileSearchContacts
        ref={searchContactsRef}
        onInputChange={onInputChange}
        onDropdownHide={() => setCallDisabled(false)}
        onUserSelect={(user) => setCallDisabled(!!user)}
        makeCall = {makeCrossLaunchCall}
      />

      <NumberPad onButtonPress={onButtonPress} />
      <Flex justifyContent="flex-end" xgap="1.2rem">
      <CallButtons
        address={callAddress}
        className={sc('call-buttons')}
        disabled={callDisabled}
        useMakeAudioCall={() => makeCrossLaunchCall(callAddress, false)}
        useMakeVideoCall={() => makeCrossLaunchCall(callAddress, true)}
      />
      <div className={sc('backspace-delete')}>
      <ButtonCircle className={sc('backspace-delete-button')} onPress={backSpaceAction} size={28} ghost key="1" aria-label={t('deleteButton')}>
          <IconNext scale={18} name="backspace" weight="bold" />
      </ButtonCircle>
      </div>
      </Flex>
      <div className= {sc('open-webex-button')}>      
         <ButtonPill ghost outline size = {40} className={sc('open-webex')} onPress={onClickOpenWebex}>
            <span className= {sc('open-webex-text')} >{ t('openWebex') }</span>
         </ButtonPill>
      </div>
        <span className= {sc('info-text')}>{ t('infoText') }</span>
      <div>
      </div>
    </div>
  );
}; 