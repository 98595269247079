import { useCallback, useContext, useEffect, useState } from "react";
import { AdapterContext } from "../contexts/AdapterContext";
/**
 * Custom hook that returns callforward data for a given user.
 *
 * @returns {object}
 */
export default function useCallForwardSetting() {
  const [callForwardSettings, setCallForwardSettings] = useState<string>('');
  const [isAttendantConsoleFeatureEnabled, setIsAttendantConsoleFeatureEnabled] = useState<boolean | null>(null);
  const [isCXEssentialsLicensedUser, setIsCXEssentialsLicensedUser] = useState<boolean | null>(null);


  const ctx = useContext(AdapterContext);

  const onCallForwardUpdate = useCallback((data:string) => {
    setCallForwardSettings(data);
  }, []);

  useEffect(() => {
    const subscription = ctx?.callForwardAdapter
      ?.getCallForwardSettings()
      .subscribe({ next: onCallForwardUpdate });
    return () => {
      subscription?.unsubscribe();
    };
  }, [ctx?.callForwardAdapter, onCallForwardUpdate]);

  const refreshCallForward = useCallback(async () => {
    if (ctx?.callForwardAdapter?.refreshCallForward) {
      console.log("callforward refreshing function");
      return ctx?.callForwardAdapter?.refreshCallForward();
    }
    return Promise.resolve();
  }, [ctx?.callForwardAdapter]);

  const onAttendentConsoleUpdate = useCallback((data:boolean) => {
    setIsAttendantConsoleFeatureEnabled(data);
  }, []);

  const onCXEssentialsUpdate = useCallback((data:boolean) => {
    setIsCXEssentialsLicensedUser(data);
  }, []);

  useEffect(() => {
    const featureToggleSubscription = ctx?.callForwardAdapter?.checkAttendantConsoleFeatureToggle()
      ?.subscribe({ next: onAttendentConsoleUpdate });
    return () => {
      if (featureToggleSubscription) {
        featureToggleSubscription.unsubscribe();
      }
    };
  }, [ctx?.callForwardAdapter, onAttendentConsoleUpdate]);
  
  useEffect(() => {
    const cxessentialfeatureSubscription = ctx?.callForwardAdapter?.checkCXEssentialsFeature()
      ?.subscribe({ next: onCXEssentialsUpdate });
    return () => {
      if (cxessentialfeatureSubscription) {
        cxessentialfeatureSubscription.unsubscribe();
      }
    };
  }, [ctx?.callForwardAdapter, onCXEssentialsUpdate]);

  return {
    callForwardSettings,
    refreshCallForward,
    isAttendantConsoleFeatureEnabled,
    isCXEssentialsLicensedUser
  } as const;
}
