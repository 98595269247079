import { Logger } from "../../../../packages/logger/src";
import { setLocalStorageItem } from "../../../logger/src";
import { VM_STORAGE_KEYS} from "../../../adapter-interfaces/src";
export const VALUES = '_values';
export const KEY = 'key';
export const ENTITLEMENT_BASIC = 'bc-sp-basic';
export const ENTITLEMENT_STANDARD = 'bc-sp-standard';
export const ENTITLEMENT_BROADWORKS_CONN = 'broadworks-connector';
export const NATIVE_SIP_CALL_TO_UCM = 'NATIVE_SIP_CALL_TO_UCM';
export const NATIVE_WEBEX_TEAMS_CALLING = 'NATIVE_WEBEX_TEAMS_CALLING';
export const FEATURES_KEY_TYPE_DEVELOPER = "developer";
export const FEATURE_TOGGLE_BROADWORKS_VM_ENABLED = "broadworks-voicemail-enabled-spark-541886";
export const dispatchStorageEvent = () => window.dispatchEvent(new Event("storage"));

export const setLocalStoragewithDispatchEvent = (key: string, value: any) => {
    setLocalStorageItem(key, value);
    dispatchStorageEvent();
};

export type Model = {
    _values: {
        key: string;
    };
};

export enum CALLING_BACKEND {
    WXC = 'WEBEX_CALLING',
    BWRKS = 'BROADWORKS_CALLING',
    UCM = 'UCM_CALLING',
    INVALID = 'Calling backend is currently not supported',
}


export const VM_BROADWORKS_RESPONSE = {
    "statusCode": 300,
    "data": {
        "error": "Voicemail feature not supported for Broadworks user"
    },
    "message": "FAILURE"
}

export const CALLING_BACKEND_NOT_IDENTIFIED_RESPONSE = {
    "statusCode": 302,
    "data": {
        "error": "Calling backend is not identified, exiting...."
    },
    "message": "FAILURE"
}

export function getCallingBackEnd(): CALLING_BACKEND {
    const entModels: Model[] = window.webex.internal.device.features.entitlement.models;

    let callingBackend;

    if (window.webex.internal.device.callingBehavior === NATIVE_WEBEX_TEAMS_CALLING) {
        for (let i = 0; i < entModels.length; i += 1) {
            if (
                entModels[i][VALUES][KEY] === ENTITLEMENT_BASIC ||
                entModels[i][VALUES][KEY] === ENTITLEMENT_STANDARD
            ) {
                callingBackend = CALLING_BACKEND.WXC;
                break;
            } else if (entModels[i][VALUES][KEY] === ENTITLEMENT_BROADWORKS_CONN) {
                callingBackend = CALLING_BACKEND.BWRKS;
                break;
            }
        }
    } else if (window.webex.internal.device.callingBehavior === NATIVE_SIP_CALL_TO_UCM) {
        callingBackend = CALLING_BACKEND.UCM;
    } else {
        callingBackend = CALLING_BACKEND.INVALID;
    }
    Logger.info(`Calling Behaviour is ${callingBackend}`);
    setLocalStorageItem(
        "webex_userCallingBackend",
        callingBackend
      );
    return callingBackend as CALLING_BACKEND;
}

 /*  
    * Check if feature toggle is enabled/disabled: broadworks-voicemail-enabled-spark-541886
    * returns true if FT is enabled, false if not 
 */
export async function setVoicemailForBroadworks(): Promise<boolean> {
    let isBroadworksVoicemailTabEnabled = false;
    if (window?.isWebexInitalized) {
        isBroadworksVoicemailTabEnabled = await window.webex.internal.feature.getFeature(FEATURES_KEY_TYPE_DEVELOPER, FEATURE_TOGGLE_BROADWORKS_VM_ENABLED);
    }
    return !!isBroadworksVoicemailTabEnabled;
}
