export interface IPhoneNumber {
  type: string;
  value: string | null;
  primary: boolean;
}

export interface IBusinessNumberResponse {
  id: string;
  emails: string[];
  phoneNumbers: IPhoneNumber[];
  displayName: string;
  nickName: string;
  firstName: string;
  lastName: string;
  userName: string;
  orgId: string;
  created: string;
  lastModified: string;
  lastActivity: string;
  status: string;
  type: string;
}

export interface IErrorResponse {
  response?: IResponseData;
}

export interface IResponseData {
  status?: string;
}

export enum MAKE_A_CALL_KEYS {
  MAKE_A_CALL_TOKEN = "makeAcallToken",
  CALL_FORWARD_SETTING_INTERVAL = "callForwardSettingInterval",
  DO_NOT_FORWARD_CALL = "Do not forward calls",
}

export enum UserProfileMenuItemsKeys {
  SIGN_OUT = 'signout',
  ATTENDANT_CONSOLE = "attendantConsole",
  CX_ESSENTIALS = "cxEssentials",
  E911 = "e911",
  CALLING_WIDGET = "callingWidget",
} 