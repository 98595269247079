import {
  BadgeNext as Badge,
  Flex,
  TabNext as Tab,
  Text,
} from "@momentum-ui/react-collaboration";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocalStorageItem, setLocalStorageItem } from "../../ms-teams-sdk/util/LocalStorageUtil";
import { getMSTeamsContext } from "../../ms-teams-sdk/util/MsTeamsContextUtil";
import { VM_STORAGE_KEYS } from "../../packages/adapter-interfaces/src";
import { Logger } from "../../packages/logger/src/Logger";
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";
import { activeTabBarMetric } from "../../packages/widgets/src/utils/MetricUtils";
import {
  WEBEX_CALLING_THEME,
  THEME_CLASS,
} from "../../packages/widgets/src/utils/ThemeUtils";
import { useMsTeamsTheme } from "../../hooks/useMsTeamsTheme";
import "./TabBar.scss";
import { makeCrossLaunchCall } from "../../ms-teams-sdk/service/CrossLaunchService";
import { WebexTokenEnum } from "../../ms-teams-sdk/service/MsTeamsAuthenticationService";

export interface ITabBarProps {
  children: React.ReactElement<ITabBarItemProps | undefined>[];
}

export interface ITabBarItemProps {
  label: string;
  badgeCount?: number;
  children: JSX.Element;
  onTabPress?: () => void;
  onActiveRightItem?: React.ReactElement;
}

/**
 *
 * @param props
 */
export function TabBarItem(props: ITabBarItemProps): JSX.Element {
  return props.children;
}

/**
 *
 * @param root0
 * @param root0.items
 * @param root0.children
 * @param root0.rest
 */

export const TabBar = ({ children }: ITabBarProps): JSX.Element => {
  const filteredChildren = children.filter((child) => child);
  const { t } = useTranslation();
  const [theme] = useMsTeamsTheme();
  const [focusedLabel, setFocusedLabel] = useState<string>("");
  const [activeTabs, setActiveTabs] = useState<string[]>([
    t("recentCalls"),
    t("dialpad"),
  ]);
  const tabMappings = {
    [t("voicemail")]: [t("voicemail"), t("dialpad")],
    [t("recentCalls")]: [t("recentCalls"), t("dialpad")],
  };

  if (filteredChildren.length === 0) {
    throw new Error("No tabs available for TabBar");
  }

  const fetchClientType = async () => {
    const context = await getMSTeamsContext();
    if (context?.page?.subPageId) {
      if (tabMappings[context.page.subPageId]) {
        const selectedTabs = tabMappings[context.page.subPageId];
        Logger.info(
          `Tab is selected through deepLinking: ${context.page.subPageId}`
        );
        setActiveTabs(selectedTabs);
      }
      else {
        Logger.info(
          `Deep linking failed. Default tabs selected: ${context.page.subPageId}`
        );
        setActiveTabs([t("recentCalls"), t("dialpad")]);
      }
    } else {
      Logger.info(`Default tabs selected on page load without deep linking`);
      setActiveTabs([t("recentCalls"), t("dialpad")]);
    }
  };

  const onTabPressCallback = (label: string, onTabPress?: () => void) => {
    if (onTabPress && !activeTabs.includes(label)) {
      onTabPress();
    }
    submitMetrics({
      metricEvent: activeTabBarMetric,
      activeTab: `${label}`,
    });
    setFocusedLabel(label);
    setActiveTabs([label]);
  };

  const sectionRef = useRef<HTMLDivElement>(null);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);
  const onTabFocus = (label: string): void => {
    setFocusedLabel(label);
  };

  useEffect(() => {
    // Calculate the badge count for recent calls
    const recentCallsBadgeCount = filteredChildren.reduce(
      (accumulator, child) => {
        return child?.props?.label === t("recentCalls")
          ? accumulator + (child?.props?.badgeCount || 0)
          : accumulator;
      },
      0
    );
    // Calculate the badge count for voicemails
    const voicemailBadgeCount = filteredChildren.reduce(
      (accumulator, child) => {
        return child?.props?.label === t("voicemail")
          ? accumulator + (child?.props?.badgeCount || 0)
          : accumulator;
      },
      0
    );
    if (focusedLabel === t("dialpad") && !activeTabs.includes(focusedLabel)) {
      setFocusedLabel(t("label.dialpad.tab"));
    } else if (
      focusedLabel === t("dialpad") &&
      activeTabs.includes(focusedLabel)
    ) {
      setFocusedLabel(t("label.dialpad.selected.tab"));
    } else if (
      focusedLabel === t("speedDial") &&
      !activeTabs.includes(focusedLabel)
    ) {
      setFocusedLabel(t("label.speedDial.tab"));
    } else if (
      focusedLabel === t("speedDial") &&
      activeTabs.includes(focusedLabel)
    ) {
      setFocusedLabel(t("label.speedDial.selected.tab"));
    } else if (
      focusedLabel === t("recentCalls") &&
      !activeTabs.includes(focusedLabel)
    ) {
      if (recentCallsBadgeCount > 0) {
        if (recentCallsBadgeCount === 1) {
          setFocusedLabel(
            `${t("label.recentCalls.tab.withBadge", {
              count: recentCallsBadgeCount,
            })}`
          );
        } else {
          setFocusedLabel(
            `${t("label.recentCalls.tab.withMultipleBadges", {
              count: recentCallsBadgeCount,
            })}`
          );
        }
      } else {
        setFocusedLabel(t("label.recentCalls.tab"));
      }
    } else if (
      focusedLabel === t("recentCalls") &&
      activeTabs.includes(focusedLabel)
    ) {
      if (recentCallsBadgeCount > 0) {
        if (recentCallsBadgeCount === 1) {
          setFocusedLabel(
            `${t("label.recentCalls.selected.tab.withBadge", {
              count: recentCallsBadgeCount,
            })}`
          );
        } else {
          setFocusedLabel(
            `${t("label.recentCalls.selected.tab.withMultipleBadges", {
              count: recentCallsBadgeCount,
            })}`
          );
        }
      } else {
        setFocusedLabel(t("label.recentCalls.selected.tab"));
      }
    } else if (
      focusedLabel === t("voicemail") &&
      !activeTabs.includes(focusedLabel)
    ) {
      if (voicemailBadgeCount > 0) {
        if (voicemailBadgeCount === 1) {
          setFocusedLabel(
            t("label.voicemail.tab.withBadge", { count: voicemailBadgeCount })
          );
        } else {
          setFocusedLabel(
            `${t("label.voicemail.tab.withMultipleBadges", {
              count: voicemailBadgeCount,
            })}`
          );
        }
      } else {
        setFocusedLabel(t("label.voicemail.tab"));
      }
    } else if (
      focusedLabel === t("voicemail") &&
      activeTabs.includes(focusedLabel)
    ) {
      if (voicemailBadgeCount > 0) {
        if (voicemailBadgeCount === 1) {
          setFocusedLabel(
            t("label.voicemail.selected.tab.withBadge", {
              count: voicemailBadgeCount,
            })
          );
        } else {
          setFocusedLabel(
            `${t("label.voicemail.selected.tab.withMultipleBadges", {
              count: voicemailBadgeCount,
            })}`
          );
        }
      } else {
        setFocusedLabel(t("label.voicemail.selected.tab"));
      }
    }
  }, [focusedLabel, activeTabs, filteredChildren]);

  useEffect(() => {
    fetchClientType();
  }, []);

  useEffect(() => {
    const checkScrollbar = () => {
      if (sectionRef.current?.previousElementSibling) {
        const sectionContentHeight =
          sectionRef.current?.previousElementSibling.scrollHeight;
        const sectionContainerHeight =
          sectionRef.current?.previousElementSibling.clientHeight;
        const scrollbarVisible = sectionContentHeight > sectionContainerHeight;
        setIsScrollbarVisible(scrollbarVisible);
      }
    };

    // Call the checkScrollbar function initially to determine the initial scrollbar state
    checkScrollbar();

    // Use ResizeObserver to watch for changes in the section's size
    const resizeObserver = new ResizeObserver(checkScrollbar);
    if (sectionRef.current?.previousElementSibling) {
      resizeObserver.observe(sectionRef.current?.previousElementSibling);
    }

    // Detach the ResizeObserver on unmount
    return () => {
      if (sectionRef.current?.previousElementSibling) {
        resizeObserver.unobserve(sectionRef.current?.previousElementSibling);
      }
    };
  }, []);

  return (
    <Flex className="tab-bar-container" direction="column" alignItems="stretch">
      <Flex alignItems="center" xgap="1rem" className="tab-bar">
        {filteredChildren.map((child) => {
          const { label, badgeCount, onTabPress } = child.props!;
          const isActive = activeTabs.includes(label);
          return (
            <Tab
              key={label}
              active={isActive}
              onPress={() => onTabPressCallback(label, onTabPress)}
              onFocus={() => onTabFocus(label)}
              aria-label={focusedLabel}
            >
              {badgeCount ? (
                <Badge size={18}>{`${badgeCount}`}</Badge>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}
              <Text
                type="subheader-secondary"
                className={`${isActive ? `enabled-color` : `disabled-color`}`}
              >
                {label}
              </Text>
            </Tab>
          );
        })}
        <div style={{ flexGrow: 1 }} />
        {filteredChildren
          .filter((child) => {
            if (activeTabs.includes(t("voicemail"))) {
              setLocalStorageItem(VM_STORAGE_KEYS.VM_TAB, true);
            } else if (activeTabs.includes(t("recentCalls"))) {
              setLocalStorageItem(VM_STORAGE_KEYS.VM_TAB, false);
            }
            return (
              activeTabs.includes(child.props?.label as string) &&
              child.props?.onActiveRightItem
            );
          })
          .map((child) => (
            <React.Fragment key={`${child.props?.label}-action`}>
              {child.props?.onActiveRightItem}
            </React.Fragment>
          ))}
      </Flex>
      {filteredChildren.map((child) => {
        return (
          <div
            ref={sectionRef}
            style={{
              display: activeTabs.includes(child.props!.label)
                ? "block"
                : "none",
              overflowY:
                isScrollbarVisible && activeTabs.includes(t("dialpad"))
                  ? "scroll"
                  : "auto",
              paddingLeft:
                isScrollbarVisible && activeTabs.includes(t("dialpad"))
                  ? "15.5px"
                  : "0",
              marginRight: "0.25rem",
            }}
            key={child.props!.label}
            className={`tab-bar-content ${
              child.props!.label !== activeTabs[0] ? "hidden" : ""
            } ${
              theme === WEBEX_CALLING_THEME.DARK
                ? THEME_CLASS.DARK
                : theme === WEBEX_CALLING_THEME.LIGHT
                ? THEME_CLASS.LIGHT
                : " "
            }`}
          >
            {child.props!.children}
          </div>
        );
      })}
    </Flex>
  );
};
