import { Observable } from 'rxjs';

// Define an interface for the payload within the data
export interface SubscriptionPayload {
  status: string;
}

// Define an interface for the data object
export interface SubscriptionData {
  eventType: string;
  id: string;
  subscriptionId: string;
  payload: SubscriptionPayload;
}

// Define an interface for the deviceRecipients object
export interface SubscriptionDeviceRecipients {
  alertType: string;
  headers: Record<string, any>; // Assuming headers can be any key-value pair
  deviceUrl: string;
}

// Define the main interface for the entire JSON structure
export interface SubscriptionEventResponse {
  data: SubscriptionData;
  deviceRecipients: SubscriptionDeviceRecipients;
  type: string;
}

export interface ISubscriptionEventData {
  eventType: string;
  id: string;
  subscriptionId: string;
  status: string;
}

export const INITIAL_SUBSCRIPTION_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
  status: '',
}

export const INITIAL_PHONE_SERVICES_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
  status: '',
}

export const INITIAL_ERROR_SUBSCRIPTION_DATA = {
  eventType: '',
  id: '',
  subscriptionId: '',
  errorCode: '',
  errorDescription: ''
}

// Define the Payload interface for the integration.error event
export interface IntegrationErrorPayload {
  error: string; // Error code as a string
  description: string; // Error description
}

// Define the Data interface for the integration.error event
export interface IntegrationErrorData {
  eventType: "integration.error"; // Specific event type
  id: string; // Unique identifier for the event
  payload: IntegrationErrorPayload; // Error details
  subscriptionId?: string;
}

// Define the DeviceRecipient interface for the integration.error event
export interface IntegrationErrorDeviceRecipient {
  alertType: string; // Type of alert, e.g., "none"
  headers: Record<string, string>; // Headers as key-value pairs
  deviceUrl: string; // URL pointing to the device
}

// Define the main interface for the integration.error publish request
export interface IntegrationErrorResponse {
  data: IntegrationErrorData; // Event data
  deviceRecipients: IntegrationErrorDeviceRecipient[]; // List of device recipients
  type: string; // Type of request, e.g., "publishRequest"
}

export interface IIntegrationErrorEventData {
  eventType: string;
  id: string;
  subscriptionId?: string;
  errorCode: string;
  errorDescription: string;

}

// Define constants for event types
export const enum DATA_PIPE_LINE_EVENTS {
  INTEGRATION_SUBSCRIPTION = 'event:integration.subscription',
  INTEGRATION_PHONE_SERVICES = 'event:integration.phoneservices',
  INTEGRATION_SETTINGS = 'event:integration.settings',
  INTEGRATION_E911 = 'event:integration.e911',
  INTEGRATION_ERROR = 'event:integration.error',
};

export interface IntegrationPhoneServicesLine {
  GUID: string;
  index: number;
  label: string;
  name: string;
  number: string;
  status: string;
}

export interface IntegrationPhoneServicesDetails {
  actions: any[];
  message: string;
}

export interface IntegrationPhoneServicesPayload {
  details: IntegrationPhoneServicesDetails;
  lines: IntegrationPhoneServicesLine[];
  status: string;
}

export interface IntegrationPhoneServicesData {
  eventType: string;
  id: string;
  payload: IntegrationPhoneServicesPayload;
  subscriptionId: string;
}

export interface IntegrationPhoneServicesResponse {
  id: string;
  data: IntegrationPhoneServicesData;
  timestamp: number;
  trackingId: string;
  alertType: string;
  headers: Record<string, any>;
  sequenceNumber: number;
  filterMessage: boolean;
  wsWriteTimestamp: number;
}

export interface IPhoneServicesEventData {
  eventType: string;
  id: string;
  subscriptionId: string;
  status: string;
}


export interface IEventAdapter {
  refreshSubscriptionStatus(): void;
  getSubscriptionData(): Observable<ISubscriptionEventData>;
  refreshPhoneServicesSubscriptionStatus(): void;
  getPhoneServicesData(): Observable<IPhoneServicesEventData>;
  refreshErrorSubscriptionStatus(): void;
  getErrorEventData(): Observable<IIntegrationErrorEventData>
}