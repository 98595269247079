import { ICallForwardSettingValueResponse } from "@webex/component-adapter-interfaces/dist/esm/src";
// import { createCallSettingsClient } from "@webex/web-calling-sdk";
import { createCallSettingsClient } from '@webex/calling';
import { LOGGER, Logger, formatError } from "../../../../packages/logger/src";
import { IBusinessNumberResponse } from "../../../adapter-interfaces/src/IBusinessNumber";
import { WORK_TYPE } from "../utils/MetricUtils";

const ENTITLEMENT_WXC_AC_ADVANCED = "wxc-ac-advanced";

async function initCallSettingslList() {
  const booleanVal = process.env.WEBEX_CF_BOOLEAN_VAL;
  const callForwardBoolVal = (booleanVal == "true" ? true : false);
  const callSettingsClient = createCallSettingsClient((window as any)?.webex, {
    level: LOGGER.INFO,
  },
  callForwardBoolVal
  );
  const myUserDetails: IBusinessNumberResponse = await window?.webex?.people._getMe();
  const businessnum = myUserDetails?.phoneNumbers?.find(phoneNumber => phoneNumber?.type === WORK_TYPE.ITEM_TYPE);
  const businessVal = businessnum?.value ? businessnum?.value.replace(/[+\s-]/g, "") : '';
  Logger.info(`business Num ${businessVal}`);
  const response = await callSettingsClient.getCallForwardAlwaysSetting(businessVal);
  const res = response as ICallForwardSettingValueResponse;
  return res as ICallForwardSettingValueResponse
}

export async function getCallSetting(): Promise<ICallForwardSettingValueResponse> {
  if (window?.isWebexInitalized) {
    return initCallSettingslList();
  } else {
    await new Promise((f) => setTimeout(f, 1000));
    return getCallSetting();
  }
}

/**
 * Initializes the Attendant Console feature toggle.
 * This function checks if the Attendant Console feature is enabled for the user.
 * It checks if the user has the required entitlement.
 * 
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the Attendant Console feature is enabled.
 */
async function initAttendantConsoleFeatureToggle() {
  let entitlementKeyFound = false;
  const entitlementModels = window.webex.internal.device.features.entitlement.models;
  for (const model of entitlementModels) {
    if (model._values['key'] === ENTITLEMENT_WXC_AC_ADVANCED) {
      entitlementKeyFound = true;
      break;
    }
  }
  Logger.info(`Attendant console entitlement key found: ${entitlementKeyFound}`);
  const isAttendantConsoleFeatureEnabled = entitlementKeyFound;
  Logger.info(`Is attendant console Feature Enabled: ${isAttendantConsoleFeatureEnabled}`);
  return isAttendantConsoleFeatureEnabled;
}

/**
 * Retrieves the Attendant Console feature toggle.
 * This function ensures that Webex is initialized before attempting to retrieve the feature toggle.
 * If Webex is not initialized, it waits for a second and retries.
 * 
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the Attendant Console feature is enabled.
 */
export async function getAttendantConsoleFeatuteToggle(): Promise<boolean> {
  if (window?.isWebexInitalized) {
    return initAttendantConsoleFeatureToggle();
  } else {
    await new Promise((f) => setTimeout(f, 1000));
    return getAttendantConsoleFeatuteToggle();
  }
}

/**
 * Initializes the CX-Essential feature toggle.
 * This function checks if the CX-Essentia feature is enabled for the user.
 * It checks if the user has the required license.
 *
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the CX-Essentia feature is enabled.
 */
async function initCXEssentialsLicensedUser() {
  let isCXEssentialsLicensedUser = false;

  try {
    const licenseForCXEssentials = await window.webex.internal.device.productMode;

    if (licenseForCXEssentials?.cxessential) {
      isCXEssentialsLicensedUser = true;
    }

    Logger.info(`Is CX-Essentials enabled for a user: ${isCXEssentialsLicensedUser}`);
  } catch (error) {
    Logger.error(
      `Is CX-Essentials enabled for a user: got error: '${formatError(
        error
      )}'.`
    );
  }
  return isCXEssentialsLicensedUser;
}

/**
 * Retrieves the CX-Essentials feature.
 * This function ensures that Webex is initialized before attempting to retrieve the feature toggle.
 * If Webex is not initialized, it waits for a second and retries.
 *
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the CXEssentials feature is enabled.
 */
export async function getCXEssentialsFeature(): Promise<boolean> {
  if (window?.isWebexInitalized) {
    return initCXEssentialsLicensedUser();
  } else {
    await new Promise((f) => setTimeout(f, 1000));
    return getCXEssentialsFeature();
  }
}
