import {
  BadgeNext as Badge,
  ButtonCircle,
  ButtonPill,
  Flex,
  IconNext as Icon,
  MenuNext as Menu,
  MenuTrigger,
  Toggle
} from "@momentum-ui/react-collaboration";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "react-stately";
import { getBusinessNumber } from "../../ms-teams-sdk/service/MsTeamsAuthenticationService";
import { CallSettingsURL, attendantConsoleURL, cxEssentialsURL, e911URL } from "../../ms-teams-sdk/util/TobarUtils";
import { IBusinessNumberResponse, UserProfileMenuItemsKeys } from "../../packages/adapter-interfaces/src/";

import { Logger, formatError, removeLocalStorageItem } from "../../packages/logger/src";
import useCallForwardSetting from "../../packages/widgets/src/hooks/useCallForwardSettings";
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";
import { FIRST_TIME_DOWNLOAD, WORK_TYPE, callSettingsClickedMetric, signOutMetric } from "../../packages/widgets/src/utils/MetricUtils";
import { CallForward } from '../CallForward/CallForward';
import "./TopBar.scss";
import { CallingWidgetModal } from "../CallingWidgetModal/CallingWidgetModal";
import { CallingWidgetOffModal } from "../CallingWidgetOffModal/CallingWidgetOffModal";

type ITopBarProps = {
  myNumberLabel?: string;
  myNumber?: string;
  settingsLabel?: string;
  onSettingsPress?: () => void;
  onSignOutPress?: () => void;
};

/**
 * @description The summary of this component.
 * @param {string} name The name of the property
 */
export const TopBar = ({ }: ITopBarProps) => {
  const [myNumber, setBusinessNumber] = useState<string>("");
  const { t } = useTranslation();
  const [shouldOpenURL, setShouldOpenURL] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCallsettingsClicked, setIsCallsettingsClicked] = useState(false);
  const { isAttendantConsoleFeatureEnabled, isCXEssentialsLicensedUser } = useCallForwardSetting();
  const [isShowCallingWidgetModal, setIsShowCallingWidgetModal] = useState(false)
  const [isShowCallingWidgetOffModel, setIsShowCallingWidgetOffModal] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onSignOutPress = () => {
    submitMetrics(
      {
        metricEvent: signOutMetric
      }
    );
    // Remove all local storage items except FIRST_TIME_DOWNLOAD and SPEED_DIAL_CACHED_DATE key so that user does not see the download page every time he does log in
    // const keysToKeep = [FIRST_TIME_DOWNLOAD, SPEED_DIAL_CACHED_DATE];
    const keysToKeep = [FIRST_TIME_DOWNLOAD];
    const allKeys = Object.keys(localStorage);
    allKeys.forEach((key) => {
      if (!keysToKeep.includes(key)) {
        removeLocalStorageItem(key);
      }
    });
    window.location.reload();
  }

  const handleAction = useCallback((key: React.Key) => {
    switch (key) {
      case UserProfileMenuItemsKeys.SIGN_OUT:
        if (onSignOutPress) {
          onSignOutPress();
        }
        break;
      case UserProfileMenuItemsKeys.ATTENDANT_CONSOLE:
        Logger.info(`Cross-launching the ATTENDANT_CONSOLE URL", ${attendantConsoleURL}`);
        window.open(`${attendantConsoleURL}`);
        break;
      case UserProfileMenuItemsKeys.CX_ESSENTIALS:
        Logger.info(`Cross-launching the CX_ESSENTIALS URL...", ${cxEssentialsURL}`);
        window.open(`${cxEssentialsURL}`);
        break;
      case UserProfileMenuItemsKeys.E911:
        Logger.info(`Cross-launching the E911 URL...", ${e911URL}`);
        window.open(`${e911URL}`);
        break;
      default:
        throw new Error('Cannot find action');
    }
  }, []);

  const signIn = async () => {
    try {
      const response: IBusinessNumberResponse = await getBusinessNumber();
      const primaryNumber = response?.phoneNumbers?.find(phoneNumber => phoneNumber?.type === WORK_TYPE.ITEM_TYPE);
      const result = primaryNumber?.value ? primaryNumber?.value.replace(/-/g, "") : '';
      Logger.info("Getting the Business number");
      setBusinessNumber(result);
    } catch (e) {
      Logger.error(`Business Number: got error: '${formatError(e)}'.`);
    }
  };

  useEffect(() => {
    signIn();
  }, []);



  const url = CallSettingsURL.IcallSettingsURL;
  const onSettingsPress = () => {
    setIsOpen(true);
    setIsCallsettingsClicked(true);
    submitMetrics(
      {
        metricEvent: callSettingsClickedMetric
      }
    );
    //window.open(url);
  };
  useEffect(() => {
    if (shouldOpenURL) {
      window.open(url);
      setShouldOpenURL(false);
    }
  }, [shouldOpenURL]);

  useEffect(() => {
    if (isOpen) {
      setShouldOpenURL(true);
      setIsOpen(false);
    }
  }, [isOpen]);

  const formatPhoneNumberForAnnouncement = (phoneNumber: string): string => {
    if (phoneNumber?.match(/^\d|^\+/)) {
      return phoneNumber?.split('').map((digit) => digit).join(' ');
    }
    return phoneNumber;
  }



  const menuItems = [];
  if (isAttendantConsoleFeatureEnabled) {
    menuItems.unshift(
      <Item key={UserProfileMenuItemsKeys.ATTENDANT_CONSOLE} aria-label={t("label.attendantConsole")}>
        <Flex alignItems="center" xgap="0.5rem">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 9.5C13.4344 9.49996 13.3693 9.51287 13.3087 9.53798C13.248 9.5631 13.1928 9.59992 13.1464 9.64636C13.1 9.6928 13.0631 9.74793 13.038 9.80861C13.0129 9.86929 13 9.93433 13 10V11.4805C12.9996 11.8834 12.8393 12.2696 12.5545 12.5545C12.2696 12.8393 11.8834 12.9996 11.4805 13H4.51958C4.11671 12.9995 3.73048 12.8393 3.44561 12.5545C3.16074 12.2696 3.0005 11.8834 3.00005 11.4805V4.51955C3.00049 4.11668 3.16073 3.73043 3.4456 3.44556C3.73047 3.16069 4.11671 3.00045 4.51958 3H6.00005C6.13265 3 6.25983 2.94732 6.3536 2.85355C6.44737 2.75979 6.50005 2.63261 6.50005 2.5C6.50005 2.36739 6.44737 2.24021 6.3536 2.14645C6.25983 2.05268 6.13265 2 6.00005 2H4.51958C3.8516 2.00079 3.2112 2.2665 2.73887 2.73884C2.26654 3.21117 2.00084 3.85157 2.00005 4.51955V11.4805C2.00085 12.1485 2.26656 12.7889 2.73889 13.2612C3.21122 13.7335 3.8516 13.9992 4.51958 14H11.4805C12.1485 13.9992 12.7889 13.7335 13.2612 13.2612C13.7335 12.7889 13.9992 12.1485 14 11.4805V10C14.0001 9.93433 13.9872 9.86929 13.9621 9.80861C13.9369 9.74793 13.9001 9.6928 13.8537 9.64636C13.8072 9.59992 13.7521 9.5631 13.6914 9.53798C13.6308 9.51287 13.5657 9.49996 13.5 9.5Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
            <path d="M13.5 2.001L9.50005 2C9.36744 2 9.24026 2.05268 9.14649 2.14645C9.05272 2.24021 9.00005 2.36739 9.00005 2.5C9.00005 2.63261 9.05272 2.75979 9.14649 2.85355C9.24026 2.94732 9.36744 3 9.50005 3L12.2919 3.00079L9.14653 6.1465C9.10011 6.19292 9.06328 6.24804 9.03816 6.30869C9.01303 6.36935 9.0001 6.43436 9.0001 6.50002C9.0001 6.56567 9.01303 6.63068 9.03815 6.69134C9.06328 6.75199 9.1001 6.80711 9.14653 6.85353C9.19295 6.89996 9.24807 6.93678 9.30872 6.96191C9.36938 6.98703 9.43439 6.99997 9.50004 6.99997C9.5657 6.99997 9.63071 6.98703 9.69137 6.96191C9.75202 6.93678 9.80714 6.89996 9.85356 6.85354L13.0001 3.70665V6.5C13.0001 6.63261 13.0527 6.75979 13.1465 6.85355C13.2403 6.94732 13.3675 7 13.5001 7C13.6327 7 13.7598 6.94732 13.8536 6.85355C13.9474 6.75979 14.0001 6.63261 14.0001 6.5V2.501C14.0001 2.36839 13.9474 2.24122 13.8536 2.14745C13.7598 2.05368 13.6327 2.001 13.5 2.001Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
          </svg>
          <span>{t("label.attendantConsole")}</span>
        </Flex>
      </Item>
    );
  }

  if (isCXEssentialsLicensedUser) {
    menuItems.unshift(
      <Item key={UserProfileMenuItemsKeys.CX_ESSENTIALS} aria-label={t("label.cxEssentials")}>
        <Flex alignItems="center" xgap="0.5rem">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 9.5C13.4344 9.49996 13.3693 9.51287 13.3087 9.53798C13.248 9.5631 13.1928 9.59992 13.1464 9.64636C13.1 9.6928 13.0631 9.74793 13.038 9.80861C13.0129 9.86929 13 9.93433 13 10V11.4805C12.9996 11.8834 12.8393 12.2696 12.5545 12.5545C12.2696 12.8393 11.8834 12.9996 11.4805 13H4.51958C4.11671 12.9995 3.73048 12.8393 3.44561 12.5545C3.16074 12.2696 3.0005 11.8834 3.00005 11.4805V4.51955C3.00049 4.11668 3.16073 3.73043 3.4456 3.44556C3.73047 3.16069 4.11671 3.00045 4.51958 3H6.00005C6.13265 3 6.25983 2.94732 6.3536 2.85355C6.44737 2.75979 6.50005 2.63261 6.50005 2.5C6.50005 2.36739 6.44737 2.24021 6.3536 2.14645C6.25983 2.05268 6.13265 2 6.00005 2H4.51958C3.8516 2.00079 3.2112 2.2665 2.73887 2.73884C2.26654 3.21117 2.00084 3.85157 2.00005 4.51955V11.4805C2.00085 12.1485 2.26656 12.7889 2.73889 13.2612C3.21122 13.7335 3.8516 13.9992 4.51958 14H11.4805C12.1485 13.9992 12.7889 13.7335 13.2612 13.2612C13.7335 12.7889 13.9992 12.1485 14 11.4805V10C14.0001 9.93433 13.9872 9.86929 13.9621 9.80861C13.9369 9.74793 13.9001 9.6928 13.8537 9.64636C13.8072 9.59992 13.7521 9.5631 13.6914 9.53798C13.6308 9.51287 13.5657 9.49996 13.5 9.5Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
            <path d="M13.5 2.001L9.50005 2C9.36744 2 9.24026 2.05268 9.14649 2.14645C9.05272 2.24021 9.00005 2.36739 9.00005 2.5C9.00005 2.63261 9.05272 2.75979 9.14649 2.85355C9.24026 2.94732 9.36744 3 9.50005 3L12.2919 3.00079L9.14653 6.1465C9.10011 6.19292 9.06328 6.24804 9.03816 6.30869C9.01303 6.36935 9.0001 6.43436 9.0001 6.50002C9.0001 6.56567 9.01303 6.63068 9.03815 6.69134C9.06328 6.75199 9.1001 6.80711 9.14653 6.85353C9.19295 6.89996 9.24807 6.93678 9.30872 6.96191C9.36938 6.98703 9.43439 6.99997 9.50004 6.99997C9.5657 6.99997 9.63071 6.98703 9.69137 6.96191C9.75202 6.93678 9.80714 6.89996 9.85356 6.85354L13.0001 3.70665V6.5C13.0001 6.63261 13.0527 6.75979 13.1465 6.85355C13.2403 6.94732 13.3675 7 13.5001 7C13.6327 7 13.7598 6.94732 13.8536 6.85355C13.9474 6.75979 14.0001 6.63261 14.0001 6.5V2.501C14.0001 2.36839 13.9474 2.24122 13.8536 2.14745C13.7598 2.05368 13.6327 2.001 13.5 2.001Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
          </svg>
          <span>{t("label.cxEssentials")}</span>
        </Flex>
      </Item>
    );
  }

  menuItems.unshift(
    <Item key={UserProfileMenuItemsKeys.E911} aria-label={t("label.e911")}>
      <Flex alignItems="center" xgap="0.5rem">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.7069 4.80738L11.1923 1.29273C11.0996 1.19956 10.9895 1.12569 10.8681 1.07541C10.7467 1.02512 10.6166 0.999405 10.4852 0.999759H5.51403C5.38287 0.999554 5.25297 1.02529 5.13179 1.07549C5.01062 1.12569 4.90057 1.19936 4.80798 1.29226L1.29138 4.80886C1.19874 4.90139 1.1253 5.01131 1.07528 5.13231C1.02525 5.25331 0.999628 5.38301 0.99988 5.51394V10.4856C1.00063 10.7503 1.10573 11.004 1.29238 11.1917L4.80799 14.7073C4.90051 14.8003 5.01055 14.874 5.13174 14.9242C5.25293 14.9744 5.38287 15.0001 5.51405 14.9998H10.4857C10.7505 14.9993 11.0043 14.8941 11.1918 14.7073L14.7064 11.1926C14.8938 11.005 14.9993 10.7508 14.9999 10.4856V5.51441C14.9991 5.24937 14.8938 4.99533 14.7069 4.80738ZM10.4857 13.9998L5.51501 14.0003L1.99987 10.4856L1.99937 5.51491L5.51402 1.99977H10.4852L13.9999 5.51442L14.0004 10.4846L10.4857 13.9998Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
          <path d="M10.3534 5.64624C10.307 5.59981 10.2519 5.56297 10.1912 5.53783C10.1306 5.5127 10.0655 5.49976 9.99988 5.49976C9.93422 5.49976 9.8692 5.5127 9.80855 5.53783C9.74789 5.56297 9.69278 5.59981 9.64636 5.64624L7.99986 7.29274L6.35336 5.64624C6.30696 5.59969 6.25184 5.56274 6.19115 5.5375C6.13046 5.51227 6.06539 5.49925 5.99966 5.4992C5.93393 5.49914 5.86884 5.51204 5.80811 5.53717C5.74737 5.5623 5.69219 5.59915 5.64571 5.64563C5.59923 5.69211 5.56238 5.74729 5.53725 5.80803C5.51212 5.86876 5.49922 5.93385 5.49928 5.99958C5.49933 6.06531 5.51235 6.13038 5.53758 6.19107C5.56282 6.25176 5.59977 6.30688 5.64632 6.35328L7.29283 7.99978L5.64632 9.64628C5.59977 9.69267 5.56282 9.7478 5.53758 9.80849C5.51235 9.86918 5.49933 9.93425 5.49928 9.99998C5.49922 10.0657 5.51212 10.1308 5.53725 10.1915C5.56238 10.2523 5.59923 10.3075 5.64571 10.3539C5.69219 10.4004 5.74737 10.4373 5.80811 10.4624C5.86884 10.4875 5.93393 10.5004 5.99966 10.5004C6.06539 10.5003 6.13046 10.4873 6.19115 10.4621C6.25184 10.4368 6.30696 10.3999 6.35336 10.3533L7.99986 8.70681L9.64636 10.3533C9.69276 10.3999 9.74788 10.4368 9.80857 10.4621C9.86926 10.4873 9.93433 10.5003 10.0001 10.5004C10.0658 10.5004 10.1309 10.4875 10.1916 10.4624C10.2523 10.4373 10.3075 10.4004 10.354 10.3539C10.4005 10.3075 10.4373 10.2523 10.4625 10.1915C10.4876 10.1308 10.5005 10.0657 10.5004 9.99998C10.5004 9.93425 10.4874 9.86918 10.4621 9.80849C10.4369 9.7478 10.4 9.69267 10.3534 9.64628L8.70689 7.99978L10.3534 6.35328C10.3998 6.30686 10.4367 6.25175 10.4618 6.19109C10.4869 6.13044 10.4999 6.06542 10.4999 5.99976C10.4999 5.9341 10.4869 5.86909 10.4618 5.80843C10.4367 5.74777 10.3998 5.69266 10.3534 5.64624Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
        </svg>
        <span>{t("label.e911")}</span>
        <span className="md-badge-wrapper">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4035 13.3596L10.2177 2.67042C10.0943 2.45669 9.91687 2.2792 9.70318 2.15577C9.48948 2.03235 9.24707 1.96734 9.00029 1.96729C8.75351 1.96723 8.51106 2.03212 8.29731 2.15545C8.08356 2.27877 7.90603 2.45619 7.78256 2.66986L1.59506 13.3596C1.47164 13.5733 1.40666 13.8158 1.40666 14.0627C1.40666 14.3095 1.47163 14.552 1.59505 14.7658C1.71847 14.9796 1.89599 15.1571 2.10976 15.2805C2.32354 15.4039 2.56603 15.4689 2.81288 15.4689H15.1863C15.4331 15.4689 15.6755 15.4039 15.8893 15.2804C16.103 15.157 16.2805 14.9794 16.4039 14.7657C16.5272 14.5519 16.5921 14.3094 16.5921 14.0626C16.592 13.8157 16.527 13.5733 16.4035 13.3596ZM7.875 4.50002C7.875 3.8787 8.37868 3.37502 9 3.37502C9.62132 3.37502 10.125 3.8787 10.125 4.50002V10.125C10.125 10.7463 9.62132 11.25 9 11.25C8.37868 11.25 7.875 10.7463 7.875 10.125V4.50002ZM9 14.625C8.37868 14.625 7.875 14.1213 7.875 13.5C7.875 12.8787 8.37868 12.375 9 12.375C9.62132 12.375 10.125 12.8787 10.125 13.5C10.125 14.1213 9.62132 14.625 9 14.625Z" fill="var(--mds-color-theme-text-warning-normal)" />
          </svg>
        </span>
      </Flex>
    </Item>
  );

  menuItems.push(
    <Item key={UserProfileMenuItemsKeys.CALLING_WIDGET} aria-label={t("label.callingWidget")}>
      <label htmlFor="calling-widget-toggle">
        <Flex alignItems="center" xgap="0.5rem">
          <svg width="16" height="16" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3873 7.12868C15.2157 8.30026 15.2157 10.1997 16.3873 11.3713L20.6299 15.614C21.8015 16.7855 23.701 16.7855 24.8726 15.614L29.1152 11.3713C30.2868 10.1998 30.2868 8.30026 29.1152 7.12868L24.8726 2.88604C23.701 1.71447 21.8015 1.71447 20.6299 2.88604L16.3873 7.12868ZM17.8015 9.95711C17.411 9.56659 17.411 8.93342 17.8015 8.5429L22.0441 4.30026C22.4347 3.90973 23.0678 3.90973 23.4584 4.30026L27.701 8.5429C28.0915 8.93342 28.0915 9.56659 27.701 9.95711L23.4583 14.1997C23.0678 14.5903 22.4347 14.5903 22.0441 14.1997L17.8015 9.95711Z" fill="var(--mds-color-theme-text-primary-normal)" />
            <path d="M5 3C3.34315 3 2 4.34315 2 6V12C2 13.6569 3.34315 15 5 15H11C12.6569 15 14 13.6569 14 12V6C14 4.34315 12.6569 3 11 3H5ZM4 6C4 5.44772 4.44772 5 5 5H11C11.5523 5 12 5.44772 12 6V12C12 12.5523 11.5523 13 11 13H5C4.44772 13 4 12.5523 4 12V6Z" fill="var(--mds-color-theme-text-primary-normal)" />
            <path d="M5 18C3.34315 18 2 19.3431 2 21V27C2 28.6569 3.34315 30 5 30H11C12.6569 30 14 28.6569 14 27V21C14 19.3431 12.6569 18 11 18H5ZM4 21C4 20.4477 4.44772 20 5 20H11C11.5523 20 12 20.4477 12 21V27C12 27.5523 11.5523 28 11 28H5C4.44772 28 4 27.5523 4 27V21Z" fill="var(--mds-color-theme-text-primary-normal)" />
            <path d="M17 21C17 19.3431 18.3431 18 20 18H26C27.6569 18 29 19.3431 29 21V27C29 28.6569 27.6569 30 26 30H20C18.3431 30 17 28.6569 17 27V21ZM20 20C19.4477 20 19 20.4477 19 21V27C19 27.5523 19.4477 28 20 28H26C26.5523 28 27 27.5523 27 27V21C27 20.4477 26.5523 20 26 20H20Z" fill="var(--mds-color-theme-text-primary-normal)" />
          </svg>
          <span>{t("label.callingWidget")}</span>
          <span className="md-toggle-button-wrapper">
            <Toggle aria-label="calling-widget-toggle" id="calling-widget-toggle" onChange={(e) => {
              setIsShowCallingWidgetModal(e)
              setIsShowCallingWidgetOffModal(!e)
              if (e) {
                setIsMenuOpen(false)
              }
            }} />
          </span>
        </Flex>
      </label>
    </Item>
  )

  menuItems.push(
    <Item key={UserProfileMenuItemsKeys.SIGN_OUT} aria-label={t("label.signOut")}>
      <Flex alignItems="center" xgap="0.5rem">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.49986 10.5C3.59873 10.5 3.69538 10.4707 3.77759 10.4157C3.85979 10.3608 3.92386 10.2827 3.9617 10.1914C3.99953 10.1 4.00943 9.99949 3.99015 9.90251C3.97086 9.80553 3.92326 9.71645 3.85336 9.64653L2.70729 8.50043L7.99986 8.50003C8.13246 8.50003 8.25964 8.44735 8.35341 8.35358C8.44718 8.25981 8.49986 8.13263 8.49986 8.00003C8.49986 7.86742 8.44718 7.74024 8.35341 7.64647C8.25964 7.5527 8.13246 7.50003 7.99986 7.50003L2.70651 7.50043L3.85339 6.35355C3.89982 6.30712 3.93664 6.25201 3.96177 6.19135C3.98689 6.1307 3.99982 6.06568 3.99982 6.00003C3.99982 5.93438 3.98689 5.86937 3.96177 5.80871C3.93664 5.74805 3.89982 5.69294 3.85339 5.64651C3.80697 5.60009 3.75186 5.56326 3.6912 5.53814C3.63054 5.51301 3.56553 5.50008 3.49988 5.50008C3.43422 5.50008 3.36921 5.51301 3.30856 5.53814C3.2479 5.56326 3.19279 5.60009 3.14636 5.64651L1.14636 7.64651C1.09992 7.69293 1.06308 7.74804 1.03795 7.8087C1.01281 7.86935 0.999878 7.93437 0.999878 8.00003C0.999878 8.06569 1.01281 8.1307 1.03795 8.19136C1.06308 8.25202 1.09992 8.30713 1.14636 8.35355L3.14636 10.3535C3.19273 10.4 3.24783 10.4369 3.30849 10.4621C3.36916 10.4872 3.43419 10.5001 3.49986 10.5Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
          <path d="M7.99986 1.00003C6.93559 0.99712 5.88482 1.23826 4.92831 1.70492C3.97181 2.17158 3.13502 2.85132 2.48228 3.69192C2.40074 3.79644 2.36403 3.92906 2.38021 4.06063C2.39639 4.1922 2.46413 4.31197 2.56856 4.39363C2.67299 4.47528 2.80556 4.51214 2.93716 4.49611C3.06875 4.48008 3.18859 4.41247 3.27037 4.30814C4.03915 3.32325 5.09622 2.60292 6.29407 2.24767C7.49191 1.89242 8.77078 1.91998 9.95222 2.32649C11.1337 2.733 12.1587 3.49819 12.8844 4.51527C13.61 5.53236 14.0001 6.75061 14.0001 8.00003C14.0001 9.24944 13.61 10.4677 12.8844 11.4848C12.1587 12.5019 11.1337 13.2671 9.95222 13.6736C8.77078 14.0801 7.49191 14.1076 6.29407 13.7524C5.09622 13.3971 4.03915 12.6768 3.27037 11.6919C3.22993 11.6401 3.17968 11.5967 3.12248 11.5643C3.06529 11.5319 3.00226 11.5111 2.93702 11.5031C2.87178 11.4951 2.80559 11.5 2.74225 11.5176C2.67891 11.5352 2.61965 11.5651 2.56787 11.6055C2.51609 11.646 2.47279 11.6963 2.44046 11.7536C2.40813 11.8108 2.3874 11.8738 2.37945 11.9391C2.3715 12.0044 2.37649 12.0705 2.39413 12.1339C2.41178 12.1972 2.44173 12.2564 2.48228 12.3081C3.21306 13.2441 4.16993 13.9787 5.26286 14.443C6.35579 14.9073 7.54886 15.0859 8.72984 14.962C9.91082 14.8382 11.0409 14.416 12.0137 13.7351C12.9866 13.0542 13.7702 12.137 14.2909 11.0698C14.8116 10.0026 15.0523 8.82047 14.9903 7.63464C14.9283 6.4488 14.5657 5.29823 13.9365 4.29116C13.3073 3.28409 12.4323 2.45362 11.3938 1.87788C10.3552 1.30213 9.18731 1.00004 7.99986 1.00003Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
        </svg>
        <span>{t("label.signOut")}</span>
      </Flex>
    </Item>
  );



  return (
    <div>
      <div className="wbx-top-bar">
        <div aria-label={t("label.topbar.number") + formatPhoneNumberForAnnouncement(myNumber)}>
          {t("label.topbar.number")} {myNumber}
        </div>
        <div className="settings_item">
          <CallForward shouldPollApi={isCallsettingsClicked} setShouldPollApi={setIsCallsettingsClicked}></CallForward>
          <div className="call-settings">
            <ButtonPill
              ghost
              size={32}
              onPress={onSettingsPress}
              aria-label={t("label.topbar.settings")}
              data-testid="settings-click"
            >
              <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3922 4.60254C14.6221 4.50456 14.807 4.32408 14.9104 4.09663C15.0138 3.86917 15.0284 3.61123 14.9512 3.37359L14.7473 2.74622C14.6718 2.51329 14.5134 2.31619 14.3022 2.19234C14.091 2.06849 13.8416 2.02652 13.6015 2.0744L13.3637 2.12164L13.3357 2.10242L13.3187 1.91138C13.2966 1.66248 13.1821 1.4309 12.9977 1.26223C12.8134 1.09355 12.5725 1 12.3227 1H11.6629C11.4181 0.999999 11.1818 1.08981 10.9988 1.2524C10.8158 1.415 10.6987 1.63909 10.6699 1.8822L10.6414 2.12293L10.6144 2.14362L10.4275 2.10071C10.1839 2.04465 9.92822 2.08192 9.71079 2.20514C9.49336 2.32837 9.33001 2.52861 9.25296 2.76636L9.04908 3.39374C8.9734 3.62658 8.98578 3.87908 9.08385 4.1034C9.18192 4.32772 9.35888 4.50828 9.58118 4.61084L9.80136 4.7124L9.81269 4.74444L9.71418 4.90895C9.5858 5.12332 9.54232 5.378 9.59232 5.62282C9.64232 5.86765 9.78217 6.08489 9.98432 6.23176L10.518 6.61951C10.7161 6.76341 10.9601 6.82966 11.2037 6.8057C11.4474 6.78174 11.6737 6.66924 11.84 6.48951L12.0046 6.31154L12.0385 6.31063L12.1645 6.45515C12.3287 6.6435 12.5574 6.76354 12.8057 6.79164C13.054 6.81975 13.3038 6.75388 13.506 6.60701L14.0397 6.21925C14.2378 6.07536 14.3762 5.8638 14.4287 5.62467C14.4812 5.38554 14.4441 5.13546 14.3246 4.92183L14.2062 4.71028L14.2158 4.67775L14.3922 4.60254ZM13.3908 3.94232L13.1275 4.8305L13.4519 5.41021L12.9182 5.79797L12.483 5.29876L11.5569 5.32276L11.1058 5.81049L10.5721 5.42274L10.9124 4.8545L10.6034 3.98114L10.0001 3.70282L10.204 3.07544L10.8496 3.22344L11.5847 2.65972L11.6629 2H12.3227L12.3813 2.65973L13.1447 3.18463L13.7963 3.05523L14.0001 3.68262L13.3908 3.94232Z" fill="var(--mds-color-theme-text-primary-normal)" />
                <path d="M12.0001 4.68561C12.4143 4.68561 12.7501 4.34982 12.7501 3.93561C12.7501 3.5214 12.4143 3.18561 12.0001 3.18561C11.5859 3.18561 11.2501 3.5214 11.2501 3.93561C11.2501 4.34982 11.5859 4.68561 12.0001 4.68561Z" fill="var(--mds-color-theme-text-primary-normal)" />
                <path d="M4.5538 11.4213C8.0517 14.9192 11.1857 14.6889 12.0861 13.7885L12.6047 13.2699C12.907 12.9669 13.0768 12.5564 13.0768 12.1284C13.0768 11.7005 12.907 11.29 12.6047 10.987L11.3428 9.72503C11.0407 9.42526 10.6321 9.25753 10.2065 9.25863C9.78094 9.25973 9.37317 9.42957 9.07265 9.7309L8.49397 10.3096C7.79932 10.1653 7.16496 9.81324 6.67511 9.30002C6.16212 8.81043 5.80953 8.17688 5.66382 7.48288L6.24767 6.89974C6.5432 6.58456 6.70405 6.16648 6.696 5.7345C6.68794 5.30252 6.51161 4.89073 6.20454 4.58679L4.98816 3.37041C4.6852 3.06812 4.27469 2.89835 3.84671 2.89835C3.41873 2.89835 3.00822 3.06812 2.70526 3.37041L2.18665 3.88902C1.28618 4.78946 1.05589 7.92343 4.5538 11.4213ZM3.41235 4.0775C3.5276 3.96243 3.68382 3.89779 3.84669 3.89779C4.00956 3.89779 4.16578 3.96243 4.28104 4.0775L5.49742 5.29388C5.61813 5.40968 5.68991 5.56733 5.698 5.73441C5.70608 5.9015 5.64987 6.06534 5.5409 6.19226L4.84967 6.88278C4.30933 7.42313 5.01298 9.05209 5.96799 10.0071C7.01864 11.0578 8.59651 11.6212 9.09438 11.1234L9.77974 10.438C9.89256 10.3239 10.046 10.2592 10.2065 10.2581C10.367 10.257 10.5213 10.3196 10.6357 10.4321L11.8976 11.6941C12.0127 11.8094 12.0773 11.9656 12.0773 12.1284C12.0773 12.2913 12.0127 12.4475 11.8976 12.5628L11.379 13.0814C10.8452 13.6152 8.24158 13.6949 5.26089 10.7142C2.2802 7.73355 2.35996 5.12989 2.89374 4.59611L3.41235 4.0775Z" fill="var(--mds-color-theme-text-primary-normal)" />
              </svg>
              <span>{t("label.topbar.settings")}</span>
            </ButtonPill>
          </div>
          <div className="user-profile">
            {/* <span className="e911-warning"> */}
            {/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M16.4035 13.3596L10.2177 2.67042C10.0943 2.45669 9.91687 2.2792 9.70318 2.15577C9.48948 2.03235 9.24707 1.96734 9.00029 1.96729C8.75351 1.96723 8.51106 2.03212 8.29731 2.15545C8.08356 2.27877 7.90603 2.45619 7.78256 2.66986L1.59506 13.3596C1.47164 13.5733 1.40666 13.8158 1.40666 14.0627C1.40666 14.3095 1.47163 14.552 1.59505 14.7658C1.71847 14.9796 1.89599 15.1571 2.10976 15.2805C2.32354 15.4039 2.56603 15.4689 2.81288 15.4689H15.1863C15.4331 15.4689 15.6755 15.4039 15.8893 15.2804C16.103 15.157 16.2805 14.9794 16.4039 14.7657C16.5272 14.5519 16.5921 14.3094 16.5921 14.0626C16.592 13.8157 16.527 13.5733 16.4035 13.3596ZM7.875 4.50002C7.875 3.8787 8.37868 3.37502 9 3.37502C9.62132 3.37502 10.125 3.8787 10.125 4.50002V10.125C10.125 10.7463 9.62132 11.25 9 11.25C8.37868 11.25 7.875 10.7463 7.875 10.125V4.50002ZM9 14.625C8.37868 14.625 7.875 14.1213 7.875 13.5C7.875 12.8787 8.37868 12.375 9 12.375C9.62132 12.375 10.125 12.8787 10.125 13.5C10.125 14.1213 9.62132 14.625 9 14.625Z" fill="var(--mds-color-theme-text-warning-normal)"/>
          </svg> */}
            {/* </span> */}
            <MenuTrigger
              aria-label={t("label.user.profile.menu")}
              placement="bottom"
              showArrow
              isOpen={isMenuOpen}
              triggerComponent={
                <ButtonCircle size={28} ghost key="1" aria-label={t("label.user.profile.menu")} className="press-usermenu">
                  <Icon scale={18} name="more" weight="bold" />
                </ButtonCircle>
              }
            >
              <Menu onAction={handleAction} selectionMode="single" key="2" aria-label={t("label.user.profile.menu")}>
                {menuItems}
              </Menu>
            </MenuTrigger>
          </div>
        </div>
      </div>
      {isShowCallingWidgetModal && (<CallingWidgetModal isOpen={isShowCallingWidgetModal} onCancel={() => setIsShowCallingWidgetModal(false)} />)}
      {isShowCallingWidgetOffModel && (<CallingWidgetOffModal isOpen={isShowCallingWidgetOffModel} onCancel={() => setIsShowCallingWidgetOffModal(false)}></CallingWidgetOffModal>)}
    </div>);
};
