export const appReady = "App is ready to go for all users in your organization.";
export const attendantConsoleURL = `webex://im?embeddedapp=${process.env.WEBEX_ATTENDANT_CONSOLE}&context=sidebar&expand=true`;
export enum CallSettingsURL {
    IcallSettingsURL = 'webex://application-settings/?tabId=CallForward&source=MSTeams',
}
export const callVoicemailURL = 'webextel://voicemail?audioOnlyCall=true&source=MSTeams';
//export const cxEssentialsURL = `webex://customerexperience/?`;
export const cxEssentialsURL = "webex://customerexperience/?&source=MSTeams";
export const ImeURL = '/v1/people/me';
export const ItemType = "work";
export const permissionGranted = "Permission granted";
