export const ByCiscoWordMark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="11" viewBox="0 0 51 11" fill="none">
        <path d="M25.1217 0.453613H23.1863V8.25141H25.1217V0.453613Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M40.8073 2.68753C40.3066 2.41172 39.7452 2.26882 39.1751 2.27208C37.915 2.27208 37.0419 3.15736 37.0419 4.3515C37.0419 5.51083 37.8784 6.43308 39.1751 6.43308C39.7447 6.43526 40.3058 6.29322 40.8073 6.01981V8.11228C40.2331 8.29129 39.6361 8.38438 39.0353 8.38852C36.8483 8.38852 34.9302 6.86593 34.9302 4.3515C34.9302 2.02412 36.6677 0.31665 39.0353 0.31665C39.6364 0.318173 40.234 0.412138 40.8073 0.595065V2.68753Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M20.5042 2.68753C20.003 2.41151 19.4406 2.2686 18.8699 2.27208C17.6098 2.27208 16.7389 3.15736 16.7389 4.3515C16.7389 5.51083 17.5732 6.43308 18.8699 6.43308C19.4402 6.43439 20.0017 6.29235 20.5042 6.01981V8.11228C19.9292 8.29129 19.3316 8.38438 18.7301 8.38852C16.5432 8.38852 14.625 6.86593 14.625 4.3515C14.625 2.02412 16.3625 0.31665 18.7301 0.31665C19.332 0.318173 19.9303 0.41192 20.5042 0.595065V2.68753Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M46.9272 2.30036C46.661 2.29797 46.3971 2.34952 46.1511 2.45196C45.9049 2.55441 45.6815 2.7058 45.4942 2.89699C45.3069 3.08818 45.1591 3.31527 45.06 3.56519C44.9606 3.81489 44.912 4.08243 44.9165 4.3515C44.9114 4.62099 44.9598 4.88875 45.0587 5.13889C45.1576 5.38903 45.3051 5.61676 45.4926 5.80817C45.6802 5.99958 45.9038 6.15119 46.1502 6.25364C46.3967 6.35608 46.6608 6.40742 46.9272 6.40481C47.1936 6.4072 47.4579 6.35565 47.7041 6.25298C47.9506 6.15032 48.1742 5.99893 48.3617 5.80752C48.5495 5.61633 48.6972 5.38881 48.7966 5.13867C48.8959 4.88853 48.9447 4.62099 48.94 4.3515C48.9443 4.08222 48.8953 3.8149 48.7959 3.56497C48.6966 3.31505 48.5486 3.08797 48.3611 2.89678C48.1734 2.70558 47.9499 2.55441 47.7037 2.45196C47.4575 2.34952 47.1934 2.29797 46.9272 2.30036ZM51.0001 4.3515C51.0001 6.58317 49.2991 8.38852 46.9272 8.38852C44.5553 8.38852 42.8564 6.58317 42.8564 4.3515C42.8564 2.11982 44.5574 0.31665 46.9272 0.31665C49.297 0.31665 51.0001 2.12417 51.0001 4.3515Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M32.4996 2.22652C32.0035 2.08861 31.4934 2.00922 30.9792 1.98943C30.2008 1.98943 29.7771 2.25262 29.7771 2.62674C29.7771 3.10309 30.3491 3.27927 30.6674 3.37063L31.205 3.54246C32.4673 3.94921 33.0415 4.82361 33.0415 5.77413C33.0415 7.73174 31.3405 8.38428 29.8502 8.38428C29.1458 8.37579 28.4432 8.30293 27.7514 8.16676V6.37229C28.349 6.54739 28.9662 6.64462 29.5879 6.66158C30.5556 6.66158 31.0007 6.37664 31.0007 5.93292C31.0007 5.53922 30.6158 5.31083 30.1405 5.1564L29.7255 5.02154C28.6503 4.6757 27.7385 4.03186 27.7385 2.73984C27.7385 1.28251 28.8137 0.303711 30.6115 0.303711C31.2504 0.312629 31.886 0.395936 32.506 0.551674L32.4996 2.22652Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M1.03725 8.22547H0.192139V0.445068H1.21788V3.38365C1.42303 3.07957 1.70172 2.83378 2.02708 2.66934C2.35265 2.50491 2.71436 2.42725 3.078 2.444C3.43411 2.42682 3.78892 2.49707 4.11235 2.64868C4.43577 2.80007 4.71834 3.02846 4.93596 3.31405C5.32734 3.83325 5.52302 4.52124 5.52302 5.37824C5.52302 6.39337 5.25636 7.17119 4.72306 7.71214C4.49726 7.93814 4.22826 8.11519 3.93236 8.23221C3.63646 8.34923 3.32013 8.40405 3.00272 8.39295C2.64984 8.41123 2.29847 8.33749 1.98214 8.17849C1.66581 8.0197 1.39508 7.78109 1.19638 7.48593L1.03725 8.22547ZM4.45426 5.34996C4.45426 4.6437 4.28007 4.11449 3.93171 3.76212C3.78978 3.61683 3.61969 3.50307 3.43195 3.42824C3.24422 3.3532 3.04294 3.31884 2.84144 3.3271C2.6049 3.31731 2.36965 3.36386 2.1544 3.46326C1.93935 3.56267 1.75033 3.7121 1.60281 3.89916C1.29767 4.317 1.14563 4.82902 1.17273 5.34779C1.17273 6.06775 1.34691 6.62023 1.69743 7.00088C1.84108 7.1651 2.01892 7.29474 2.21826 7.38087C2.41761 7.46701 2.63329 7.50746 2.85005 7.49898C3.0866 7.51247 3.32249 7.46483 3.53581 7.36064C3.74913 7.25624 3.93278 7.09876 4.06934 6.903C4.32868 6.53758 4.45856 6.0199 4.45856 5.34996H4.45426Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M11.0191 2.60494L9.08373 8.2994C8.82568 9.05634 8.57989 9.57184 8.34614 9.84591C8.21432 10.0032 8.04939 10.1285 7.86316 10.2124C7.67715 10.2964 7.47458 10.3368 7.27093 10.331C7.04729 10.3253 6.82494 10.2931 6.60861 10.2353V9.38696C6.78344 9.43394 6.96321 9.45939 7.14406 9.46309C7.24148 9.46787 7.33867 9.44938 7.4277 9.40892C7.51651 9.36847 7.59479 9.30735 7.65587 9.23035C7.85198 8.91561 8.00659 8.57629 8.11604 8.22109H7.78489L5.74414 2.60059H6.843L8.41496 7.38585L9.98476 2.60059L11.0191 2.60494Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        </svg>
    )
}