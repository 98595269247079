import { useCallback, useContext, useEffect, useState } from "react";
import { AdapterContext } from "../contexts/AdapterContext";
import {
    ISubscriptionEventData,
    INITIAL_SUBSCRIPTION_DATA,
    IPhoneServicesEventData,
    INITIAL_PHONE_SERVICES_DATA,
    IIntegrationErrorEventData,
    INITIAL_ERROR_SUBSCRIPTION_DATA
} from "../../../adapter-interfaces/src/IEventAdapter";
import { Logger } from "../../../logger/src/Logger";

/**
 * Custom hook that returns mercury events data for a given user.
 *
 * @returns {object}
 */
export default function useEventListeners() {
    const [subscriptionEventData, setSubscriptionEventData] = useState<ISubscriptionEventData>(INITIAL_SUBSCRIPTION_DATA);
    const [phoneServicesEventData, setPhoneServicesEventData] = useState<IPhoneServicesEventData>(INITIAL_PHONE_SERVICES_DATA);
    const [errorEventData, setErrorEventData] = useState<IIntegrationErrorEventData>(INITIAL_ERROR_SUBSCRIPTION_DATA);
    const ctx = useContext(AdapterContext);

    const onSubscriptionEventUpdate = useCallback((data: ISubscriptionEventData) => {
        setSubscriptionEventData(data);
    }, []);

    useEffect(() => {
        const subscription = ctx?.eventAdapter
            ?.getSubscriptionData()
            .subscribe({ next: onSubscriptionEventUpdate });
        return () => {
            subscription?.unsubscribe();
        };
    }, [ctx?.eventAdapter, onSubscriptionEventUpdate]);

    const refreshSubscriptionEventData = useCallback(async () => {
        if (ctx?.eventAdapter?.refreshSubscriptionStatus) {
            Logger.info(`refreshSubscriptionEventData refreshing function`);
            return ctx?.eventAdapter?.refreshSubscriptionStatus();
        }
        return Promise.resolve();
    }, [ctx?.eventAdapter]);

    //phone services logic

    const onPhoneServicesEventUpdate = useCallback((data: IPhoneServicesEventData) => {
        setPhoneServicesEventData(data);
    }, []);

    useEffect(() => {
        const subscription = ctx?.eventAdapter
            ?.getPhoneServicesData()
            .subscribe({ next: onPhoneServicesEventUpdate });
        return () => {
            subscription?.unsubscribe();
        };
    }, [ctx?.eventAdapter, onPhoneServicesEventUpdate]);

    const refreshPhoneServicesEventData = useCallback(async () => {
        if (ctx?.eventAdapter?.refreshPhoneServicesSubscriptionStatus) {
            Logger.info(`refreshPhoneServicesEventData refreshing function`);
            return ctx?.eventAdapter?.refreshPhoneServicesSubscriptionStatus();
        }
        return Promise.resolve();
    }, [ctx?.eventAdapter]);

    // error subscription logic

    const onErrorEventUpdate = useCallback((data: IIntegrationErrorEventData) => {
        setErrorEventData(data);
    }, []);

    useEffect(() => {
        const subscription = ctx?.eventAdapter
            ?.getErrorEventData()
            .subscribe({ next: onErrorEventUpdate });
        return () => {
            subscription?.unsubscribe();
        };
    }, [ctx?.eventAdapter, onErrorEventUpdate]);

    const refreshErrorEventData = useCallback(async () => {
        if (ctx?.eventAdapter?.refreshErrorSubscriptionStatus) {
            Logger.info(`refreshErrorEventData refreshing function`);
            return ctx?.eventAdapter?.refreshErrorSubscriptionStatus();
        }
        return Promise.resolve();
    }, [ctx?.eventAdapter]);

    return {
        refreshSubscriptionEventData,
        subscriptionEventData,
        refreshPhoneServicesEventData,
        phoneServicesEventData,
        refreshErrorEventData,
        errorEventData
    } as const;
}
