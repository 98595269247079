export const WebexWordMark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="16" viewBox="0 0 64 16" fill="none">
        <path d="M15.4496 4.5459H17.8797L14.3465 15.2256H11.5588L8.91811 7.16651L6.27744 15.2256H3.53315L0 4.5459H2.48691L4.99387 12.6618L7.62451 4.5459H10.2652L12.9393 12.7052L15.4496 4.5459Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M28.302 10.7431H20.4201C20.4928 11.2938 20.6994 11.8182 21.0218 12.2706C21.3073 12.6665 21.6941 12.9782 22.1415 13.1731C22.5972 13.37 23.0892 13.469 23.5855 13.4639C24.1358 13.4619 24.6812 13.3623 25.1967 13.1698C25.7311 12.9744 26.2301 12.6933 26.6741 12.3375L27.834 14.0088C27.2425 14.5245 26.5538 14.9166 25.8084 15.162C25.0428 15.4023 24.2442 15.5207 23.4418 15.513C22.4536 15.5325 21.4774 15.2927 20.6106 14.8177C19.7907 14.3582 19.1192 13.674 18.6752 12.8456C18.1977 11.9375 17.9584 10.9231 17.98 9.89737C17.9595 8.88129 18.1891 7.87575 18.6485 6.96924C19.0754 6.14471 19.7266 5.45759 20.527 4.98707C21.3669 4.50374 22.3226 4.25873 23.2914 4.27843C24.2056 4.25314 25.1077 4.49193 25.8897 4.9662C26.6716 5.44047 27.3003 6.13012 27.7003 6.95253C28.1419 7.86199 28.3619 8.86314 28.3421 9.87398C28.3454 10.0979 28.3321 10.3887 28.302 10.7431ZM25.9621 8.97147C25.8903 8.23955 25.5762 7.55255 25.0697 7.01938C24.8268 6.79179 24.5407 6.61534 24.2284 6.50049C23.916 6.38565 23.5837 6.33476 23.2513 6.35086C22.9014 6.33484 22.5518 6.38931 22.2233 6.51105C21.8949 6.63278 21.5943 6.81929 21.3393 7.05949C20.8345 7.58484 20.5122 8.25875 20.4201 8.9815L25.9621 8.97147Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M38.273 4.99387C39.0861 5.47483 39.7528 6.16817 40.2017 6.99944C40.6816 7.88544 40.9256 8.87996 40.9103 9.88747C40.9256 10.8939 40.6816 11.8874 40.2017 12.7721C39.7527 13.6072 39.0835 14.3033 38.2667 14.7848C37.45 15.2663 36.5169 15.5147 35.5688 15.5031C34.9035 15.517 34.2438 15.3787 33.6401 15.0986C33.0869 14.8318 32.6029 14.4405 32.2262 13.9554V15.2256H29.8864V0H32.2262V5.81616C32.6045 5.33432 33.0882 4.94555 33.6401 4.67967C34.243 4.39686 34.9031 4.25729 35.5688 4.27187C36.5193 4.26024 37.4548 4.51 38.273 4.99387ZM36.9961 12.8524C37.4814 12.554 37.8721 12.124 38.1226 11.6123C38.3797 11.073 38.5131 10.4832 38.5131 9.88579C38.5131 9.2884 38.3797 8.69855 38.1226 8.15933C37.871 7.64836 37.4806 7.21858 36.9961 6.91922C36.4811 6.6246 35.8981 6.46961 35.3047 6.46961C34.7114 6.46961 34.1284 6.6246 33.6134 6.91922C33.1276 7.2142 32.734 7.6391 32.4769 8.14596C32.1989 8.68734 32.0588 9.28898 32.0691 9.89749C32.0585 10.505 32.1986 11.1056 32.4769 11.6457C32.734 12.1525 33.1276 12.5774 33.6134 12.8724C34.1284 13.167 34.7114 13.322 35.3047 13.322C35.8981 13.322 36.4811 13.167 36.9961 12.8724V12.8524Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M52.4556 10.7431H44.577C44.6498 11.2938 44.8563 11.8183 45.1787 12.2707C45.4643 12.6665 45.851 12.9783 46.2985 13.1732C46.7542 13.3701 47.2461 13.4691 47.7425 13.464C48.2926 13.4615 48.838 13.3619 49.3536 13.1699C49.888 12.9745 50.387 12.6934 50.8311 12.3376L51.9876 14.0089C51.3978 14.5253 50.7101 14.9175 49.9653 15.1621C49.1985 15.4022 48.3989 15.5206 47.5954 15.5131C46.6073 15.5315 45.6314 15.2918 44.7642 14.8178C43.946 14.3572 43.2758 13.6732 42.8322 12.8457C42.3536 11.9379 42.1132 10.9235 42.1336 9.89747C42.1132 8.8814 42.3428 7.87589 42.8021 6.96933C43.229 6.1448 43.8802 5.45768 44.6806 4.98716C45.5253 4.49678 46.4886 4.24815 47.465 4.2685C48.379 4.24445 49.2806 4.48375 50.0623 4.95789C50.844 5.43202 51.4729 6.12097 51.874 6.94259C52.3156 7.85206 52.5355 8.8532 52.5157 9.86404C52.5024 10.098 52.479 10.3888 52.4556 10.7431ZM50.1157 8.97156C50.0386 8.24434 49.7237 7.56289 49.2199 7.03284C48.9787 6.804 48.6942 6.62576 48.383 6.50865C48.0719 6.39154 47.7404 6.33792 47.4082 6.35095C47.0583 6.33455 46.7086 6.38885 46.3801 6.5106C46.0516 6.63235 45.751 6.81906 45.4962 7.05958C44.9902 7.5841 44.6677 8.25843 44.577 8.98159L50.1157 8.97156Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        <path d="M63.5698 15.2256H60.7052L57.9977 11.5119L55.3236 15.2256H52.5927L56.6038 9.77375L52.6562 4.5459H55.5241L58.1314 8.11247L60.7386 4.5459H63.5096L59.5453 9.7537L63.5698 15.2256Z" fill="var(--mds-color-theme-text-primary-normal)"/>
        </svg>
    )
}