// import { createVoicemailClient } from "@webex/web-calling-sdk";
import { createVoicemailClient } from '@webex/calling';
import { DBSchema, IDBPDatabase, openDB } from "idb";
import { formatError, Logger, LOGGER } from "../../../../packages/logger/src";
// import {
//   IVoiceMailResponse,
//   SORT,
//   VOICEMAIL_LIMIT,
//   IVoiceMailDeleteResponse,
//   RESPONSE_STATUS,
//   IDB_STORAGE,
//   IVoiceMessageContentResponse,
//   IVoiceMailReadResponse
// } from '@webex/component-adapter-interfaces/dist/esm/src';
import {
  IDB_STORAGE,
  IDB_VM_TRANSCRIPT_STORAGE,
  IVoiceMailDeleteResponse,
  IVoiceMailReadResponse,
  IVoiceMailResponse,
  IVoiceMailTranscriptMessage,
  IVoiceMessageContentResponse,
  RESPONSE_STATUS,
  SORT,
  VM_STORAGE_KEYS,
  VOICEMAIL_LIMIT,
  VoicemailTranscriptFetchStatus
} from "../../../adapter-interfaces/src";
import { setLocalStorageItem } from "../../../logger/src";
import { submitMetrics } from "../services/WebexMetricsService";
import { voicemail, voicemailListLatencyMetric } from "../utils/MetricUtils";
import {
  CALLING_BACKEND,
  CALLING_BACKEND_NOT_IDENTIFIED_RESPONSE,
  getCallingBackEnd,
  setVoicemailForBroadworks,
  VM_BROADWORKS_RESPONSE
} from "../utils/callingUtils";

interface VoiceMessageStore extends DBSchema {
  [IDB_STORAGE.STORE_NAME]: {
    key: string;
    value: IVoiceMessageContentResponse | any;
  };
}
interface VoiceMessageTranscriptStore extends DBSchema {
  [IDB_VM_TRANSCRIPT_STORAGE.STORE_NAME]: {
    key: string;
    value: IVoiceMailTranscriptMessage | any;
  };
}

async function initVoicemailList() {
  let voicemailListLatency;
  const startTime = new Date();
  let endTime;
  let response;

  const callvoicemailClient = createVoicemailClient(
    (window as any)?.webex,
    {
      level: LOGGER.INFO,
    }
  );
  await callvoicemailClient?.init();
  response = await callvoicemailClient.getVoicemailList(
    VOICEMAIL_LIMIT.OFFSET,
    VOICEMAIL_LIMIT.OFFSET_LIMIT,
    SORT.DESC,
    true
  );
  endTime = new Date();
  voicemailListLatency =
    Math.floor(endTime.getTime() - startTime.getTime()) / 1000;
  submitMetrics(
    {
      metricEvent: voicemailListLatencyMetric,
      client_timestamp: voicemail,
      trackingId: voicemail,
      key: voicemail,
      voicemailListLatency: `${voicemailListLatency}`,
    }
  );
  Logger.info(`WebexVoicemailService:getVoiceMailList(): done.`);
  return response as unknown as IVoiceMailResponse;
}

export async function getVoiceMailList(): Promise<IVoiceMailResponse> {
  if(window?.isWebexInitalized) {
    const callingBehaviour = getCallingBackEnd();
    switch (callingBehaviour) {
      case CALLING_BACKEND.WXC: {
        return initVoicemailList();
      }
      case CALLING_BACKEND.BWRKS: {
        /* SPARK-541886 If feature toggle is enabled */
        const isBroadworksFTEnabled = await setVoicemailForBroadworks();
        Logger.info(`Is Broadworks feature toggle enabled? : ${isBroadworksFTEnabled}`);
        if(isBroadworksFTEnabled) {
          return initVoicemailList();
        }
        setLocalStorageItem(VM_STORAGE_KEYS.IS_HIDE_VM_TAB, true);
        window.dispatchEvent(new Event("storage"));
        return VM_BROADWORKS_RESPONSE as unknown as IVoiceMailResponse;
      }
      case CALLING_BACKEND.UCM: {
        return initVoicemailList();
      }
      default: {
        setLocalStorageItem(VM_STORAGE_KEYS.IS_HIDE_VM_TAB, true);
        window.dispatchEvent(new Event("storage"));
        return CALLING_BACKEND_NOT_IDENTIFIED_RESPONSE as unknown as IVoiceMailResponse;
      }
    }
  } else {
    await new Promise(f => setTimeout(f, 1000));
    return getVoiceMailList();
  }
}

export async function deleteVoiceMail(
  voicemailId: string
): Promise<IVoiceMailDeleteResponse> {
  try {
    const callvoicemailClient = createVoicemailClient((window as any).webex, {
      level: LOGGER.INFO,
    });
    await callvoicemailClient.init();
    const response = await callvoicemailClient.deleteVoicemail(voicemailId);
    Logger.info(`WebexVoicemailService:deleteVoiceMail(): done.`);
    return response as IVoiceMailDeleteResponse;
  } catch (error) {
    Logger.error(
      `WebexVoicemailService.deleteVoiceMail(): got error: '${formatError(
        error
      )}'.`
    );
    throw error;
  }
}

async function getDb(): Promise<IDBPDatabase<VoiceMessageStore>> {
  const db = await openDB<VoiceMessageStore>(
    IDB_STORAGE.DB_NAME,
    IDB_STORAGE.DB_VERSION,
    {
      upgrade(db) {
        db.createObjectStore(IDB_STORAGE.STORE_NAME, { keyPath: "key" });
      },
    }
  );
  return db;
}

async function getVoiceMessageFromApi(
  voicemailId: string
): Promise<IVoiceMessageContentResponse> {
  try {
    const callvoicemailClient = createVoicemailClient((window as any).webex, {
      level: LOGGER.INFO,
    });
    await callvoicemailClient.init();
    const result = await callvoicemailClient.getVoicemailContent(voicemailId);
    return {
      ...result,
      voiceMessageId: voicemailId,
      createdAt: new Date().toUTCString(),
    } as IVoiceMessageContentResponse;
  } catch (error) {
    Logger.error(
      `WebexVoicemailService.getFromAPI(): got error: '${formatError(error)}'.`
    );
    throw error;
  }
}

export async function getVoiceMessage(
  voicemailId: string
): Promise<IVoiceMessageContentResponse> {
  if (!(IDB_STORAGE.IDB_EXIST in window)) {
    return await getVoiceMessageFromApi(voicemailId);
  }

  try {
    let db = await getDb();
    let voiceMessage = await db.get(IDB_STORAGE.STORE_NAME, voicemailId);

    if (voiceMessage) {
      return voiceMessage.value;
    }

    const response = await getVoiceMessageFromApi(voicemailId);

    if (response.message === RESPONSE_STATUS.SUCCESS) {
      db = await openDB<VoiceMessageStore>(
        IDB_STORAGE.DB_NAME,
        IDB_STORAGE.DB_VERSION
      );
      const voiceMessageStore = await db
        .transaction(IDB_STORAGE.STORE_NAME, IDB_STORAGE.READ_WRITE)
        .objectStore(IDB_STORAGE.STORE_NAME);

      const currentLength = await voiceMessageStore.count();

      if (currentLength === IDB_STORAGE.MAX_ITEMS) {
        const getAllVMs = await voiceMessageStore.getAll();
        const firstRecord = getAllVMs.reduce((prev, curr) =>
          prev.value.createdAt > curr.value.createdAt ? curr : prev
        );
        await voiceMessageStore.delete(firstRecord.key);
      }
      await voiceMessageStore.put({ key: voicemailId, value: response });
    }
    return response as IVoiceMessageContentResponse;
  } catch (error) {
    Logger.error(
      `WebexVoicemailService.getVoiceMessage(): got error: '${formatError(
        error
      )}'.`
    );
    throw error;
  }
}

export async function readVoiceMessage(
  voicemailId: string
): Promise<IVoiceMailReadResponse> {
  try {
    const callvoicemailClient = createVoicemailClient((window as any).webex, {
      level: LOGGER.INFO,
    });
    await callvoicemailClient.init();
    const response = await callvoicemailClient.voicemailMarkAsRead(voicemailId);
    Logger.info(`WebexVoicemailService:readVoiceMessage(): done.`);
    return response as IVoiceMailReadResponse;
  } catch (error) {
    Logger.error(
      `WebexVoicemailService.readVoiceMessage(): got error: '${formatError(
        error
      )}'.`
    );
    throw error;
  }
}

async function getVMTranscriptDb(): Promise<IDBPDatabase<VoiceMessageTranscriptStore>> {
  const db = await openDB<VoiceMessageTranscriptStore>(
    IDB_VM_TRANSCRIPT_STORAGE.DB_NAME,
    IDB_VM_TRANSCRIPT_STORAGE.DB_VERSION,
    {
      upgrade(db) {
        db.createObjectStore(IDB_VM_TRANSCRIPT_STORAGE.STORE_NAME, { keyPath: "key" });
      },
    }
  );
  return db;
}

export async function getVMTranscript(voicemailId: string): Promise<IVoiceMailTranscriptMessage> {
  try {
    const callvoicemailClient = createVoicemailClient((window as any).webex, {
      level: LOGGER.INFO,
    });
    await callvoicemailClient.init();
    const response = await callvoicemailClient.getVMTranscript(voicemailId);
    Logger.info(`WebexVoicemailService:getVMTranscript(): done.`);
    return {
      ...response,
      voiceMessageId: voicemailId,
      createdAt: new Date().toUTCString(),
    } as IVoiceMailTranscriptMessage;
  } catch (error) {
    Logger.error(
      `WebexVoicemailService.getVMTranscriptFromAPI(): got error: '${formatError(
        error
      )}'.`
    );
    throw error;
  }
}

// export async function getVMTranscript(
//   voicemailId: string
// ): Promise<IVoiceMailTranscriptMessage> {
//   if (!(IDB_VM_TRANSCRIPT_STORAGE.IDB_EXIST in window)) {
//     return await getVMTranscriptFromAPI(voicemailId);
//   }
//   try {
//     let db = await getVMTranscriptDb();
//     let voiceMessageTranscript = await db.get(IDB_VM_TRANSCRIPT_STORAGE.STORE_NAME, voicemailId);

//     if (voiceMessageTranscript) {
//       return voiceMessageTranscript.value;
//     }

//     const response = await getVMTranscriptFromAPI(voicemailId);
//     if (response.message === VoicemailTranscriptFetchStatus.READY) {
//       db = await openDB<VoiceMessageTranscriptStore>(
//         IDB_VM_TRANSCRIPT_STORAGE.DB_NAME,
//         IDB_VM_TRANSCRIPT_STORAGE.DB_VERSION
//       );
//       const voiceMessageTranscriptStore = await db
//         .transaction(IDB_VM_TRANSCRIPT_STORAGE.STORE_NAME, IDB_VM_TRANSCRIPT_STORAGE.READ_WRITE)
//         .objectStore(IDB_VM_TRANSCRIPT_STORAGE.STORE_NAME);

//       const currentLength = await voiceMessageTranscriptStore.count();
//       if (currentLength === IDB_VM_TRANSCRIPT_STORAGE.MAX_ITEMS) {
//         const getAllVMs = await voiceMessageTranscriptStore.getAll();
//         const firstRecord = getAllVMs.reduce((prev, curr) =>
//           prev.value.createdAt > curr.value.createdAt ? curr : prev
//         );
//         await voiceMessageTranscriptStore.delete(firstRecord.key);
//       }
//       await voiceMessageTranscriptStore.put({ key: voicemailId, value: response });
//     }
//     return response as IVoiceMailTranscriptMessage;
//   } catch (error) {
//     Logger.error(
//       `WebexVoicemailService.getVMTranscript(): got error: '${formatError(
//         error
//       )}'.`
//     );
//     throw error;
//   }
// }
