import { authentication, HostClientType } from "@microsoft/teams-js";
import axios from "axios";
import { DateTime } from "luxon";
import qs from "qs";
import { v4 as uuid } from "uuid";
import { ImeURL } from "../../ms-teams-sdk/util/TobarUtils";
import { IBusinessNumberResponse, IErrorResponse, MAKE_A_CALL_KEYS } from "../../packages/adapter-interfaces/src";
import { IUserMapping } from "../../packages/adapter-interfaces/src/IUserMapping";
import { Logger, formatError } from "../../packages/logger/src/Logger";
import { initWebex } from "../../packages/widgets/src/services/WebexInstanceService";
import { submitMetrics } from "../../packages/widgets/src/services/WebexMetricsService";
import { Access_Denied, businessNumberFailureMetric, businessNumberSuccessMetric, Cancel, Error_String, Error_Description, Error_Subcode, Need_Admin_Approval, presenceServerApiLatencyMetric, presenceServerApiReturnCodeMetric, presenceServiceFailureMetric, Require_Approval, userVisitedMetric } from "../../packages/widgets/src/utils/MetricUtils";
import { MS_AUTH_RESPONSE, WEBEX_AUTH_RESPONSE } from "../../packages/widgets/src/utils/commonUtils";
import { ClientBuilderFactory } from "../remote/ClientBuilderFactory";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from "../util/LocalStorageUtil";
import { getMSTeamsContext } from "../util/MsTeamsContextUtil";
import { checkAdminConsent } from './MSTeamsExtensionService';
import { RESPONSE_STATUS } from '../../packages/adapter-interfaces/src/IVoicemailAdapter';

// Access token could be used for 1 hour , but need to add some skew
const tokenExpiredAfterInMilliSeconds = 60 * 60 * 1000; // 1 hour
const tokenExpirationSkewInMilliSeconds = 2 * 60 * 1000; // 2 min

export enum TokenEnum {
  ACCESS_TOKEN_KEY = "accessToken",
  REFRESH_TOKEN_KEY = "refreshToken",
  TOKEN_EXPIRY_DATE_KEY = "accessTokenExpiryDate",
  CROSS_LAUNCH_TOKEN_KEY = "crossLaunchCallToken",
  TOKEN_STATE_KEY = "tokenStateForAuth",
  ACCESS_TOKEN_DECRYPT_KEY = "msAccessTokenDecrypt",
  REFRESH_TOKEN_DECRYPT_KEY = "msRefreshTokenDecrypt",
  VOICEMAIL_FEATURE_ENABLE = "webex_voiceMailFeatureEnabled",
  EMAIL_CROSS_LAUNCH_ENABLE = "webex_emailCrossLaunchEnabled"
}

export interface MsTeamsTokens {
  accessToken: string;
  accessTokenDecrypt: string;
  crossLaunchCallToken?: string;
  voiceMailFeatureEnabled?: string;
  webexEmailCrossLaunchEnabled?: string
}

export interface MsTeamsAuthenticationTokenResponse extends MsTeamsTokens {
  refreshToken: string;
  refreshTokenDecrypt: string;
  clientMetricToggles: string;
}

let tokenPendingPromise: Promise<MsTeamsTokens> | undefined;

/**
 * Composes a unique identifier key for where to store the token
 *
 * @param {string} key unique id denoting which token to be saved
 * @param {string} userId the user's Microsoft Teams user ID
 * @returns {string} unique identifier key for where to store the token
 */
export function composeLocalStorageTokenKey(
  key: string,
  userId: string
): string {
  return `${process.env.APP_NAME}-${process.env.TOKEN_VERSION}-${key}-${userId}`;
}

/**
 * Reads the url and parses the hash, returning the value with designated key
 *
 * @param {string} key key to find in the hash
 * @returns {string | null} value associated with key in the URL
 */
export function getHashValue(key: string): string | null {
  const matches = window.location.hash.match(new RegExp(`${key}=([^&]*)`));
  return matches && matches.length === 2
    ? decodeURIComponent(matches[1])
    : null;
}

/**
 * Saves the Microsoft Teams and Cross-launch tokens in their corresponding local storage locations
 *
 * @param {MsTeamsAuthenticationTokenResponse} tokens Token JSON object
 * @param {string} userId Microsoft Teams unique user ID
 */
export function saveTokens(
  tokens: MsTeamsAuthenticationTokenResponse,
  userId: string
): void {
  console.debug("Saving tokens...");
  clearAllSavedItems(true);
  const expiryDate =
    DateTime.utc().toMillis() +
    tokenExpiredAfterInMilliSeconds -
    tokenExpirationSkewInMilliSeconds;
  setLocalStorageItem(
    composeLocalStorageTokenKey(TokenEnum.ACCESS_TOKEN_KEY, userId),
    tokens.accessToken
  );
  setLocalStorageItem("accessToken", tokens.accessToken);
  setLocalStorageItem(
    composeLocalStorageTokenKey(TokenEnum.REFRESH_TOKEN_KEY, userId),
    tokens.refreshToken
  );

  setLocalStorageItem(
    composeLocalStorageTokenKey(TokenEnum.TOKEN_EXPIRY_DATE_KEY, userId),
    expiryDate
  );
  if (tokens.crossLaunchCallToken) {
    setLocalStorageItem(
      composeLocalStorageTokenKey(TokenEnum.CROSS_LAUNCH_TOKEN_KEY, userId),
      tokens.crossLaunchCallToken
    );
  }
  if (tokens.clientMetricToggles) {
    const clientMetricToggles = JSON.parse(tokens.clientMetricToggles);
    setLocalStorageItem(
      composeLocalStorageTokenKey(
        "msTeamsSplunkMetricsEnabledForClient",
        userId
      ),
      clientMetricToggles.msTeamsSplunkMetricsEnabledForClient
    );
    setLocalStorageItem(
      composeLocalStorageTokenKey(
        "msTeamsOperationalMetricsEnabledForClient",
        userId
      ),
      clientMetricToggles.msTeamsOperationalMetricsEnabledForClient
    );
    setLocalStorageItem(
      TokenEnum.ACCESS_TOKEN_DECRYPT_KEY,
      tokens.accessTokenDecrypt
    );
    setLocalStorageItem(
      TokenEnum.REFRESH_TOKEN_DECRYPT_KEY,
      tokens.refreshTokenDecrypt
    );
    if (tokens?.voiceMailFeatureEnabled) {
      setLocalStorageItem(
        TokenEnum.VOICEMAIL_FEATURE_ENABLE,
        tokens.voiceMailFeatureEnabled
      );
    }
    if (tokens?.webexEmailCrossLaunchEnabled) {
      setLocalStorageItem(
        TokenEnum.EMAIL_CROSS_LAUNCH_ENABLE,
        tokens.webexEmailCrossLaunchEnabled
      );
    }
    setLocalStorageItem("webex_presenceStatusExecuteOnce", true);
    setLocalStorageItem("webex_botServiceStatusExecuteOnce", true);
  }
}

/**
 * Begins the process of authenticating the user with Microsoft Teams
 *
 * @returns {Promise<MsTeamsAuthenticationTokenResponse>} promise containing the authorization tokens
 */
async function startMSTeamsAuth(): Promise<MsTeamsAuthenticationTokenResponse> {
  try {
  const context = await getMSTeamsContext();

    let authResponse = await getMSToken(context);
    const tokens =
      authResponse as unknown as MsTeamsAuthenticationTokenResponse;
    if (!context?.user?.id) {
      throw new Error("Error starting auth. userId is not defined");
    }
    saveTokens(tokens, context.user.id);
    return tokens;
  } catch (e) {
    Logger.error(`Error starting auth: ${formatError(e)}`);
    return Promise.reject(e);
  }
}

async function getMSToken(context:any){
  return await getClientSideToken()
        .then((clientSideToken) => {
            return getServerSideToken(clientSideToken, context);
        })
        .then((profile) => {
            return profile;
        })
        .catch((error) => {
          Logger.error("Error: " + error);
          return Promise.reject(error);
        })
}

async function getClientSideToken() {
  return new Promise((resolve, reject) => 
    {
      const authToken = authentication.getAuthToken().then((token) => {
        resolve(token);
      }).catch(function (error) {
        Logger.error(`Error getting token from client side: ${formatError(error)}.`);
        reject("Error getting token: " + error);
      }
    )})
}

async function getServerSideToken(clientSideToken:any, context:any) {
  return new Promise((resolve, reject) => 
      {
        const params = new URLSearchParams();
        params.append("tenantId", context!.user!.tenant!.id);
        params.append("assertion", clientSideToken);
        params.append("userObjectId", context!.user!.id);
        const response = axios.post(
          `${process.env.JABBER_INTEGRATION_URL}/msteams/${process.env.APP_NAME}/exchangeToken_obo`,
          params
        ).then((response) => {
          resolve(response.data);
        }).catch(function (error) {
          if (error?.response) {
            if(error.response.data?.message.includes("AADSTS65001")) {
              Logger.error(`Error getting token from server side: ${formatError(error.response?.status)} : ${formatError(error?.response?.data?.message)}.`);
              reject("Error getting token: " + error?.response?.data?.message);
            }
            Logger.error(`Error getting token from server side: ${formatError(error.response?.status)} : ${formatError(error.response?.data)}.`);
          } else if (error?.request) {
            Logger.error(`Error getting token from server side: ${formatError(error.request)}.`);
          } else {
            Logger.error(`Error getting token from server side: ${formatError(error?.message)}.`);
          }
          reject("Error getting token: " + error);
        });

      });
    }    

/**
 * Refreshes the Microsoft Teams and Cross-launch tokens using the tokens stored in local storage
 *
 * @param {string} refreshToken Microsoft teams refresh token used to re-authorize
 */
async function refreshMSToken(
  refreshToken: string
): Promise<MsTeamsAuthenticationTokenResponse> {
  const context = await getMSTeamsContext();
  if (!context?.user?.tenant?.id) {
    throw new Error("Error while performing refresh: tenantId is not defined");
  }
  if (!context?.user?.id) {
    throw new Error("Error while performing refresh: userId is not defined");
  }

  /**
  * without JIS server, need to add respective client id and secret 
  */

  // const data = {
  //   grant_type: 'refresh_token',
  //   refresh_token: refresh_token,
  //   client_id: `${process.env.CLIENT_ID}`,
  //   client_secret: `${process.env.CLIENT_SECRET}`,
  //   scope: `${process.env.SCOPE}`,
  //   redirect_uri: `${process.env.REDIRECT_URI}`,
  // };
  // const authResponse = await axios.post(`https://login.microsoftonline.com/${context?.user?.tenant?.id}/oauth2/v2.0/token`, qs.stringify(data));

  // const tokens =
  //   authResponse.data as unknown as MsTeamsAuthenticationTokenResponse;
  // saveTokens(tokens, context.user.id);
  // return tokens;

  try {
    const params = new URLSearchParams();
    params.append("tenantId", context.user.tenant.id);
    params.append("refreshToken", refreshToken);
    params.append("userObjectId", context.user.id);
    const authResponse = await axios.post(
      `${process.env.JABBER_INTEGRATION_URL}/msteams/${process.env.APP_NAME}/renewToken`,
      params
    );
    const tokens =
      authResponse.data as unknown as MsTeamsAuthenticationTokenResponse;
    saveTokens(tokens, context.user.id);
    // await presenceUserMapping();
    return tokens;
  } catch (e) {
    clearAllSavedItems(false);
    Logger.error(`Error while refreshing token: ${JSON.stringify(e)}`);
    throw e;
  }
}

/**
 * Pulls tokens from local storage, checks for missing token or refresh and returns a promise containing the requestable tokens
 *
 * @returns {Promise<MsTeamsTokens>} Promise of JSON object containing MS authorization token and Webex cross-launch token
 */
async function getTokenAndRefreshIfNecessary(isContinue: boolean): Promise<MsTeamsTokens> {

  const context = await getMSTeamsContext();
  if (!context?.user?.id) {
    return Promise.reject(
      new Error("Error fetching ms token: userId is not defined")
    );
  }
  const accessToken = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(TokenEnum.ACCESS_TOKEN_KEY, context.user.id)
  );
  const accessTokenDecrypt = getLocalStorageItem<string>(
    TokenEnum.ACCESS_TOKEN_DECRYPT_KEY
  );
  const expiryDate = getLocalStorageItem<number>(
    composeLocalStorageTokenKey(
      TokenEnum.TOKEN_EXPIRY_DATE_KEY,
      context.user.id
    )
  );
  const refreshToken = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(TokenEnum.REFRESH_TOKEN_KEY, context.user.id)
  );
  const refreshTokenDecrypt = getLocalStorageItem<string>(
    TokenEnum.REFRESH_TOKEN_DECRYPT_KEY
  );
  const crossLaunchCallToken = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(
      TokenEnum.CROSS_LAUNCH_TOKEN_KEY,
      context.user.id
    )
  );
  if ((!accessToken || !expiryDate || !refreshToken || !crossLaunchCallToken) && isContinue) {
    console.debug("Tokens missing. Authenticating...");
    return startMSTeamsAuth();
  }
  if (expiryDate && expiryDate <= DateTime.utc().toMillis()) {
    console.debug("Token expired. Refreshing...");
    return refreshMSToken(refreshToken);
  }
  return Promise.resolve({
    accessToken,
    accessTokenDecrypt,
    refreshTokenDecrypt,
    crossLaunchCallToken,
  });
}

/**
 * If there is an outstanding request for a token, it returns that same promise (singleton design), ensuring that it
 * does not try to refresh the same tokens multiple times at once.
 *
 * @returns {Promise<MsTeamsTokens>} promise containing the user queryable tokens
 */
function getTokenHelper(isContinue: boolean): Promise<MsTeamsTokens> {
  if (!tokenPendingPromise) {
    tokenPendingPromise = getTokenAndRefreshIfNecessary(isContinue);
    tokenPendingPromise.finally(() => {
      tokenPendingPromise = undefined;
    });
  }
  return tokenPendingPromise;
}

/**
 * Returns the Microsoft Teams auth token, authorizing and refreshing as necessary
 *
 * @returns {Promise<string>} Microsoft Teams auth token
 */
export async function getMSTeamsToken(isContinue: boolean): Promise<string> {
  const tokens = await getTokenHelper(isContinue);
  return tokens.accessTokenDecrypt;
}

export async function getDecryptMSTeamsAccessToken(): Promise<string> {
  const accessTokenDecrypt = getLocalStorageItem<string>(
    TokenEnum.ACCESS_TOKEN_DECRYPT_KEY
  );
  return accessTokenDecrypt;
}

export async function getDecryptMSTeamsRefreshToken(): Promise<string> {
  const refreshTokenDecrypt = getLocalStorageItem<string>(
    TokenEnum.REFRESH_TOKEN_DECRYPT_KEY
  );
  return refreshTokenDecrypt;
}

export async function webexEmailCrossLaunchEnabled(): Promise<string> {
  const context = await getMSTeamsContext();
  if (!context?.user?.id) {
    return Promise.reject(
      new Error("Error fetching ms token: userId is not defined")
    );
  }
  const emailCrosslaunchEnable = getLocalStorageItem<string>(
    TokenEnum.EMAIL_CROSS_LAUNCH_ENABLE, context.user.id
  );
  return emailCrosslaunchEnable;
  
}

export async function msCrossLaunchToken(): Promise<string> {
  const context = await getMSTeamsContext();
  if (!context?.user?.id) {
    return Promise.reject(
      new Error("Error fetching ms token: userId is not defined")
    );
  }
  const crossLaunchTokenKey = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(
      TokenEnum.CROSS_LAUNCH_TOKEN_KEY,
      context.user.id
    )
  );
  return crossLaunchTokenKey;
}

export async function voiceMailEnabled(): Promise<string> {
  const context = await getMSTeamsContext();
  if (!context?.user?.id) {
    return Promise.reject(
      new Error("Error fetching ms token: userId is not defined")
    );
  }
  const voiceMailEnabled = getLocalStorageItem<string>(
    TokenEnum.VOICEMAIL_FEATURE_ENABLE, context.user.id
  );
  return voiceMailEnabled;
}

export async function makeAcallToken() {
  const webexcrossLaunchCallToken = await getCrossLaunchToken();
  const mscrossLaunchToken = await msCrossLaunchToken();
  const isVoiceMailEnabled = await voiceMailEnabled();
  const isWebexEmailCrossLaunchEnabled = await webexEmailCrossLaunchEnabled();
  if (isWebexEmailCrossLaunchEnabled === "true" && isVoiceMailEnabled === "true") {
    setLocalStorageItem(MAKE_A_CALL_KEYS.MAKE_A_CALL_TOKEN, webexcrossLaunchCallToken);
    window.dispatchEvent(new Event("storage"));
  } else {
    setLocalStorageItem(MAKE_A_CALL_KEYS.MAKE_A_CALL_TOKEN, mscrossLaunchToken);
    window.dispatchEvent(new Event("storage"));
  }
}
/**
 * Begins the authorization process for MS Teams
 */
export async function authStart() {
  const urlParams = new URLSearchParams(window.location.search);
  const loginHint = urlParams.get("loginHint");
  const authId = urlParams.get("authId");
  const oauthRedirectMethod = urlParams.get("oauthRedirectMethod");
  const tenantId = urlParams.get("tenantId");
  const clientType = urlParams.get("clientType");

  let state  = {"authId": authId, "oauthRedirectMethod" : oauthRedirectMethod, "clientType": clientType};
  const queryParams: { [key: string]: string } = {
    client_id: process.env.CLIENT_ID!,
    response_type: "code",
    response_mode: "fragment",
    scope: "https://graph.microsoft.com/.default offline_access",
    redirect_uri: `${window.location.origin}/${process.env.APP_NAME}/auth-end/loader`,
    login_hint: loginHint? loginHint : "",
    nonce: uuid(),
    state: state ? encodeURIComponent(JSON.stringify(state)) : "",
  };
  const authorizeUrl = new URL(
    `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`
  );
  const params = new URLSearchParams(queryParams).toString();
  window.location.assign(`${authorizeUrl}?${params}`);
}

/**
 * Finalizes the authorization process for MS Teams. The user is redirected to this URL after authorizing via Azure
 */
export async function authEnd() {
  // Initialize MS
  const context = await getMSTeamsContext().catch((error) => {
    Logger.error(error);
  });
  const errorFragment = getHashValue("error");
  const errorSubcode = getHashValue("error_subcode");
  const errorDescription = getHashValue("error_description");
  const code = getHashValue("code");

  let isError = false;
  let errorMessage = "";

  if (
    errorFragment === "access_denied" &&
    errorSubcode === "cancel" &&
    errorDescription &&
    errorDescription.indexOf("AADSTS65004") >= 0
  ) {
    isError = true;
    errorMessage = "require_approval";
  } else if (errorFragment === "access_denied" && errorSubcode === "cancel") {
    isError = true;
    errorMessage = "need_admin_approval";
  } else if (errorFragment) {
    isError = true;
    errorMessage = `error_fragment: ${errorFragment}`;
  } else if (!code) {
    isError = true;
    errorMessage = "code_not_found";
  }

  const state = getHashValue("state");
  const stateJson = JSON.parse(state ? decodeURIComponent(state): '{}');
  const clientType = stateJson?.clientType;

  if (isError){
    if (clientType !== HostClientType.desktop && clientType !== HostClientType.web) {
      window.location.assign(`msteams://teams.microsoft.com/l/auth-callback?authId=${stateJson?.authId}&result=ERROR.${errorMessage}`);
    } else {
      authentication.notifyFailure(errorMessage);
    }
    return;
  }

  if (clientType !== HostClientType.desktop && clientType !== HostClientType.web) { //Mobile
      window.location.assign(`msteams://teams.microsoft.com/l/auth-callback?authId=${stateJson?.authId}&result=${code}`);
  } else {
      authentication.notifySuccess(code? code : "");
  }
}

export interface IWebexAuthTokens {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  refresh_token_expires_in?: number;
  webex_calling_backend?: string;
  webex_userId?: string;
  webex_orgId?: string;
  webex_cross_launch_token?: string;
}

export enum WebexTokenEnum {
  AUTH_TOKEN = "webex_auth_token",
  REFRESH_TOKEN = "webex_refresh_token",
  AUTH_TOKEN_EXPIRY = "webex_auth_token_expiry",
  REFRESH_TOKEN_EXPIRY = "webex_refresh_token_expiry",
  USER_CALLING_BACKEND = "webex_userCallingBackend",
  USER_ID = "webex_userId",
  ORG_ID = "webex_orgId",
  WEBEX_CROSS_LAUNCH_TOKEN = "webex_crossLaunchToken"
}

export function saveWebexTokens(tokens: IWebexAuthTokens) {
  setLocalStorageItem(WebexTokenEnum.AUTH_TOKEN, tokens?.access_token);
  setLocalStorageItem(WebexTokenEnum.REFRESH_TOKEN, tokens?.refresh_token);
  setLocalStorageItem(
    WebexTokenEnum.AUTH_TOKEN_EXPIRY,
    (new Date().getTime() + (tokens?.expires_in || 0) * 1000).toString()
  );
  setLocalStorageItem(
    WebexTokenEnum.REFRESH_TOKEN_EXPIRY,
    (
      new Date().getTime() +
      (tokens?.refresh_token_expires_in || 0) * 1000
    ).toString()
  );
  setLocalStorageItem(
    WebexTokenEnum.USER_CALLING_BACKEND,
    tokens?.webex_calling_backend
  );
  setLocalStorageItem(WebexTokenEnum.USER_ID, btoa(`${tokens?.webex_userId}`));
  setLocalStorageItem(WebexTokenEnum.ORG_ID, tokens?.webex_orgId);
  if (tokens?.webex_cross_launch_token) {
    setLocalStorageItem(WebexTokenEnum.WEBEX_CROSS_LAUNCH_TOKEN, tokens?.webex_cross_launch_token);
  }
  initWebex(tokens?.access_token as string);
}

function isTokenExpired(tokenExpiryDate: number, timeFormat = 1) {
  return (
    !Boolean(tokenExpiryDate) ||
    Boolean(new Date().getTime() >= timeFormat * parseInt(`${tokenExpiryDate}`))
  );
}

export async function getWebexTokens(isContinue: boolean, useDifferentAccount?: boolean): Promise<IWebexAuthTokens> {
  try {
    const access_token = getLocalStorageItem<string>(WebexTokenEnum.AUTH_TOKEN);
    const refresh_token = getLocalStorageItem<string>(
      WebexTokenEnum.REFRESH_TOKEN
    );
    const expires_in = getLocalStorageItem<number>(
      WebexTokenEnum.AUTH_TOKEN_EXPIRY
    );
    const refresh_token_expires_in = getLocalStorageItem<number>(
      WebexTokenEnum.REFRESH_TOKEN_EXPIRY
    );
    const webex_calling_backend = getLocalStorageItem<string>(
      WebexTokenEnum.USER_CALLING_BACKEND
    );
    const webex_userId = getLocalStorageItem<string>(WebexTokenEnum.USER_ID);
    const webex_orgId = getLocalStorageItem<string>(WebexTokenEnum.ORG_ID);
    const webex_crossLaunchToken = getLocalStorageItem<string>(WebexTokenEnum.WEBEX_CROSS_LAUNCH_TOKEN);
    if (
      (!access_token ||
        !refresh_token ||
        !expires_in ||
        !refresh_token_expires_in ||
        !webex_calling_backend ||
        !webex_userId ||
        !webex_crossLaunchToken ||
        isTokenExpired(refresh_token_expires_in)) && isContinue
    ) {
      return startWebexAuth(useDifferentAccount);
    }
    if (isTokenExpired(expires_in)) {
      return refreshWebexToken();
    }
    return {
      access_token,
      refresh_token,
      expires_in,
      refresh_token_expires_in,
      webex_calling_backend,
      webex_userId,
      webex_orgId,
      webex_crossLaunchToken,
    } as unknown as IWebexAuthTokens
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Returns the cross-launch token, authorizing and refreshing as necessary
 *
 * @returns {Promise<string>} cross-launch token
 */
export async function getCrossLaunchToken(): Promise<string> {
  const crossLanuchToken = getLocalStorageItem<string>(
    WebexTokenEnum.WEBEX_CROSS_LAUNCH_TOKEN
  );
  return crossLanuchToken;
}

export async function exchangeOrRefreshWebexTokenRequest(
  data: any
): Promise<IWebexAuthTokens> {
  const url = `${process.env.JABBER_INTEGRATION_URL}/webex/token`;

  const rawClient = await ClientBuilderFactory.getRawClient();
  const exchangeTokenResponse = await rawClient.request({
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify(data),
    url,
  });
  const tokens = exchangeTokenResponse.data as IWebexAuthTokens;
  return tokens;
}

export async function exchangeWebexCode(): Promise<IWebexAuthTokens> {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get("code");
    const data = {
      grantType: "authorization_code",
      code: authCode,
      clientId: process.env.WEBEX_CLIENT_ID,
    };
    const tokens = await exchangeOrRefreshWebexTokenRequest(data);
    await saveWebexTokens(tokens);
    return tokens;
  } catch (e) {
    Logger.error(`Error while exchanging token: ${JSON.stringify(e)}`);
    throw e;
  }
}

export async function refreshWebexToken(): Promise<IWebexAuthTokens> {

  /**
  * without JIS server, need to add respective client id and secret 
  */

  //   try {
  //     const refresh_token = getLocalStorageItem<string>(WebexTokenEnum.REFRESH_TOKEN);
  //     const params = new URLSearchParams();
  //     params.append('grant_type', 'refresh_token');
  //     params.append('refresh_token', refresh_token);
  //     params.append('client_id', `${process.env.WEBEX_CLIENT_ID}`);
  //     params.append('client_secret', `${process.env.WEBEX_CLIENT_SECRET}`);
  //     params.append('scope', `${process.env.WEBEX_SCOPE}`);
  //     params.append('redirect_uri', `${process.env.WEBEX_REDIRECT_URI}`);
  //     const data = {};
  //     const response = await axios({
  //     method: "POST",
  //     url: `${process.env.WEBEX_BASE_URL}v1/access_token`,
  //     params: params,
  //     data: data,
  //     headers: { "content-type": "application/x-www-form-urlencoded" },
  //   });
  //     const tokens = response.data;
  //     await saveWebexTokens(tokens);
  //     return tokens;
  //   } catch (e) {
  //     Logger.error(`Error while refreshing token: ${JSON.stringify(e)}`);
  //     throw e;
  // }

  try {
    const refresh_token = getLocalStorageItem<string>(
      WebexTokenEnum.REFRESH_TOKEN
    );
    const data = {
      clientId: process.env.WEBEX_CLIENT_ID,
      grantType: "refresh_token",
      refreshToken: refresh_token,
    };
    const tokens = await exchangeOrRefreshWebexTokenRequest(data);
    await saveWebexTokens(tokens);
    setLocalStorageItem("webex_presenceStatusExecuteOnce", true);
    setLocalStorageItem("webex_botServiceStatusExecuteOnce", true);
    // await presenceUserMapping();
    return tokens;
  } catch (e) {
    clearAllSavedItems(false);
    Logger.error(`Error while refreshing token: ${JSON.stringify(e)}`);
    throw e;
  }
}

export async function webexAuth() {
  const urlParams = new URLSearchParams(window.location.search);
  const useDifferentAccount = urlParams.get("useDifferentAccount");
  const loginHint = urlParams.get("loginHint");
  const authId = urlParams.get("authId");
  const oauthRedirectMethod = urlParams.get("oauthRedirectMethod");
  const clientType = urlParams.get("clientType");

  let state  = {"authId": authId, "oauthRedirectMethod" : oauthRedirectMethod, "clientType" : clientType};

  const queryParams: { [key: string]: string } = {
    client_id: `${process.env.WEBEX_CLIENT_ID}`,
    response_type: "code",
    redirect_uri: `${process.env.WEBEX_REDIRECT_URI}`,
    scope: `${process.env.WEBEX_SCOPE}`,
    state: state ? encodeURIComponent(JSON.stringify(state)) : "",
    login_hint: loginHint ? loginHint : "",
    email: loginHint ? loginHint : "",
  };
  if(useDifferentAccount === "true") {
    queryParams.prompt = "select_account";
    queryParams.login_hint = "";
    queryParams.email = "";
  }
  const authorizeUrl = new URL(`${process.env.WEBEX_BASE_URL}/v1/authorize`);
  const params = new URLSearchParams(queryParams).toString();
  window.location.assign(`${authorizeUrl}?${params}`);
}

export async function startWebexAuth(useDifferentAccount?: boolean): Promise<IWebexAuthTokens> {
  try {
    const context = await getMSTeamsContext();
    const loginHint = context?.user?.loginHint;
    const clientType = context?.app?.host?.clientType;
    let isExternal = false;

    if (clientType !== HostClientType.desktop && clientType !== HostClientType.web) {
      isExternal = true;
    }
    const authResponseKey = await authentication.authenticate({
      url: `${window.location.origin}/${process.env.APP_NAME}/webex-auth-start/loader?oauthRedirectMethod={oauthRedirectMethod}&authId={authId}&clientType=${clientType}&useDifferentAccount=${useDifferentAccount ? useDifferentAccount : false}&loginHint=${loginHint}`,
      width: 550,
      height: 450,
      isExternal: isExternal,
    }).catch((error) => {
      return Promise.reject(error);
  });
    if (!authResponseKey || authResponseKey === ''){
      throw new Error('Auth Code is Empty');
    } else if (authResponseKey && authResponseKey.startsWith("ERROR.")){
      throw new Error(authResponseKey.slice(6));
    }

    const token = await getWebexAuthToken(authResponseKey ? authResponseKey: '');

    const tokens = token as unknown as IWebexAuthTokens;
    await saveWebexTokens(tokens);
    return tokens;
  } catch (err) {
    Logger.error(`Webex failureCallback:'${err}'.`);
    // TODO: Voicemail business metrics
    // voiceMailBusinessMetricsClient.sendVoiceMailMetrics({ metricEvent: 'authorization_code', responseStatus: 'Error' });
    throw new Error("Failed to webex sign in");
  }
}
export async function webexExchangeToken() {

  /**
  * without JIS server, need to add respective client id and secret 
  */

  // const params = new URLSearchParams();
  // const urlParams = new URLSearchParams(window.location.search);
  // const authCode = urlParams.get('code');
  //   params.append('grant_type', 'authorization_code');
  //   params.append('code', authCode + '');
  //   params.append('client_id', `${process.env.WEBEX_CLIENT_ID}`);
  //   params.append('client_secret', `${process.env.WEBEX_CLIENT_SECRET}`);
  //   params.append('scope', `${process.env.WEBEX_SCOPE}`);
  //   params.append('redirect_uri', `${process.env.WEBEX_REDIRECT_URI}`);

  // const data = {};
  // const response = await axios({
  //   method: "POST",
  //   url: "https://integration.webexapis.com/v1/access_token",
  //   params: params,
  //   data: data,
  //   headers: { "content-type": "application/x-www-form-urlencoded" },
  // });
  // authentication.notifySuccess(response.data);
  // Initialize MS
  const context = await getMSTeamsContext().catch((error) => {
    Logger.error(error);
  });
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const stateJson = JSON.parse(state ? decodeURIComponent(state): '{}');
  const clientType = stateJson?.clientType;

  const errorFragment = urlParams.get(Error_String);
  const errorSubcode = urlParams.get(Error_Subcode);
  const errorDescription = urlParams.get(Error_Description);
  let isError = false;
  let errorMessage = "";

  if (
    errorFragment === Access_Denied &&
    errorSubcode === Cancel &&
    errorDescription
  ) {
    isError = true;
    errorMessage = Require_Approval;
  } else if (errorFragment === Access_Denied && errorSubcode === Cancel) {
    isError = true;
      errorMessage = Need_Admin_Approval;
    } else if (errorFragment) {
    isError = true;
    errorMessage = `error_fragment: ${errorFragment}`;
  } else if (!code) {
    isError = true;
    errorMessage = "code_not_found";
  }

  // TODO Handle Error cases

  if (isError){
    if (clientType !== HostClientType.desktop && clientType !== HostClientType.web) {
      window.location.assign(`msteams://teams.microsoft.com/l/auth-callback?authId=${stateJson?.authId}&result=ERROR.${errorMessage}`);
    } else {
      authentication.notifyFailure(errorMessage);
    }
    return;
  }

  if (clientType !== HostClientType.desktop && clientType !== HostClientType.web) {
    window.location.assign(`msteams://teams.microsoft.com/l/auth-callback?authId=${stateJson?.authId}&result=${code}`);
  }
  else {
    authentication.notifySuccess(code? code: "");
  }
}


async function getWebexAuthToken (code: string) {
  const data = {
    grantType: "authorization_code",
    code: code,
    clientId: `${process.env.WEBEX_CLIENT_ID}`,
  };
  const url = `${process.env.JABBER_INTEGRATION_URL}/webex/token`;
  const response = await axios({
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify(data),
    url,
  });
  return response?.data;
}

export async function grantPermission() {
  const clientId = `${process.env.CLIENT_ID}`;
  const msftBaseUrl = "https://login.microsoftonline.com";
  const context = await getMSTeamsContext();
  const redirect_uri = `${window.location.origin}/${process.env.APP_NAME}/auth-end/loader`;
  return `${msftBaseUrl}/${context.user?.tenant?.id}/v2.0/adminconsent?client_id=${clientId}&scope=https://graph.microsoft.com/.default&redirect_uri=${redirect_uri}`;
}

export async function getMSAccessTokenPromise() {
  const context = await getMSTeamsContext();
  if (!context?.user?.id) {
    return Promise.reject(
      new Error("Error fetching ms token: userId is not defined")
    );
  }
  const access_token = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(TokenEnum.ACCESS_TOKEN_KEY, context.user.id)
  );
  const expiryDate = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(
      TokenEnum.TOKEN_EXPIRY_DATE_KEY,
      context.user.id
    )
  );
  const refresh_token = getLocalStorageItem<string>(
    composeLocalStorageTokenKey(TokenEnum.REFRESH_TOKEN_KEY, context.user.id)
  );
  if (!expiryDate || new Date().getTime() >= parseInt(expiryDate)) {
    refreshMSToken(refresh_token);
  }
  if (!refresh_token || !access_token) {
    return true;
  } else {
    const adminConsent = await checkAdminConsent();
    if(!adminConsent){
      // Invalid token, require Admin Grant Permission
      removeLocalStorageItem(composeLocalStorageTokenKey(TokenEnum.ACCESS_TOKEN_KEY, context.user.id));
      return true;
    }
    return false;
  }
}

export async function getWebexAccessTokenPromise() {
  const access_token = getLocalStorageItem<string>(WebexTokenEnum.AUTH_TOKEN);
  const refresh_token = getLocalStorageItem<string>(
    WebexTokenEnum.REFRESH_TOKEN
  );
  const expires_in = getLocalStorageItem<number>(
    WebexTokenEnum.AUTH_TOKEN_EXPIRY
  );
  const refresh_token_expires_in = getLocalStorageItem<number>(
    WebexTokenEnum.REFRESH_TOKEN_EXPIRY
  );
  if (!refresh_token || !access_token) {
    return true;
  }
  if (isTokenExpired(expires_in)) {
    try {
      let tokens = await refreshWebexToken();
      if (!tokens) {
        return true;
      }
    } catch (e) {
      return true;
    }
  }
  return false;
} 

export async function getBusinessNumber(): Promise<IBusinessNumberResponse> {
  const url = `${process.env.WEBEX_BASE_URL}` + ImeURL;
  const access_token = getLocalStorageItem<string>(WebexTokenEnum.AUTH_TOKEN);
  submitMetrics(
    {
      metricEvent: userVisitedMetric
    });
  try {
    const businessNumberResponse = await axios({
      method: "GET",
      headers: { Authorization: "Bearer " + access_token },
      url,
    });
    const response = businessNumberResponse.data as IBusinessNumberResponse;
    submitMetrics(
      {
        metricEvent: businessNumberSuccessMetric,
      }
    );
    Logger.info("getting the Business number User details");
    return response;
  } catch (error) {
    submitMetrics(
      {
        metricEvent: businessNumberFailureMetric,
      }
    );
    Logger.error(`Error getting Businessnumber(): '${formatError(error)}'.`);
    throw error;
  }
}

export async function postUsermapping() {
  const org_Id = getLocalStorageItem<string>(WebexTokenEnum.ORG_ID);
  const url = `${process.env.WEBEX_U2C_BASE_URL}/org/${org_Id}/catalog?services=msft-presence-service&format=servicelist`;
  const access_token = getLocalStorageItem<string>(WebexTokenEnum.AUTH_TOKEN);
  try {
    const u2cApiResponse = await axios({
      method: "GET",
      headers: { Authorization: "Bearer " + access_token },
      url,
    });
    const u2cResPonse = u2cApiResponse.data as IUserMapping;
    const getMsftServiceUrl = await Promise.all([
      u2cResPonse?.services.map((item) =>
        item?.serviceUrls
          .map((presencebaseUrl) => presencebaseUrl.baseUrl)
          .toString()
      ),
    ]);
    Logger.info(`Presence mapping URL: ${getMsftServiceUrl}`);
    const userMappingURL = `${getMsftServiceUrl}/userMapping`;
    const startTime = new Date();
    let endTime;
    const webex_access_token = getLocalStorageItem<string>(WebexTokenEnum.AUTH_TOKEN);
    const msft_access_token = await getDecryptMSTeamsAccessToken();
    const msft_refresh_token = await getDecryptMSTeamsRefreshToken();
    let presenceResponseStatus;
    let presenceServerApiLatency;
    try {
      const userMappingApiResponse = await axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${webex_access_token}`,
          msfttoken: `${msft_access_token}`,
          msftrefreshtoken: `${msft_refresh_token}`,
          webexToken: `${webex_access_token}`,
        },
        url: userMappingURL,
      });
      presenceResponseStatus = userMappingApiResponse.status.toString();
      Logger.info(`Presence user mapping status code response: ${presenceResponseStatus}`);
      endTime = new Date();
      presenceServerApiLatency = Math.floor(
        (endTime.getTime() - startTime.getTime()) / 1000
      );
      submitMetrics(
        {
          metricEvent: presenceServerApiLatencyMetric,
          presenceServerApiLatency: `${presenceServerApiLatency}`,
        }
      );
      submitMetrics(
        {
          metricEvent: presenceServerApiReturnCodeMetric,
          presenceServerApiReturnCode: `${presenceResponseStatus}`,
        }
      );
      removeLocalStorageItem("webex_presenceStatusExecuteOnce");
    } catch (error) {
      endTime = new Date();
      presenceServerApiLatency = Math.floor(
        (endTime.getTime() - startTime.getTime()) / 1000
      );
      presenceResponseStatus = await (
        error as IErrorResponse
      ).response?.status?.toString();
      submitMetrics(
        {
          metricEvent: presenceServerApiLatencyMetric,
          presenceServerApiLatency: `${presenceServerApiLatency}`
        }
      );
      submitMetrics(
        {
          metricEvent: presenceServerApiReturnCodeMetric,
          presenceServerApiReturnCode: `${presenceResponseStatus}`
        }
      );
      submitMetrics(
        {
          metricEvent: presenceServiceFailureMetric
        }
      );
      throw error;
    }
    return userMappingURL;
  } catch (error) {
    throw error;
  }
}

export async function presenceUserMapping(): Promise<any> {
  if (getLocalStorageItem("webex_presenceStatusExecuteOnce")) {
    if (window?.isWebexInitalized) {
        Logger.info(`PostUsermapping funtion triggering`);
        await postUsermapping();
    } else {
      await new Promise(f => setTimeout(f, 1000));
      return presenceUserMapping();
    }
  } 
}

/**
 * 
 * Function to validate U2C URL and post user mapping for the bot service
 */
export async function postBotServiceUsermapping() {
  const orgId = getLocalStorageItem<string>(WebexTokenEnum.ORG_ID);
  const accessToken = getLocalStorageItem<string>(WebexTokenEnum.AUTH_TOKEN);

  if (!orgId || !accessToken) {
    Logger.info("Missing organization ID or access token in local storage.");
    throw new Error("Missing required parameters.");
  }

  const baseUrl = process.env.WEBEX_U2C_BASE_URL;
  const catalogUrl = `${baseUrl}/org/${orgId}/catalog?services=wxc-msteams-bot&format=servicelist`;

  try {
    // Fetch U2C service response
    const u2cApiResponse = await axios.get<IUserMapping>(catalogUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const serviceUrls = u2cApiResponse.data?.services.flatMap((service) =>
      service.serviceUrls.map((url) => url.baseUrl)
    );

    if (!serviceUrls?.length) {
      Logger.info("No bot service URLs found in U2C response.");
      throw new Error("Failed to retrieve bot service URLs.");
    }

    const userMappingUrl = `${serviceUrls[0]}/usermapping`;
    Logger.info(`Bot service user mapping URL: ${userMappingUrl}`);

    // Decrypt MS Teams access token
    const msftAccessToken = await getDecryptMSTeamsAccessToken();
    if (!msftAccessToken) {
      Logger.info("Failed to retrieve MS Teams access token.");
      throw new Error("Missing MS Teams access token.");
    }

    // Call the bot service user mapping API
    const userMappingApiResponse = await axios.post(
      userMappingUrl,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          msftToken: msftAccessToken
        },
      }
    );

    Logger.info(`Bot service user mapping status code response: ${userMappingApiResponse.status}`);
    removeLocalStorageItem("webex_botServiceStatusExecuteOnce");
  } catch (error) {
    Logger.error(`Error in bot service user mapping: ${error}`);
  }
}

export async function botServiceUserMapping(): Promise<any> {
  if (getLocalStorageItem("webex_botServiceStatusExecuteOnce")) {
    if (window?.isWebexInitalized) {
        Logger.info(`Bot service Usermapping funtion invoking`);
        await postBotServiceUsermapping();
    } else {
      await new Promise(f => setTimeout(f, 1000));
      return botServiceUserMapping();
    }
  } 
}

export function clearAllSavedItems(isMSTeamsAuthRenewToken: boolean) {
  // Don't want to clear the webex related details when the MSTeams renew token get triggered
  if (isMSTeamsAuthRenewToken) {
    for (let key in localStorage) {
      if (key.startsWith(`${process.env.APP_NAME}` + "-") && !(key.startsWith(`${process.env.APP_NAME}` + "-" + `${process.env.TOKEN_VERSION}` + "-webex"))) {
        localStorage.removeItem(key);
      }
    }
    return;
  }

  for (let key in localStorage) {
    if (key.startsWith(`${process.env.APP_NAME}` + "-")) {
      localStorage.removeItem(key);
    }
    if (key.startsWith('webex_')) {
      localStorage.removeItem(key);
    }
  }
  return;
}
