import { t } from "i18next";
import { Observable, ReplaySubject, from } from "rxjs";

import { Logger, formatError } from "../../../../logger/src/Logger";
import {
    getSubscriptionStatus,
    getPhoneServicesData,
    getErrorEventData
} from "../../services/WebexEventListener";
import { IEventAdapter, IIntegrationErrorEventData, IntegrationErrorResponse, IntegrationPhoneServicesResponse, IPhoneServicesEventData, ISubscriptionEventData, SubscriptionEventResponse } from "../../../../adapter-interfaces/src/IEventAdapter";



export class WebexEventsAdapter implements IEventAdapter {
    subcriptionObservables$!: ReplaySubject<ISubscriptionEventData>;
    getPhoneServicesSubcriptionObservables$!: ReplaySubject<IPhoneServicesEventData>;
    getErrorEventSubscriptionObservables$!: ReplaySubject<IIntegrationErrorEventData>;

    parseSubscriptionData(response: SubscriptionEventResponse): ISubscriptionEventData {

        return {
            eventType: response?.data?.eventType ? response?.data?.eventType : '',
            id: response?.data?.id ? response?.data?.id : '', //to do need to pick right id
            subscriptionId: response?.data?.subscriptionId ? response?.data?.subscriptionId : '',
            status: response?.data?.payload?.status ? response?.data?.payload?.status : ''
        }

    }

    refreshSubscriptionStatus() {
        const data$ = from(getSubscriptionStatus());
        data$.subscribe((data: SubscriptionEventResponse) => {
            this.subcriptionObservables$.next(this.parseSubscriptionData(data));
        });
    }

    getSubscriptionData(): Observable<ISubscriptionEventData> {
        if (!this.subcriptionObservables$) {
            this.subcriptionObservables$ = new ReplaySubject<ISubscriptionEventData>();
            this.refreshSubscriptionStatus();
        }
        return this.subcriptionObservables$;
    }

    //phone services logic
    parsePhoneServicesSubscriptionData(response: IntegrationPhoneServicesResponse): IPhoneServicesEventData {

        return {
            eventType: response?.data?.eventType ? response?.data?.eventType : '',
            id: response?.id ? response?.id : '',
            subscriptionId: response?.data?.subscriptionId ? response?.data?.subscriptionId : '',
            status: response?.data?.payload?.status ? response?.data?.payload?.status : ''
        }

    }

    refreshPhoneServicesSubscriptionStatus() {
        const data$ = from(getPhoneServicesData());
        data$.subscribe((data: IntegrationPhoneServicesResponse) => {
            this.getPhoneServicesSubcriptionObservables$.next(this.parsePhoneServicesSubscriptionData(data));
        });
    }

    getPhoneServicesData(): Observable<IPhoneServicesEventData> {
        if (!this.getPhoneServicesSubcriptionObservables$) {
            this.getPhoneServicesSubcriptionObservables$ = new ReplaySubject<IPhoneServicesEventData>();
            this.refreshPhoneServicesSubscriptionStatus();
        }
        return this.getPhoneServicesSubcriptionObservables$;
    }

    //error subscription event logic

    parseErrorEventSubscriptionData(response: IntegrationErrorResponse): IIntegrationErrorEventData {
        return {
            eventType: response?.data?.eventType ? response?.data?.eventType : '',
            id: response?.data?.id ? response?.data?.id : '',
            subscriptionId: response?.data?.subscriptionId ? response?.data?.subscriptionId : '',
            errorCode: response?.data?.payload?.error ? response?.data?.payload?.error : '',
            errorDescription: response?.data?.payload?.description ? response?.data?.payload?.description : ''
        }
    }

    refreshErrorSubscriptionStatus() {
        const data$ = from(getErrorEventData());
        data$.subscribe((data: IntegrationErrorResponse) => {
            this.getErrorEventSubscriptionObservables$.next(this.parseErrorEventSubscriptionData(data));
        });
    }

    getErrorEventData(): Observable<IIntegrationErrorEventData> {
        if (!this.getErrorEventSubscriptionObservables$) {
            this.getErrorEventSubscriptionObservables$ = new ReplaySubject<IIntegrationErrorEventData>();
            this.refreshErrorSubscriptionStatus();
        }
        return this.getErrorEventSubscriptionObservables$;
    }

}
