import { TextInput } from '@momentum-ui/react-collaboration';
import { IWebexIntContact } from '@webex/component-adapter-interfaces/dist/esm/src';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMakeCall } from '../hooks/useMakeCall';
import { useSearchDropdown } from '../hooks/useSearchDropdown';
import useWebexClasses from '../hooks/useWebexClasses';
import './WebexMobileSearchContacts.styles.scss';

export interface IWebexMobileSearchContactsProps {
  label?: string;
  noContactsFoundMessage?: string;
  style?: React.CSSProperties;
  minSearchLength?: number;
  onInputChange?: (input: string) => void;
  onUserSelect?: (user: IWebexIntContact | undefined) => void;
  onDropdownHide?: () => void;
  hideDropdownSource?: boolean;
  makeCall?:(address: string, video?: boolean) => void;
}

export type WebexMobileSearchContactsHandle = {
  appendValueToInput: (toAppend: string) => void;
  backSpaceAction: () => void;
  getInputValue: () => string;
  callButtonPressAction: () => void;
  clearInput: () => void;
};

/**
 * Description for this component.
 *
 * @param {IWebexSearchContactsProps} props Props for this component
 * @param props.style optional styles to add
 * @param props.label label to display in the search bar
 * @param props.noContactsFoundMessage text to output when no users are found
 * @param props.minSearchLength minimum text length required before searching
 * @param props.inputId id to make the search input
 */
export const WebexMobileSearchContacts = forwardRef<
WebexMobileSearchContactsHandle,
  IWebexMobileSearchContactsProps
>(
  (
    {
      label,
      noContactsFoundMessage,
      style,
      minSearchLength,
      onInputChange,
      onUserSelect,
      onDropdownHide,
      hideDropdownSource,
      makeCall
    },
    ref
  ) => {
    const [cssClasses, sc] = useWebexClasses('search-contacts-widget');
    const [inputValue, setInputValue] = useState<string>('');
    const { t } = useTranslation('WebexSearchContacts');
    const placeholder = label || t('mobile.dialNumber');

    const [clickRef, showDropdown] = useSearchDropdown<HTMLDivElement>(
      inputValue,
      minSearchLength!
    );
    const dropdownRef = useRef<HTMLDivElement>(null); 
    const backSpaceAction = () => {
      setInputValue((prev) => prev.slice(0, -1));
    }; 
    const callButtonPressAction = () => {
      setInputValue('');
    };
    const clearInput = () => {
      setInputValue('');
    };
    
    useImperativeHandle(ref, () => ({
      appendValueToInput(toAppend: string) {
        setInputValue((prev) => prev + toAppend);
      },
      backSpaceAction,
      getInputValue() {
        return inputValue;
      }, 
      callButtonPressAction,
      clearInput
    }));

    useEffect(() => {
      if (!showDropdown && onDropdownHide) {
        onDropdownHide();
      }
    }, [showDropdown, onDropdownHide]);

    useEffect(() => {
      if (onInputChange) {
        onInputChange(inputValue);
      }
    }, [inputValue, onInputChange]);

  

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        if (inputValue) {
          if(makeCall){
            makeCall(inputValue, false);
            setInputValue('')
          }
        }
      }
      if (event.key === 'Escape' || event.keyCode === 27) {
        setInputValue('')
      }
    };

    return (
      <div className={cssClasses} style={style} ref={clickRef}>
        <TextInput
          className={sc('input')}
          placeholder={placeholder}
          aria-label={placeholder}
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          autoComplete="off"
          onKeyDown={handleEnterKeyPress} 
        />
       
      </div>
    );
  }
);

WebexMobileSearchContacts.defaultProps = {
  label: undefined,
  noContactsFoundMessage: undefined,
  style: undefined,
  minSearchLength: 1,
  onInputChange: () => { },
  onUserSelect: () => { },
  onDropdownHide: () => { },
  hideDropdownSource: false,
};
