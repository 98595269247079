import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Flex, ListItemBase, ListNext, Text } from '@momentum-ui/react-collaboration';
import { ButtonPill, Link } from '@momentum-ui/react-collaboration/dist/esm/components';
import "./CallingWidgetModal.scss"
import { GenericModal } from '../../packages/widgets/src/components/GenericModal/GenericModal';
import callingDockGif from '../../../src/assets/callingDockGif.gif'; // Adjust the path as necessary
import {Logger, setLocalStorageItem } from "../../packages/logger/src";
import { FIRST_TIME_UX_FOR_CALLING_DOCK } from '../../packages/widgets/src/utils/MetricUtils';
import { useMsTeamsTheme } from "../../hooks/useMsTeamsTheme";
import { WEBEX_CALLING_THEME } from '../../packages/widgets/src/utils/ThemeUtils';

type ICallingWidgetModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    setShowCoachMarkForCallingDockFeature: React.Dispatch<React.SetStateAction<boolean>>;
    isCallingDockEnabled: boolean;
    enableCallingDockAction: () => void;
};

export const CallingWidgetModal = ({ isOpen, onCancel, setShowCoachMarkForCallingDockFeature, isCallingDockEnabled, enableCallingDockAction }: ICallingWidgetModalProps) => {
    const [theme] = useMsTeamsTheme();
    const { t } = useTranslation();
    const [isNextButtonPressed, setIsNextButtonPressed] = useState(false);
    const isDarkTheme = (theme === WEBEX_CALLING_THEME.DARK);

    const handleLaterAction = () => {
        Logger.info('should display coach mark for calling dock When "Later" is clicked');
        //TO DO COACH MARK IMPLEMENTATION NEEDS TO BE DONE
        setLocalStorageItem(FIRST_TIME_UX_FOR_CALLING_DOCK, true);
        setShowCoachMarkForCallingDockFeature(true);
        onCancel();
    }

    const handleDoneAction = () => { 
        Logger.info('Closing the calling dock modal popup for first time user experience when "done" is clicked');
        onCancel();
        setLocalStorageItem(FIRST_TIME_UX_FOR_CALLING_DOCK, true);
    }

    const handleEnableCallingDock = () => {
        Logger.info('Enabling the calling dock feature on the first-time user experience screen when "Try Now" is clicked');
        enableCallingDockAction && enableCallingDockAction();
        onCancel();
        setLocalStorageItem(FIRST_TIME_UX_FOR_CALLING_DOCK, true);
    }

    return (
        <GenericModal ariaLabel={ isNextButtonPressed ? `${t("callingDock.whatsNewLabel")}. ${t("callingDock.getMoreDoneLabel")}. ${t("callingDock.useCallingDockLabel")}` :  `${t("callingDock.whatsNewLabel")}. ${t("callingDock.ciscoFeaturesLabel")}. ${t("label.callHistory")}, ${t("label.voicemails")}, ${t("label.topbar.settings")}, ${t("label.callingWidget")}`} isRound isOpen={isOpen} data-padded="false" className='calling-widget-modal' >{
            isNextButtonPressed ? (<Flex direction='row' className={`calling-widget-modal__content ${isDarkTheme ? 'borderColorForDarkTheme' : ''}`}>
                <Flex direction='column' className='calling-widget-modal__left-section'>
                    <Flex direction='column' className='calling-widget-modal__left-section-top'>
                        <Text type='body-secondary' aria-label={t("callingDock.whatsNewLabel")}>{t("callingDock.whatsNewLabel")}</Text>
                        <Text type='title'aria-label={t("callingDock.getMoreDoneLabel")} >{t("callingDock.getMoreDoneLabel")}</Text>
                        <Text className='calling-widget-modal__description' aria-label={t("callingDock.useCallingDockLabel")} type='body-secondary'>{t("callingDock.useCallingDockLabel")}</Text>
                        {/* <Link target="_blank" aria-label={t("callingDock.learnMoreAboutLabel")} href="https://help.webex.com/en-us/article/ngmx08cb/Cisco-Call-for-Microsoft-Teams"><Text type='hyperlink-secondary'>{t("label.learnMore")}</Text></Link> */}
                        <a  
                            // tabIndex={0}
                            className={`calling-dock-learn-more-link 
                            ${isDarkTheme ? 'more-link-dark-theme-color' : 'more-link-light-theme-color'}`} 
                            target="_blank" 
                            aria-label={t("callingDock.learnMoreAboutLabel")} 
                            href="https://help.webex.com/en-us/article/ngmx08cb/Cisco-Call-for-Microsoft-Teams">{t("label.learnMore")}</a>
                    </Flex>
                    <Flex className='calling-widget-modal__left-section-bottom calling-widget-modal__left-section-bottom--modified'>
                        {!isCallingDockEnabled &&
                            <>
                                <ButtonPill size={32} onPress={handleEnableCallingDock}>{t("callingDock.useCallingDockButton")}</ButtonPill>
                                <ButtonPill ghost size={32} onPress={handleLaterAction} style={{fontWeight: '550'}}>{t("label.later")}</ButtonPill>
                            </>
                        }
                        {isCallingDockEnabled && 
                         <ButtonPill ghost inverted size={32} onPress={handleDoneAction}>{t("label.done")}</ButtonPill>
                        }
                    </Flex>
                </Flex>
                <Flex justifyContent='center' alignItems='center' className='calling-widget-modal__right-section calling-widget-modal__right-section--modified'>
                    <Flex justifyContent='center' alignItems='center' className='calling-widget-modal__right-section-webex-logo-container'>
                        <div style={{ position: 'absolute', top: '147px' }}>
                            <img aria-label={t("callingDock.animatedGifForRecieveCallLabel")} className='calling-widget-modal_gif' src={callingDockGif} alt="Calling dock gif" />
                        </div>
                    </Flex>
                </Flex>
            </Flex>) : (
                <Flex direction='row' className={`calling-widget-modal__content ${isDarkTheme ? 'borderColorForDarkTheme' : ''}`}>
                    <Flex direction='column' className='calling-widget-modal__left-section'>
                        <Flex direction='column' className='calling-widget-modal__left-section-top'>
                            <Text  type='body-secondary'>{t("callingDock.whatsNewLabel")}</Text>
                            <Text type='title'  style={{marginBottom: '0px'}}>{t("callingDock.ciscoFeaturesLabel")}</Text>
                            <ListNext listSize={4} className='calling-widget-modal__list'>
                                <ListItemBase className="calling-widget-modal__list-item"><Text type='body-secondary'><span>{'\u2022'}</span> {t("label.callHistory")}</Text></ListItemBase>
                                <ListItemBase className='calling-widget-modal__list-item'><Text type='body-secondary'>{'\u2022'} {t("label.voicemails")}</Text></ListItemBase>
                                <ListItemBase><Text type='body-secondary'>{'\u2022'} {t("label.topbar.settings")}</Text></ListItemBase>
                                <ListItemBase><Text type='body-secondary'>{'\u2022'} {t("label.callingWidget")}</Text></ListItemBase>
                            </ListNext>
                            {/* <Link tabIndex={0} target="_blank" aria-label={t("label.learnMoreForAriaLabel")} href="https://help.webex.com/en-us/article/ngmx08cb/Cisco-Call-for-Microsoft-Teams"><Text type='hyperlink-secondary'>{t("label.learnMore")}</Text></Link> */}
                                <a
                                    tabIndex={0}
                                    className={`calling-dock-learn-more-link ${isDarkTheme ? 'more-link-dark-theme-color' : 'more-link-light-theme-color'}`}
                                    target="_blank"
                                    aria-label={t("label.learnMoreForAriaLabel")}
                                    href="https://help.webex.com/en-us/article/ngmx08cb/Cisco-Call-for-Microsoft-Teams">{t("label.learnMore")}
                                </a>
                        </Flex>
                        <Flex className='calling-widget-modal__left-section-bottom'>
                            <ButtonPill size={32} onPress={() => setIsNextButtonPressed(true)}>{t("label.Next")}</ButtonPill>
                        </Flex>
                    </Flex>
                    <Flex justifyContent='center' alignItems='center' className='calling-widget-modal__right-section'>
                        <Flex justifyContent='center' alignItems='center' className='calling-widget-modal__right-section-webex-logo-container'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="215" height="215" viewBox="0 0 215 215" fill="none">
                                    <rect width="215" height="215" rx="44" fill="url(#paint0_linear_2882_9441)" />
                                    <g clipPath="url(#clip0_2882_9441)">
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint1_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint2_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint3_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint4_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint5_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint6_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint7_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint8_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint9_radial_2882_9441)" />
                                        <path d="M151.461 51.342C146.008 51.342 139.696 53.1719 135.519 61.0357C133.118 65.5558 131.902 70.6445 130.777 75.6944C130.407 77.3562 130.025 79.038 129.618 80.6328V103.481C131.682 102.903 133.898 101.791 136.025 99.6512C144.012 91.6144 145.352 70.5868 149.346 66.5674C149.799 66.1119 150.288 65.847 150.816 65.847C152.424 65.847 153.667 67.864 154.857 70.2898C156.18 72.9862 157.752 76.0625 162.39 76.0625C164.51 76.0625 169.402 74.5479 169.402 68.6099C169.402 65.0566 163.418 51.342 151.461 51.342Z" fill="url(#paint10_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint11_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint12_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint13_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint14_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint15_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="#316AFF" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint16_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint17_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint18_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint19_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint20_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint21_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint22_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint23_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint24_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint25_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint26_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint27_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint28_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint29_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint30_radial_2882_9441)" />
                                    </g>
                                    <path d="M137.069 149.28C135.107 153.866 126.784 161.813 126.784 161.813C124.084 164.261 120.82 165.854 117.275 166.476C116.586 166.603 115.886 166.709 115.187 166.777C114.089 166.913 112.973 166.991 111.875 167.02C111.409 167.03 110.933 167.04 110.467 167.03C107.417 166.981 104.377 166.583 101.395 165.854C92.7804 163.853 79.319 158.277 64.187 143.14C49.0551 127.994 43.4898 114.538 41.4794 105.901C40.343 101.384 40.0322 96.7494 40.5664 92.1151C40.6441 91.4059 40.7412 90.7064 40.8675 90.0166C41.4891 86.4803 43.0819 83.2256 45.5197 80.5345L52.0465 74.0058C52.6778 73.3743 54.6883 71.5672 55.4847 71.1592C55.7663 70.9649 56.0577 70.79 56.3685 70.6248C56.8639 70.3625 57.3883 70.1391 57.9225 69.9545C58.7869 69.663 59.6805 69.5076 60.6031 69.459C62.4291 69.3716 64.1482 69.8087 65.7993 70.654C67.4504 71.4992 69.49 73.0245 71.0634 74.6081C71.0634 74.6081 79.6686 83.0702 80.8438 84.3915C83.1165 86.9563 83.913 88.3359 84.2335 90.2304C84.7482 93.213 84.3209 95.9818 82.2619 98.1969C80.737 99.8388 74.5599 105.959 73.2973 107.514C72.0347 109.068 72.2192 112.032 72.9962 115.014C73.8897 118.463 75.7837 122.301 78.0855 125.021C86.0108 134.377 93.4409 135.047 96.1312 135.28C98.4525 135.475 100.084 133.58 100.939 132.793C102.036 131.783 107.874 126.09 108.689 125.41C112.04 122.602 115.886 121.98 119.519 124.312C123.151 126.643 134.418 137.826 135.904 139.759C138.303 142.887 138.303 146.395 137.069 149.27V149.28Z" fill="#E52B2B" />
                                    <path d="M137.069 149.28C135.107 153.866 126.784 161.813 126.784 161.813C124.084 164.261 120.82 165.854 117.275 166.476C116.586 166.603 115.886 166.709 115.187 166.777C114.089 166.913 112.973 166.991 111.875 167.02C111.409 167.03 110.933 167.04 110.467 167.03C107.417 166.981 104.377 166.583 101.395 165.854C92.7804 163.853 79.319 158.277 64.187 143.14C49.0551 127.994 43.4898 114.538 41.4794 105.901C40.343 101.384 40.0322 96.7494 40.5664 92.1151C40.6441 91.4059 40.7412 90.7064 40.8675 90.0166C41.4891 86.4803 43.0819 83.2256 45.5197 80.5345L52.0465 74.0058C52.6778 73.3743 54.6883 71.5672 55.4847 71.1592C55.7663 70.9649 56.0577 70.79 56.3685 70.6248C56.8639 70.3625 57.3883 70.1391 57.9225 69.9545C58.7869 69.663 59.6805 69.5076 60.6031 69.459C62.4291 69.3716 64.1482 69.8087 65.7993 70.654C67.4504 71.4992 69.49 73.0245 71.0634 74.6081C71.0634 74.6081 79.6686 83.0702 80.8438 84.3915C83.1165 86.9563 83.913 88.3359 84.2335 90.2304C84.7482 93.213 84.3209 95.9818 82.2619 98.1969C80.737 99.8388 74.5599 105.959 73.2973 107.514C72.0347 109.068 72.2192 112.032 72.9962 115.014C73.8897 118.463 75.7837 122.301 78.0855 125.021C86.0108 134.377 93.4409 135.047 96.1312 135.28C98.4525 135.475 100.084 133.58 100.939 132.793C102.036 131.783 107.874 126.09 108.689 125.41C112.04 122.602 115.886 121.98 119.519 124.312C123.151 126.643 134.418 137.826 135.904 139.759C138.303 142.887 138.303 146.395 137.069 149.27V149.28Z" fill="#242424" />
                                    <g clipPath="url(#clip1_2882_9441)">
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint31_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint32_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint33_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint34_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint35_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint36_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint37_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint38_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint39_radial_2882_9441)" />
                                        <path d="M151.461 51.342C146.008 51.342 139.696 53.1719 135.519 61.0357C133.118 65.5558 131.902 70.6445 130.777 75.6944C130.407 77.3562 130.025 79.038 129.618 80.6328V103.481C131.682 102.903 133.898 101.791 136.025 99.6512C144.012 91.6144 145.352 70.5868 149.346 66.5674C149.799 66.1119 150.288 65.847 150.816 65.847C152.424 65.847 153.667 67.864 154.857 70.2898C156.18 72.9862 157.752 76.0625 162.39 76.0625C164.51 76.0625 169.402 74.5479 169.402 68.6099C169.402 65.0566 163.418 51.342 151.461 51.342Z" fill="url(#paint40_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint41_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint42_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint43_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint44_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint45_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="#316AFF" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint46_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint47_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint48_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint49_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint50_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint51_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint52_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint53_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint54_radial_2882_9441)" />
                                        <path d="M167.197 59.4344C166.658 59.4344 166.116 59.4848 165.584 59.5885C168.112 63.1966 169.377 66.9765 169.377 68.6097C169.377 74.5478 164.484 76.0625 162.364 76.0625C160.709 76.0625 159.445 75.6706 158.443 75.0444C158.434 75.0814 158.425 75.1184 158.415 75.1555C158.117 76.334 157.882 77.6239 157.475 78.879C156.692 81.2925 155.636 84.0493 154.509 86.1825C153.293 88.4823 152.09 89.8279 150.656 89.7572C149.024 89.677 147.702 87.5956 146.058 81.7129C145.528 79.8162 145.037 77.7366 144.582 75.6946C143.454 70.6298 142.069 65.5366 139.701 61.0359C135.833 53.6852 129.235 51.3423 123.968 51.3423C119.083 51.3423 115.433 53.632 112.566 56.5168C111.774 57.3141 110.908 58.3881 110.039 59.6504C112.54 60.2468 114.904 62.1102 115.282 65.6419C115.356 66.3152 115.878 71.6634 117.631 77.861C118.134 76.3071 118.868 74.0061 120.572 70.2901C121.407 68.5883 122.161 67.4661 122.841 66.7819C123.464 66.1544 124.026 65.8953 124.532 65.8953C125.077 65.8953 126.939 66.3894 128.752 72.687C131 80.496 132.673 92.924 139.047 99.5227C141.859 102.434 145.793 104.317 151.246 104.317C156.03 104.317 160.019 102.231 162.863 99.3287C167.54 94.5569 170.102 86.6676 171.016 83.7482C173.617 75.4426 174.346 67.9308 174.43 67.136C174.914 62.5946 171.05 59.4344 167.197 59.4344Z" fill="url(#paint55_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint56_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint57_radial_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint58_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint59_linear_2882_9441)" />
                                        <path d="M123.766 104.107C110.154 104.107 105.521 87.0635 104.483 83.748C101.875 75.4224 101.083 67.9229 100.999 67.1358C100.515 62.5944 104.194 59.4342 108.232 59.4342C111.289 59.4342 114.838 61.2461 115.308 65.6421C115.389 66.3815 116.011 72.7584 118.21 79.7068C120.505 86.9585 122.939 89.7474 124.692 89.7474C127.523 89.7474 129.25 82.5488 130.777 75.6944C131.902 70.6445 133.119 65.5558 135.519 61.0357C139.697 53.1719 146.009 51.342 151.461 51.342C163.418 51.342 169.403 65.0566 169.403 68.6099C169.403 74.5479 164.51 76.0625 162.39 76.0625C157.752 76.0625 156.18 72.9862 154.857 70.2898C153.667 67.864 152.424 65.847 150.817 65.847C150.289 65.847 149.799 66.1119 149.347 66.5674C145.352 70.5868 144.012 91.6144 136.025 99.6512C131.597 104.107 126.779 104.107 123.766 104.107Z" fill="url(#paint60_radial_2882_9441)" />
                                    </g>
                                    <path d="M137.069 149.28C135.107 153.866 126.784 161.813 126.784 161.813C124.084 164.261 120.82 165.854 117.275 166.476C116.586 166.603 115.886 166.709 115.187 166.777C114.089 166.913 112.973 166.991 111.875 167.02C111.409 167.03 110.933 167.04 110.467 167.03C107.417 166.981 104.377 166.583 101.395 165.854C92.7804 163.853 79.319 158.277 64.187 143.14C49.0551 127.994 43.4898 114.538 41.4794 105.901C40.343 101.384 40.0322 96.7494 40.5664 92.1151C40.6441 91.4059 40.7412 90.7064 40.8675 90.0166C41.4891 86.4803 43.0819 83.2256 45.5197 80.5345L52.0465 74.0058C52.6778 73.3743 54.6883 71.5672 55.4847 71.1592C55.7663 70.9649 56.0577 70.79 56.3685 70.6248C56.8639 70.3625 57.3883 70.1391 57.9225 69.9545C58.7869 69.663 59.6805 69.5076 60.6031 69.459C62.4291 69.3716 64.1482 69.8087 65.7993 70.654C67.4504 71.4992 69.49 73.0245 71.0634 74.6081C71.0634 74.6081 79.6686 83.0702 80.8438 84.3915C83.1165 86.9563 83.913 88.3359 84.2335 90.2304C84.7482 93.213 84.3209 95.9818 82.2619 98.1969C80.737 99.8388 74.5599 105.959 73.2973 107.514C72.0347 109.068 72.2192 112.032 72.9962 115.014C73.8897 118.463 75.7837 122.301 78.0855 125.021C86.0108 134.377 93.4409 135.047 96.1312 135.28C98.4525 135.475 100.084 133.58 100.939 132.793C102.036 131.783 107.874 126.09 108.689 125.41C112.04 122.602 115.886 121.98 119.519 124.312C123.151 126.643 134.418 137.826 135.904 139.759C138.303 142.887 138.303 146.395 137.069 149.27V149.28Z" fill="#E52B2B" />
                                    <path d="M137.069 149.28C135.107 153.866 126.784 161.813 126.784 161.813C124.084 164.261 120.82 165.854 117.275 166.476C116.586 166.603 115.886 166.709 115.187 166.777C114.089 166.913 112.973 166.991 111.875 167.02C111.409 167.03 110.933 167.04 110.467 167.03C107.417 166.981 104.377 166.583 101.395 165.854C92.7804 163.853 79.319 158.277 64.187 143.14C49.0551 127.994 43.4898 114.538 41.4794 105.901C40.343 101.384 40.0322 96.7494 40.5664 92.1151C40.6441 91.4059 40.7412 90.7064 40.8675 90.0166C41.4891 86.4803 43.0819 83.2256 45.5197 80.5345L52.0465 74.0058C52.6778 73.3743 54.6883 71.5672 55.4847 71.1592C55.7663 70.9649 56.0577 70.79 56.3685 70.6248C56.8639 70.3625 57.3883 70.1391 57.9225 69.9545C58.7869 69.663 59.6805 69.5076 60.6031 69.459C62.4291 69.3716 64.1482 69.8087 65.7993 70.654C67.4504 71.4992 69.49 73.0245 71.0634 74.6081C71.0634 74.6081 79.6686 83.0702 80.8438 84.3915C83.1165 86.9563 83.913 88.3359 84.2335 90.2304C84.7482 93.213 84.3209 95.9818 82.2619 98.1969C80.737 99.8388 74.5599 105.959 73.2973 107.514C72.0347 109.068 72.2192 112.032 72.9962 115.014C73.8897 118.463 75.7837 122.301 78.0855 125.021C86.0108 134.377 93.4409 135.047 96.1312 135.28C98.4525 135.475 100.084 133.58 100.939 132.793C102.036 131.783 107.874 126.09 108.689 125.41C112.04 122.602 115.886 121.98 119.519 124.312C123.151 126.643 134.418 137.826 135.904 139.759C138.303 142.887 138.303 146.395 137.069 149.27V149.28Z" fill="#242424" />
                                    <defs>
                                        <linearGradient id="paint0_linear_2882_9441" x1="107.5" y1="0" x2="107.5" y2="215" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="#ECECEC" />
                                            <stop offset="1" stopColor="#020202" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_2882_9441" x1="161.954" y1="66.8231" x2="113.961" y2="82.7218" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#5CEE64" />
                                            <stop offset="1" stopColor="#00BBFF" />
                                        </linearGradient>
                                        <radialGradient id="paint2_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(128.938 79.4803) rotate(-121.115) scale(31.2703 26.3756)">
                                            <stop stopColor="#007383" />
                                            <stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75" />
                                            <stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint3_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(149.636 73.6773) rotate(-127.011) scale(25.5266 16.8196)">
                                            <stop stopColor="#218970" />
                                            <stop offset="0.459527" stopColor="#267E6A" />
                                            <stop offset="1" stopColor="#026C51" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint4_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.124 80.9537) rotate(-107.634) scale(20.7337 10.891)">
                                            <stop stopColor="#178697" />
                                            <stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79" />
                                            <stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint5_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.427 80.8433) rotate(-121.115) scale(52.7451 16.3472)">
                                            <stop stopColor="#25342F" stopOpacity="0.3" />
                                            <stop offset="1" stopColor="#25342F" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint6_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(145.905 74.2541) rotate(85.0485) scale(19.8139 11.3502)">
                                            <stop stopColor="#056D4F" />
                                            <stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75" />
                                            <stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824" />
                                            <stop offset="0.874482" stopColor="#056C4F" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint7_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(146.79 42.2122) rotate(56.8137) scale(23.3597 12.3022)">
                                            <stop stopColor="#22B67B" />
                                            <stop offset="0.407867" stopColor="#24BE82" />
                                            <stop offset="1" stopColor="#24BE82" stopOpacity="0.07" />
                                        </radialGradient>
                                        <linearGradient id="paint8_linear_2882_9441" x1="82.8681" y1="77.264" x2="116.947" y2="77.547" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#09F8FF" />
                                            <stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
                                        </linearGradient>
                                        <radialGradient id="paint9_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(90.1892 46.8766) rotate(49.3657) scale(30.5472 30.519)">
                                            <stop stopColor="#09F8FF" />
                                            <stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint10_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(114.801 31.5621) rotate(47.7248) scale(61.9735 61.9382)">
                                            <stop stopColor="#00B8FF" />
                                            <stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint11_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118.101 77.8348) rotate(-107.634) scale(29.7642 20.7938)">
                                            <stop stopColor="#00BCFF" stopOpacity="0.76" />
                                            <stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint12_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(163.767 66.5406) rotate(-129.834) scale(21.1147 14.8098)">
                                            <stop stopColor="#5FEC50" />
                                            <stop offset="1" stopColor="#3CC08E" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint13_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(160.726 80.0848) rotate(-78.9867) scale(6.36855 4.44611)">
                                            <stop stopColor="#28855B" />
                                            <stop offset="1" stopColor="#14795C" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint14_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(160.416 79.6161) rotate(-77.7608) scale(5.25056 7.40547)">
                                            <stop stopColor="#28855B" />
                                            <stop offset="1" stopColor="#14795C" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint15_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(166.732 70.1613) rotate(-133.078) scale(17.4911 12.2966)">
                                            <stop stopColor="#5FF05B" />
                                            <stop offset="0.813285" stopColor="#5DE955" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint16_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.231 75.1621) rotate(37.1778) scale(15.5818 21.6007)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.966119" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint17_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(157.34 76.9073) rotate(8.40231) scale(18.4492 27.2742)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint18_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(158.838 79.1734) rotate(24.4451) scale(15.7356 27.8551)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.756788" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint19_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(156.987 80.098) rotate(95.8611) scale(28.0559 15.5272)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
                                            <stop offset="1" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint20_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.231 67.7128) rotate(93.7042) scale(33.7622 12.1699)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint21_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(122.583 74.7324) rotate(84.7776) scale(17.9165 9.58645)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint22_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(122.571 75.2143) rotate(87.2226) scale(18.6975 15.592)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint23_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(126.868 92.7828) rotate(-22.9645) scale(21.0291 40.8189)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint24_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(119.374 70.643) rotate(-159.715) scale(13.9366 23.682)">
                                            <stop stopColor="#1A3DA0" />
                                            <stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint25_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(107.091 75.3445) rotate(16.5905) scale(26.5915 26.6191)">
                                            <stop stopColor="#1E3882" />
                                            <stop offset="0.761404" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <linearGradient id="paint26_linear_2882_9441" x1="98.7428" y1="60.7714" x2="124.814" y2="77.5801" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.229329" stopColor="#1DC4FF" />
                                            <stop offset="1" stopColor="#1CC1FF" stopOpacity="0" />
                                        </linearGradient>
                                        <radialGradient id="paint27_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(90.1876 46.8754) rotate(49.3657) scale(30.5472 30.519)">
                                            <stop stopColor="#09F8FF" />
                                            <stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
                                        </radialGradient>
                                        <linearGradient id="paint28_linear_2882_9441" x1="100.192" y1="61.6179" x2="107.482" y2="68.2929" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#26FBFF" />
                                            <stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint29_linear_2882_9441" x1="98.2381" y1="66.671" x2="108.412" y2="67.4042" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#26FBFF" />
                                            <stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
                                        </linearGradient>
                                        <radialGradient id="paint30_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118.1 77.8351) rotate(-107.634) scale(29.7642 20.7938)">
                                            <stop stopColor="#00BCFF" stopOpacity="0.76" />
                                            <stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
                                        </radialGradient>
                                        <linearGradient id="paint31_linear_2882_9441" x1="161.954" y1="66.8231" x2="113.961" y2="82.7218" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#5CEE64" />
                                            <stop offset="1" stopColor="#00BBFF" />
                                        </linearGradient>
                                        <radialGradient id="paint32_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(128.938 79.4803) rotate(-121.115) scale(31.2703 26.3756)">
                                            <stop stopColor="#007383" />
                                            <stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75" />
                                            <stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint33_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(149.636 73.6773) rotate(-127.011) scale(25.5266 16.8196)">
                                            <stop stopColor="#218970" />
                                            <stop offset="0.459527" stopColor="#267E6A" />
                                            <stop offset="1" stopColor="#026C51" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint34_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.124 80.9537) rotate(-107.634) scale(20.7337 10.891)">
                                            <stop stopColor="#178697" />
                                            <stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79" />
                                            <stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint35_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.427 80.8433) rotate(-121.115) scale(52.7451 16.3472)">
                                            <stop stopColor="#25342F" stopOpacity="0.3" />
                                            <stop offset="1" stopColor="#25342F" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint36_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(145.905 74.2541) rotate(85.0485) scale(19.8139 11.3502)">
                                            <stop stopColor="#056D4F" />
                                            <stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75" />
                                            <stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824" />
                                            <stop offset="0.874482" stopColor="#056C4F" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint37_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(146.79 42.2122) rotate(56.8137) scale(23.3597 12.3022)">
                                            <stop stopColor="#22B67B" />
                                            <stop offset="0.407867" stopColor="#24BE82" />
                                            <stop offset="1" stopColor="#24BE82" stopOpacity="0.07" />
                                        </radialGradient>
                                        <linearGradient id="paint38_linear_2882_9441" x1="82.8681" y1="77.264" x2="116.947" y2="77.547" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#09F8FF" />
                                            <stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
                                        </linearGradient>
                                        <radialGradient id="paint39_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(90.1892 46.8766) rotate(49.3657) scale(30.5472 30.519)">
                                            <stop stopColor="#09F8FF" />
                                            <stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint40_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(114.801 31.5621) rotate(47.7248) scale(61.9735 61.9382)">
                                            <stop stopColor="#00B8FF" />
                                            <stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint41_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118.101 77.8348) rotate(-107.634) scale(29.7642 20.7938)">
                                            <stop stopColor="#00BCFF" stopOpacity="0.76" />
                                            <stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint42_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(163.767 66.5406) rotate(-129.834) scale(21.1147 14.8098)">
                                            <stop stopColor="#5FEC50" />
                                            <stop offset="1" stopColor="#3CC08E" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint43_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(160.726 80.0848) rotate(-78.9867) scale(6.36855 4.44611)">
                                            <stop stopColor="#28855B" />
                                            <stop offset="1" stopColor="#14795C" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint44_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(160.416 79.6161) rotate(-77.7608) scale(5.25056 7.40547)">
                                            <stop stopColor="#28855B" />
                                            <stop offset="1" stopColor="#14795C" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint45_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(166.732 70.1613) rotate(-133.078) scale(17.4911 12.2966)">
                                            <stop stopColor="#5FF05B" />
                                            <stop offset="0.813285" stopColor="#5DE955" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint46_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.231 75.1621) rotate(37.1778) scale(15.5818 21.6007)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.966119" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint47_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(157.34 76.9073) rotate(8.40231) scale(18.4492 27.2742)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint48_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(158.838 79.1734) rotate(24.4451) scale(15.7356 27.8551)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.756788" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint49_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(156.987 80.098) rotate(95.8611) scale(28.0559 15.5272)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
                                            <stop offset="1" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint50_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(161.231 67.7128) rotate(93.7042) scale(33.7622 12.1699)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint51_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(122.583 74.7324) rotate(84.7776) scale(17.9165 9.58645)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint52_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(122.571 75.2143) rotate(87.2226) scale(18.6975 15.592)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint53_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(126.868 92.7828) rotate(-22.9645) scale(21.0291 40.8189)">
                                            <stop stopColor="#123AA8" />
                                            <stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint54_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(119.374 70.643) rotate(-159.715) scale(13.9366 23.682)">
                                            <stop stopColor="#1A3DA0" />
                                            <stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <radialGradient id="paint55_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(107.091 75.3445) rotate(16.5905) scale(26.5915 26.6191)">
                                            <stop stopColor="#1E3882" />
                                            <stop offset="0.761404" stopColor="#123AA8" stopOpacity="0" />
                                        </radialGradient>
                                        <linearGradient id="paint56_linear_2882_9441" x1="98.7428" y1="60.7714" x2="124.814" y2="77.5801" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.229329" stopColor="#1DC4FF" />
                                            <stop offset="1" stopColor="#1CC1FF" stopOpacity="0" />
                                        </linearGradient>
                                        <radialGradient id="paint57_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(90.1876 46.8754) rotate(49.3657) scale(30.5472 30.519)">
                                            <stop stopColor="#09F8FF" />
                                            <stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
                                        </radialGradient>
                                        <linearGradient id="paint58_linear_2882_9441" x1="100.192" y1="61.6179" x2="107.482" y2="68.2929" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#26FBFF" />
                                            <stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint59_linear_2882_9441" x1="98.2381" y1="66.671" x2="108.412" y2="67.4042" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#26FBFF" />
                                            <stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
                                        </linearGradient>
                                        <radialGradient id="paint60_radial_2882_9441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(118.1 77.8351) rotate(-107.634) scale(29.7642 20.7938)">
                                            <stop stopColor="#00BCFF" stopOpacity="0.76" />
                                            <stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
                                        </radialGradient>
                                        <clipPath id="clip0_2882_9441">
                                            <rect width="73.5155" height="52.9744" fill="white" transform="translate(100.957 51.342)" />
                                        </clipPath>
                                        <clipPath id="clip1_2882_9441">
                                            <rect width="73.5155" height="52.9744" fill="white" transform="translate(100.957 51.342)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                        </Flex>
                    </Flex>
                </Flex>)
        }

        </GenericModal >
    );
};