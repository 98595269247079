import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from "../../../../packages/logger/src";
import { Logger, formatError } from "../../../../packages/logger/src/Logger";
import {
  IVoiceMailDeleteResponse,
  IVoiceMailReadResponse,
  IVoiceMessageContentResponse,
  IWebexVoicemail,
  RESPONSE_STATUS,
  VM_STORAGE_KEYS,
  VoicemailTranscriptFetchStatus
} from "../../../adapter-interfaces/src";
import { serverSideErrorWhileFetchingVMContentMetric, voicemail, voicemailDeletedMetric, voicemailPlayedFailureMETRIC, voicemailPlayedSuccessMETRIC, voicemailServerSideErrorResponseStatus, voicemailServerSideErrorResponseStatusCode } from "../../src/utils/MetricUtils";
import { AdapterContext } from "../contexts/AdapterContext";
import { submitMetrics } from "../services/WebexMetricsService";
import { CALLING_BACKEND, getCallingBackEnd } from "../utils/callingUtils";
import { CustomError } from '../utils/CustomError';


/**
 * Custom hook that returns voicemail data for a given user.
 *
 * @returns {object} Returns {callHistory, lastUpdated} values
 */
export default function useVoicemails() {
  const [voicemails, setVoicemails] = useState<IWebexVoicemail[]>([]);
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(true);
  const [voicemailId, setVoicemailId] = useState<string>("");
  const [voicemailSrc, setVoicemailSrc] = useState<string>("");
  const [isShowVmPlayError, setIsShowVmPlayError] = useState<boolean>(false);
  const [isShowVmConfigPage, setIsShowVmConfigPage] = useState<boolean>(false)
  const [voicemailTranscript, setVoicemailTranscript] = useState<string>("");
  const [vmTranscriptloading, setvmTranscriptloading] = useState<boolean>(false);
  const [isWebexCallingBackend, setisWebexCallingBackend] = useState<boolean>(false);
  const { t } = useTranslation('WebexVoicemail');
  const ctx = useContext(AdapterContext);

  const onError = (error: unknown) => {
    setLoading(false);
    const customError = error as CustomError;
    switch (customError?.statusCode) {
      case RESPONSE_STATUS.STATUSCODE_400:
        if (window?.isWebexInitalized && getCallingBackEnd() === CALLING_BACKEND.WXC) {
          setIsShowVmConfigPage(true)
        }
        break;
      case RESPONSE_STATUS.STATUSCODE_404:
        if (window?.isWebexInitalized && getCallingBackEnd() === CALLING_BACKEND.UCM) {
          setIsShowVmConfigPage(true)
        }
        break;
      default:
        setLoading(false);
        Logger.info(
          `WebexVoicemailHook.getVoiceMailList(): Unhandled status code: ${customError?.statusCode} - ${customError?.message}`
        );
        break;
    }
    Logger.error(
      `Could not find voice mail data: '${formatError(customError.message)}'.`
    );
  };

  const onUpdate = useCallback((data: IWebexVoicemail[]) => {
    removeLocalStorageItem(VM_STORAGE_KEYS.VM_CACHED_DATA);
    setLastUpdated(new Date(Date.now()));
    setVoicemails(data);
    setIsShowVmConfigPage(false)
    setLoading(false);
    if (data.length > 0) {
      setLocalStorageItem(VM_STORAGE_KEYS.VM_CACHED_DATA, data);
    }
  }, []);

  useEffect(() => {
    const data = getLocalStorageItem<any>(VM_STORAGE_KEYS.VM_CACHED_DATA);
    if (data !== null) {
      setLoading(false);
      setVoicemails(data);
    }
  }, []);


  const refresh = useCallback(async () => {
    if (ctx?.voicemailAdapter?.refresh) {
      console.log("refreshing function");
      return ctx?.voicemailAdapter?.refresh();
    }
    return Promise.resolve();
  }, [ctx?.voicemailAdapter]);

  const deleteVoicemail = useCallback(
    (voicemailId: string) => {
      const subscription = ctx.voicemailAdapter
        ?.deleteVoicemail(voicemailId)
        .subscribe((res: IVoiceMailDeleteResponse) => {
          if (res.message === RESPONSE_STATUS.SUCCESS) {
            setVoicemails((prevVoicemails) =>
              prevVoicemails.filter((voicemail) => voicemail.id !== voicemailId)
            );
            submitMetrics(
              {
                metricEvent: voicemailDeletedMetric,
                client_timestamp: voicemail,
                trackingId: voicemail,
                key: voicemail,
              }
            );
            refresh();
          }
        });

      return () => {
        subscription?.unsubscribe();
      };
    },
    [ctx?.voicemailAdapter]
  );

  const readVoicemail = useCallback(
    (voicemailId: string, unread: boolean) => {
      setIsShowVmPlayError(false);
      setVoicemailSrc("");
      setLocalStorageItem(VM_STORAGE_KEYS.VOICEMAIL_ID_MAP, voicemailId);
      setVoicemailId(
        getLocalStorageItem<string>(VM_STORAGE_KEYS.VOICEMAIL_ID_MAP)
      );
      const subscription = ctx?.voicemailAdapter
        ?.getVoiceMessage(voicemailId)
        .subscribe((res: IVoiceMessageContentResponse) => {
          if (res.message === RESPONSE_STATUS.SUCCESS) {
            submitMetrics(
              {
                metricEvent: voicemailPlayedSuccessMETRIC
              }
            );
            const cacheVmId = getLocalStorageItem<string>(
              VM_STORAGE_KEYS.VOICEMAIL_ID_MAP
            );
            const resVmId = res?.voiceMessageId;
            if (cacheVmId === resVmId) {
              const base64String = "data:audio/wav;base64,";
              const audioSrc = `${base64String}${res?.data?.voicemailContent?.content}`;
              setVoicemailSrc(audioSrc);
              if (unread) {
                ctx?.voicemailAdapter
                  ?.markVoicemailRead(resVmId)
                  .subscribe((res: IVoiceMailReadResponse) => {
                    if (res.message === RESPONSE_STATUS.SUCCESS) {
                      setVoicemails((prevVoicemails) =>
                        prevVoicemails.map((voicemail) => {
                          if (voicemail.id === resVmId) {
                            // eslint-disable-next-line no-param-reassign
                            voicemail.unread = false;
                          }
                          return voicemail;
                        })
                      );
                      refresh();
                    }
                  });
              }
            }
          }
          if (res.message === RESPONSE_STATUS.FAILURE) {
            submitMetrics(
              {
                metricEvent: voicemailPlayedFailureMETRIC
              }
            );
            const cacheVmId = getLocalStorageItem<string>(
              VM_STORAGE_KEYS.VOICEMAIL_ID_MAP
            );
            const resVmId = res?.voiceMessageId;
            if (cacheVmId === resVmId) {
              submitMetrics(
                {
                  metricEvent: serverSideErrorWhileFetchingVMContentMetric,
                  messageId: `${resVmId}`,
                  responseStatus: voicemailServerSideErrorResponseStatus,
                  voiceMailContentErrorResponseCode: voicemailServerSideErrorResponseStatusCode,
                }
              );
              setIsShowVmPlayError(true);
            }
          }
        });

      return () => {
        subscription?.unsubscribe();
      };
    },
    [ctx?.voicemailAdapter?.getVoiceMessage, setVoicemailId, setVoicemailSrc]
  );

  useEffect(() => {
    const subscription = ctx?.voicemailAdapter
      ?.getAll()
      .subscribe({ next: onUpdate, error: onError });

    return () => {
      subscription?.unsubscribe();
    };
  }, [ctx?.voicemailAdapter, onUpdate]);

  const onWebexCallingBackendUpdate = useCallback((data:boolean) => {
    setisWebexCallingBackend(data);
  }, []);

  useEffect(() => {
    const webexCallingBackendSubscription = ctx?.voicemailAdapter?.getWebexCallingBackend().subscribe({ next: onWebexCallingBackendUpdate });
    return () => {
      if (webexCallingBackendSubscription) {
        webexCallingBackendSubscription.unsubscribe();
      }
    };
  }, [ctx?.voicemailAdapter, onWebexCallingBackendUpdate]);

  const getVMTranscript = useCallback((voicemailId: string) => {
    setvmTranscriptloading(true);
    setVoicemailTranscript("");
    const subscription = ctx?.voicemailAdapter
      ?.getVMTranscript(voicemailId)
      .subscribe((res) => {
        Logger.info(`Getting the VM Transcription", ${res.message}`);
        switch(res.message) {
          case VoicemailTranscriptFetchStatus.READY:
            setvmTranscriptloading(false);
            setVoicemailTranscript(res.data.voicemailTranscript);
            break;
          case VoicemailTranscriptFetchStatus.FAILURE:
            setvmTranscriptloading(false);
            setVoicemailTranscript(`${t("vmTranscription.failure")}`);
            break;
          case VoicemailTranscriptFetchStatus.PENDING:
            setvmTranscriptloading(false);
            setVoicemailTranscript(`${t("vmTranscription.pending")}`);
            break;
          case VoicemailTranscriptFetchStatus.ERROR:
            setvmTranscriptloading(false);
            setVoicemailTranscript(`${t("vmTranscription.error")}`);
            break;
          case VoicemailTranscriptFetchStatus.NA:
            setvmTranscriptloading(false);
            setVoicemailTranscript(`${t("vmTranscription.na")}`);
            break;
          default:
            Logger.error(`Error fetching transcript: ${res.message}`);
        }
      });

    return () => {
      subscription?.unsubscribe();
    };
  }, [ctx?.voicemailAdapter]);

  return {
    lastUpdated,
    voicemails,
    refresh,
    loading,
    deleteVoicemail,
    readVoicemail,
    voicemailSrc,
    voicemailId,
    isShowVmPlayError,
    isShowVmConfigPage,
    voicemailTranscript,
    getVMTranscript,
    vmTranscriptloading,
    isWebexCallingBackend,
  } as const;
}
