export function getMultiLineLabel(lineNumber: number | null | undefined): string {
  // Check if lineNumber is null or undefined
  if (lineNumber === null || lineNumber === undefined || isNaN(lineNumber)) {
    return ''; // Return empty string
  }

  // Check if lineNumber is within the expected range (1 to 50)
  if (lineNumber >= 1 && lineNumber <= 50) {
    return `L${lineNumber}`; // Return L1, L2, L3, ..., L50
  }

  // If lineNumber is outside the range, return an empty string (optional)
  return '';
}

/**
 * @function getmultLineStyle
 * @description Extracts the last number from the line string using a regular expression (\d+)$.
 * Calculates the last digit of the number using parseInt and % 10. The last digit is used in
 * a switch statement to assign a specific color.
 * 
 * @param {string} lineNumber - The line string from which the last number is extracted.
 * @returns {object} - Style object with the assigned color based on the last digit.
 */

export const getmultLineStyle = (lineNumber: string): React.CSSProperties => {
  const style: React.CSSProperties = {};

  // Regex to extract the last digit from the line (e.g., L1, L11, L21, etc.)
  const match = lineNumber.match(/(\d+)$/);
  const lastDigit = match ? parseInt(match[1], 10) % 10 : null;

  if (lastDigit !== null) {
    switch (lastDigit) {
      case 1:
        style.color = "var(--mds-color-theme-text-team-mint-normal)";
        break;
      case 2:
        style.color = "var(--mds-color-theme-text-team-cobalt-normal)";
        break;
      case 3:
        style.color = "var(--mds-color-theme-text-team-orange-normal)";
        break;
      case 4:
        style.color = "var(--mds-color-theme-text-team-pink-normal)";
        break;
      case 5:
        style.color = "var(--mds-color-theme-text-team-lime-normal)";
        break;
      case 6:
        style.color = "var(--mds-color-theme-text-team-cyan-normal)";
        break;
      case 7:
        style.color = "var(--mds-color-theme-text-team-purple-normal)";
        break;
      case 8:
        style.color = "var(--mds-color-theme-text-team-violet-normal)";
        break;
      case 9:
        style.color = "var(--mds-color-theme-text-team-gold-normal)";
        break;
      case 0:
        style.color = "var(--mds-color-theme-text-team-slate-normal)";
        break;
      default:
        return style;
    }
  }

  return style;
};

/**
 * lineNumber.startsWith('L'): This checks if the string starts with 'L'.
 * !isNaN(Number(lineNumber.substring(1))): This ensures that the part after 'L' is a valid number.
 * lineNumber.substring(1): If the conditions are met, it extracts the portion of the string after 'L' and returns it.
 */
export const extractLineNumber = (lineNumber: string): string => {
  // Check if the lineNumber starts with 'L' and the rest is a valid number
  if (lineNumber.startsWith('L') && !isNaN(Number(lineNumber.substring(1)))) {
    // Return the number part after 'L'
    return lineNumber.substring(1);
  }

  // Return an empty string if the format is not as expected
  return '';
};