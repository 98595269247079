import { getLocalStorageItem } from "../../../logger/src/LocalStorage";
import { v4 as uuid } from 'uuid';
import { formatError, Logger } from "../../../logger/src/Logger";
import {
    SubscriptionEventResponse,
    DATA_PIPE_LINE_EVENTS,
    IntegrationErrorResponse,
    IntegrationPhoneServicesResponse
} from '../../../adapter-interfaces/src';
import { EventType, eventEmitter } from "../utils/eventEmitter";

let makeIntegrationSubscriptionRegister = true;
let makeIntegrationErrorRegister = true;
let makeIntegrationPhoneServicesRegister = true;


// Async function to initialize the data pipeline subscription
async function initDataPipelineSubscription() {
    try {
        const webex_crossLaunchToken = getLocalStorageItem<string>("webex_crossLaunchToken");

        // Proceed only if the token is available
        if (webex_crossLaunchToken) {
            Logger.info(`WebexPhoneService.initDataPipelineSubscription(): data pipeline cross launching`);
            // Generate UUIDs for subscriptionId and requestId
            const subscriptionId = uuid();
            const requestId = uuid();
            // Retrieve the device ID using window.webex.internal.device.getId()
            const deviceUrl = window.webex.internal.device.getId();
            Logger.info(`WebexPhoneService.initDataPipelineSubscription() subscriptionId: ${subscriptionId}`);
            Logger.info(`WebexPhoneService.initDataPipelineSubscription() requestId: ${requestId}`);
            Logger.info(`WebexPhoneService.initDataPipelineSubscription() deviceUrl: ${deviceUrl}`);

            // Construct the Webex cross-launch URL with the generated values
            const webexUrl = `webex://integration?cmd=subscribe&events=phoneservices,settings,e911&subscriptionId=${subscriptionId}&requestId=${requestId}&deviceUrl=${deviceUrl}&source=MSTeams&jws=${webex_crossLaunchToken}`;
            //const webexUrl = `webex://integration?cmd=getphoneservices&requestId=${requestId}&deviceUrl=${deviceUrl}&source=MSTeams&jws=${webex_crossLaunchToken}`
            
            //const webexUrl = `webex://integration?cmd=getdata&requestId=${requestId}&deviceUrl=${deviceUrl}&source=MSTeams&jws=${webex_crossLaunchToken}`
            console.log('webexUrl ', webexUrl)

            console.log('phone service log ', `webex://integration?cmd=getphoneservices&requestId=${requestId}&deviceUrl=${deviceUrl}&source=MSTeams&jws=${webex_crossLaunchToken}`)

            const callUrl = new URL(webexUrl);

            window.open(callUrl.href);
        }
    } catch (error) {
        Logger.error(
            `WebexPhoneService.initDataPipelineSubscription(): Error initializing data pipeline subscription:: '${formatError(
                error
            )}'.`
        );
        throw error;
    }
}


export async function getDataPipelineSubscription(): Promise<any> {
    if (window?.isWebexInitalized) {
        return initDataPipelineSubscription();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getDataPipelineSubscription();
    }
}

async function listenSubscriptionStatus(): Promise<SubscriptionEventResponse> {
    return new Promise<SubscriptionEventResponse>((resolve) => {
        if (makeIntegrationSubscriptionRegister) {
            const eventHandler = (sessionData: SubscriptionEventResponse) => {
                Logger.info(
                    `User subscribed to the event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_SUBSCRIPTION,
                eventHandler
            );

            // window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_PHONE_SERVICES, (event: any) => {
            //     console.log('Phone services event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

            //   window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_SETTINGS, (event: any) => {
            //     console.log('Phone settings event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

            //   window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_E911, (event: any) => {
            //     console.log('E911 event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

            //   window.webex.internal.mercury.on(DATA_PIPE_LINE_EVENTS.INTEGRATION_ERROR, (event: any) => {
            //     console.log('Error event from webex', event)
            //     eventEmitter.dispatch(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, "integrationSubscription");
            //   });

              makeIntegrationSubscriptionRegister = false;
        }
    });
}

export async function getSubscriptionStatus(): Promise<SubscriptionEventResponse> {
    if (window?.isWebexInitalized) {
        return listenSubscriptionStatus();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getSubscriptionStatus();
    }
}

async function listenPhoneServicesStatus(): Promise<IntegrationPhoneServicesResponse> {
    return new Promise<IntegrationPhoneServicesResponse>((resolve) => {
        if (makeIntegrationPhoneServicesRegister) {
            const eventHandler = (sessionData: IntegrationPhoneServicesResponse) => {
                Logger.info(
                    `User subscribed to the Phone services event event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_PHONE_SERVICES_EVENT_TYPE, "integrationPhoneServices");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_PHONE_SERVICES,
                eventHandler
            );

            makeIntegrationPhoneServicesRegister = false;
        }
    });
}

export async function getPhoneServicesData(): Promise<IntegrationPhoneServicesResponse> {
    if (window?.isWebexInitalized) {
        return listenPhoneServicesStatus();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getPhoneServicesData();
    }
}

//error subscription event

async function listenErrorEventResponse(): Promise<IntegrationErrorResponse> {
    return new Promise<IntegrationErrorResponse>((resolve) => {
        if (makeIntegrationErrorRegister) {
            const eventHandler = (sessionData: IntegrationErrorResponse) => {
                Logger.info(
                    `User recieved the error event '${sessionData.data.eventType}'`
                );
                eventEmitter.dispatch(EventType.INTEGRATION_ERROR_EVENT_TYPE, "integrationError");
                resolve(sessionData); // Resolve the promise with the event payload
            };

            window.webex.internal.mercury.on(
                DATA_PIPE_LINE_EVENTS.INTEGRATION_ERROR,
                eventHandler
            );

            makeIntegrationErrorRegister = false;
        }
    });
}

export async function getErrorEventData(): Promise<IntegrationErrorResponse> {
    if (window?.isWebexInitalized) {
        return listenErrorEventResponse();
    } else {
        await new Promise((f) => setTimeout(f, 1000));
        return getErrorEventData();
    }
}
