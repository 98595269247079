import { ICallSettingsAdapter } from "@webex/component-adapter-interfaces/dist/esm/src";
import { t } from "i18next";
import { Observable, ReplaySubject, from } from "rxjs";
import { CALL_FORWARDING, MAKE_A_CALL_KEYS, RESPONSE_STATUS } from "../../../../adapter-interfaces/src";
import { removeLocalStorageItem, setLocalStorageItem } from "../../../../logger/src/LocalStorage";
import { Logger } from "../../../../logger/src/Logger";
import { getAttendantConsoleFeatuteToggle, getCallSetting, getCXEssentialsFeature } from "../../services/WebexCallSettingsService";
import { submitMetrics } from "../../services/WebexMetricsService";

export class WebexCallSettingsAdapter implements ICallSettingsAdapter {
    observables$!: ReplaySubject<string>;

    parseCallForwardData(response: string) {
        return response ? response : t("donotForwardCalls") as string;
    }

    refreshCallForward() {
        const data$ = from(getCallSetting());
        data$.subscribe((data) => {
            let callForwardSettingsLatency;
            const startTime = new Date();
            let endTime;
            if (data.statusCode === RESPONSE_STATUS.STATUSCODE_200) {
                Logger.info(`WebexCallForwardService: CallForwardSettingsSuccess-200: done.`);
                removeLocalStorageItem(MAKE_A_CALL_KEYS.CALL_FORWARD_SETTING_INTERVAL);
                window.dispatchEvent(new Event("storage"));
                this.observables$.next(this.parseCallForwardData(data.data?.callSetting?.destination as string));
                endTime = new Date();
                callForwardSettingsLatency =
                  Math.floor(endTime.getTime() - startTime.getTime()) / 1000;
                submitMetrics(
                  {
                    metricEvent: CALL_FORWARDING.CallForwardSettingsLatency,
                    callForwardSettingsLatency: `${callForwardSettingsLatency}`,
                  }
                );
                submitMetrics(
                  {
                    metricEvent: CALL_FORWARDING.CallForwardSettingsReturnCode,
                    callForwardSettingsApiReturnCode: `${data?.statusCode}`,
                  }
                );
            } else {
                setLocalStorageItem(MAKE_A_CALL_KEYS.CALL_FORWARD_SETTING_INTERVAL, true);
                window.dispatchEvent(new Event("storage"));
                if (data?.message === RESPONSE_STATUS.FAILURE) {
                  Logger.error(`CallForwardSettings.(): got error: Status Code '${data?.statusCode}'.`);
                }
                endTime = new Date();
                callForwardSettingsLatency = Math.floor(
                  (endTime.getTime() - startTime.getTime()) / 1000
                );
                submitMetrics(
                  {
                    metricEvent: CALL_FORWARDING.CallForwardSettingsLatency,
                    callForwardSettingsLatency: `${callForwardSettingsLatency}`,
                  }
                );
                submitMetrics(
                  {
                    metricEvent: CALL_FORWARDING.CallForwardSettingsReturnCode,
                    callForwardSettingsApiReturnCode: `${data.statusCode}`,
                  }
                );
              }
        });
    }

    getCallForwardSettings(): Observable<string> {
        if (!this.observables$) {
            this.observables$ = new ReplaySubject<string>();
            this.refreshCallForward();
        }
        return this.observables$;
    }

    checkAttendantConsoleFeatureToggle(): Observable<boolean> {
       return from(getAttendantConsoleFeatuteToggle());
    }

    checkCXEssentialsFeature(): Observable<boolean> {
      return from(getCXEssentialsFeature());
   }
}
