import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IWebexCallHistoryHandle,
  IWebexSpeedDialsHandle,
  IWebexVoicemailHandle,
  WebexCallHistory,
  WebexDialpad,
  WebexSpeedDials,
  WebexVoicemail,
} from '../packages/widgets/src';

import { ButtonCircle, PopoverNext as Popover, TextToast } from '@momentum-ui/react-collaboration';
import { ISpeedDialRecord } from '@webex/component-adapter-interfaces/dist/esm/src';
import { TabBar, TabBarItem } from '../components/TabBar';
import { TopBar } from '../components/TopBar';
import { PhoneServicesAlert } from '../components/PhoneServicesAlert';
import { useMsTeamsTheme } from "../hooks/useMsTeamsTheme";
import { makeCrossLaunchCall } from '../ms-teams-sdk/service/CrossLaunchService';
import { getWebexTokens, presenceUserMapping } from '../ms-teams-sdk/service/MsTeamsAuthenticationService';
import {
  getLocalStorageItem,
  removeLocalStorageItem
} from '../ms-teams-sdk/util/LocalStorageUtil';
import { getMSTeamsContext } from '../ms-teams-sdk/util/MsTeamsContextUtil';
import {
  CH_STORAGE_KEYS, VM_STORAGE_KEYS, WEBEX_STORAGE_KEYS
} from '../packages/adapter-interfaces/src';
import { useLocalStorageNotification } from "../packages/widgets/src/hooks/useLocalStorageNotification";
import { initWebex } from "../packages/widgets/src/services/WebexInstanceService";
import { LOCALIZATION_CODES, SD_SUCCESS_NOTICATION } from "../packages/widgets/src/utils/MetricUtils";
import { CommonErrorAlert } from './Alerts/CommonErrorAlert';
import './LandingPage.scss';
import { callVoicemailURL } from '../ms-teams-sdk/util/TobarUtils';
import { getDataPipelineSubscription } from '../packages/widgets/src/services/WebexEventListener';
import { EventType, eventEmitter } from '../packages/widgets/src/utils/eventEmitter';
import useEventListeners from '../packages/widgets/src/hooks/useEventListeners';
import { Logger } from '../packages/logger/src';

/**
 * Main landing page
 *
 * @returns {JSX.Element} React element containing the tab page
 */
export const LandingPage = (): JSX.Element => {
  useEffect(() => {
    presenceUserMapping();
  }, [])
  const [theme] = useMsTeamsTheme();
  const [unreadMissedCalls, setUnreadMissedCalls] = useState<number>();
  const [unreadVoicemails, setUnreadVoicemails] = useState<number>();
  const [isVmPlayErrorMsg, setIsVmPlayErrorMsg] = useState<boolean>(false);
  const [isAddSpeedDialDisabled, setIsAddSpeedDialDisabled] = useState<boolean>(false);
  const [vmListErrMsg, setVmListErrMsg] = useState<string>('');
  const [chListErrMsg, setChListErrMsg] = useState<string>('');
  const [webexInstErrMsg, setWebexInstErrMsg] = useState<string>('');
  const [isShowVMTab, setIsShowVMTab] = useState<any>(true);
  const [isLocaleGerman, setIsLocaleGerman] = useState<boolean>(false);
  const { t } = useTranslation();
  const callHistoryRef = useRef<IWebexCallHistoryHandle>(null);
  const voicemailRef = useRef<IWebexVoicemailHandle>(null);
  const speedDialsRef = useRef<IWebexSpeedDialsHandle>(null);
  const speedDialAddButtonRef = useRef<HTMLButtonElement>(null);
  const userID = 'user1';
  const { refreshSubscriptionEventData, subscriptionEventData, refreshErrorEventData, errorEventData  } = useEventListeners();
  const sdSuccessAdd = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_ADD,
    2000
  );
  const sdSuccessEdit = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_EDIT,
    2000
  );
  const sdSuccessDelete = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_DELETE,
    2000
  );
  const sdSuccessReorder = useLocalStorageNotification(
    SD_SUCCESS_NOTICATION.SD_SUCCESS_REORDER,
    2000
  );  
  const reInitWebexInstance = async () => {
    try {
      const webexTokens = await getWebexTokens(false);
      if (!(window?.isWebexInitalized)) {
        initWebex(webexTokens?.access_token as string)
      }
    }
    catch (error) {
      throw error;
    }
  }

  const onHideHandler = () => {
    setIsVmPlayErrorMsg(false)
  };

  const onHideVmListErr = () => {
    setVmListErrMsg('');
    removeLocalStorageItem(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG);
    window.dispatchEvent(new Event("storage"));
  }

  const onHideChListErr = () => {
    setChListErrMsg('');
    removeLocalStorageItem(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG);
    window.dispatchEvent(new Event("storage"));
  }

  const onHideWebexInstErr = () => {
    setWebexInstErrMsg('');
    removeLocalStorageItem(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR);
    window.dispatchEvent(new Event("storage"));
  }

  const handleSetIsVmPlayErrorMsg = (newValue: boolean) => {
    setIsVmPlayErrorMsg(newValue);
  }

  const addSpeedDialDisabled = (isDisabled: boolean) => {
    setIsAddSpeedDialDisabled(isDisabled);
  }

  const fetchContextLocale = async () => {
    const context = await getMSTeamsContext();
    if (context?.app?.locale === LOCALIZATION_CODES.DE_DE) {
      setIsLocaleGerman(true)
    }
  };

  const handleRecentCallsTabPress = () => {
    callHistoryRef.current?.refreshCallHistory();
  }

  const handleVoicemailTabPress = () => {
    voicemailRef.current?.refreshVoicemails();
    callHistoryRef.current?.postMissedCallCount();
  }

  const focusSpeedDialAddButton = () => {
    if (speedDialAddButtonRef.current) {
      speedDialAddButtonRef.current.focus();
      speedDialAddButtonRef.current.classList.add("md-focus-ring-wrapper");
  
      const removeFocusClass = () => {
        speedDialAddButtonRef.current!.classList.remove("md-focus-ring-wrapper");
      };
  
      speedDialAddButtonRef.current.removeEventListener('blur', removeFocusClass);
      speedDialAddButtonRef.current.addEventListener('blur', removeFocusClass);
    }
  };

  // Cross launch to the Webex app for voicemail calls
  const vmCrossLaunchClick = () => {
      window.open(callVoicemailURL);
  };

  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG);
      if (stringValue) {
        setVmListErrMsg(stringValue);
      } else {
        setVmListErrMsg('');
      }
    };
    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleCHERRORLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(CH_STORAGE_KEYS.CH_ERROR_LIST_MSG);
      if (stringValue) {
        setChListErrMsg(stringValue);
      } else {
        setChListErrMsg('');
      }
    };
    window.addEventListener('storage', handleCHERRORLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleCHERRORLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleWebexInstLocalStorageChange = (event: StorageEvent) => {
      const stringValue = getLocalStorageItem<string>(WEBEX_STORAGE_KEYS.WEBEX_INSTANCE_ERROR);
      if (stringValue) {
        setWebexInstErrMsg(stringValue);
      } else {
        setWebexInstErrMsg('');
      }
    };
    window.addEventListener('storage', handleWebexInstLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleWebexInstLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleWebexInstUnAuthorizeUser = (event: StorageEvent) => {
      const isWebexInstanceTokenExpired = getLocalStorageItem<string>(VM_STORAGE_KEYS.WEBEX_INSTANCE_TOKEN_EXPIRED);
      if (isWebexInstanceTokenExpired) {
        removeLocalStorageItem(VM_STORAGE_KEYS.WEBEX_INSTANCE_TOKEN_EXPIRED);
        //resetting init window check as instance token is expired
        window.isWebexInitalized = false;
        reInitWebexInstance();
        voicemailRef.current?.refreshVoicemails();
        callHistoryRef.current?.refreshCallHistory();

      }
    };
    window.addEventListener('storage', handleWebexInstUnAuthorizeUser);

    return () => {
      window.removeEventListener('storage', handleWebexInstUnAuthorizeUser);
    };
  }, []);

  useEffect(() => {
    const handleVoicemailTab = (event: StorageEvent) => {
      const isShowVMTabValue = getLocalStorageItem<string>(VM_STORAGE_KEYS.IS_HIDE_VM_TAB);
      if (isShowVMTabValue) {
        setIsShowVMTab(false);
      } else {
        setIsShowVMTab(true);
      }
    };
    window.addEventListener('storage', handleVoicemailTab);

    return () => {
      window.removeEventListener('storage', handleVoicemailTab);
    };
  }, []);

  useEffect(() => {
    fetchContextLocale();
  }, [])

  useEffect(() => {
    const fetchDataSubscription = async () => {
        try {
            await getDataPipelineSubscription();
        } catch (error) {
            console.error("Error in useEffect while getting data pipeline subscription:", error);
        }
    };

    fetchDataSubscription();
}, []);

useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      console.log("App is in focus");
      // Handle logic when the app comes into focus
    } else {
      console.log("App is not in focus");
      // Handle logic when the app goes out of focus
    }
  };

  // Add event listener for visibility change
  document.addEventListener("visibilitychange", handleVisibilityChange);

  // Cleanup the event listener when the component unmounts
  return () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
}, []);

//Initial event subscription

useEffect(() => {
  console.log('outside useEffect')
  if (
    subscriptionEventData.id ||
    subscriptionEventData.subscriptionId ||
    subscriptionEventData.status
  ) {
    Logger.info(
      `Subscription event status:', ${subscriptionEventData.status}`
    );
    // Logic to run when subscriptionEventData is fully populated
    console.log('Subscription event data is fully populated:', subscriptionEventData);
    // Optionally call refreshSubscriptionEventData or other functions here
    if(subscriptionEventData.status === 'terminated') {
      console.log('entering termination')
      Logger.info(
        `Subscription event is terminated`
      );
  }
  }
}, [subscriptionEventData]); // Dependency array includes subscriptionEventData


eventEmitter.subscribe(EventType.INTEGRATION_SUBSCRIPTION_EVENT_TYPE, 'integrationSubscription', () => {
  refreshSubscriptionEventData()
});

//error subscription

useEffect(() => {
  console.log('outside useEffect')
  // Check if all necessary fields in subscriptionEventData are non-empty
  if (
    errorEventData.id && errorEventData.errorCode
   
  ) {
    Logger.info(
      `Error subscription event description:', ${errorEventData.errorDescription}`
    );

    // Optionally call refreshSubscriptionEventData or other functions here
    if(errorEventData.errorCode) {
      Logger.info(
        `Error subscription event error code:', ${errorEventData.errorCode}`
      );
  }
  }
}, [errorEventData]); // Dependency array includes subscriptionEventData

eventEmitter.subscribe(EventType.INTEGRATION_ERROR_EVENT_TYPE, 'integrationError', () => {
  refreshErrorEventData()
});

  return (
    <div className="landing-page">
      <header className="landing-page__header">
      <PhoneServicesAlert />
        <TopBar />
      </header>
      <section className="landing-page__content">
        <TabBar>
          <TabBarItem label={t('dialpad')}>
            <WebexDialpad theme={theme} />
          </TabBarItem>

          <TabBarItem
            label={t('speedDial')}
            onActiveRightItem={
              <div className='tooltip-wrapper'>
                {!isAddSpeedDialDisabled &&
                  <Popover
                    color="primary"
                    delay={[0, 0]}
                    placement="bottom"
                    showArrow={false}
                    trigger="mouseenter"
                    className="tooltip"
                    triggerComponent={
                      <ButtonCircle
                        aria-label={t('label.speed_dial.add')}
                        ghost
                        onPress={() => {
                          speedDialsRef.current?.showSearchSpeedDials();
                        }}
                        size={28}
                        ref={speedDialAddButtonRef}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none">
                          <path d="M11 5.5H6.5V1C6.5 0.867392 6.44732 0.740215 6.35355 0.646446C6.25979 0.552678 6.13261 0.5 6 0.5C5.86739 0.5 5.74021 0.552678 5.64645 0.646446C5.55268 0.740215 5.5 0.867392 5.5 1V5.5H1C0.867392 5.5 0.740215 5.55268 0.646447 5.64645C0.552679 5.74021 0.5 5.86739 0.5 6C0.5 6.13261 0.552679 6.25979 0.646447 6.35355C0.740215 6.44732 0.867392 6.5 1 6.5H5.5V11C5.5 11.1326 5.55268 11.2598 5.64645 11.3536C5.74021 11.4473 5.86739 11.5 6 11.5C6.13261 11.5 6.25979 11.4473 6.35355 11.3536C6.44732 11.2598 6.5 11.1326 6.5 11V6.5H11C11.1326 6.5 11.2598 6.44732 11.3536 6.35355C11.4473 6.25979 11.5 6.13261 11.5 6C11.5 5.86739 11.4473 5.74021 11.3536 5.64645C11.2598 5.55268 11.1326 5.5 11 5.5Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.95" />
                        </svg>
                      </ButtonCircle>
                    }
                    variant="small"
                  >
                    <p>{t('label.speed_dial.add')}</p>
                  </Popover>
                }

                {isAddSpeedDialDisabled &&
                  <Popover
                    color="primary"
                    delay={[0, 0]}
                    placement="bottom"
                    showArrow={false}
                    trigger="mouseenter"
                    className="tooltip"
                    triggerComponent={
                      <ButtonCircle
                        className="btn-disabled"
                        onPress={() => {
                        }}
                        size={28}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none">
                          <path d="M11 5.75H6.25V1C6.25 0.933696 6.22366 0.870107 6.17678 0.823223C6.12989 0.776339 6.0663 0.75 6 0.75C5.9337 0.75 5.87011 0.776339 5.82322 0.823223C5.77634 0.870107 5.75 0.933696 5.75 1V5.75H1C0.933696 5.75 0.870107 5.77634 0.823223 5.82322C0.776339 5.87011 0.75 5.9337 0.75 6C0.75 6.0663 0.776339 6.12989 0.823223 6.17678C0.870107 6.22366 0.933696 6.25 1 6.25H5.75V11C5.75 11.0663 5.77634 11.1299 5.82322 11.1768C5.87011 11.2237 5.9337 11.25 6 11.25C6.0663 11.25 6.12989 11.2237 6.17678 11.1768C6.22366 11.1299 6.25 11.0663 6.25 11V6.25H11C11.0663 6.25 11.1299 6.22366 11.1768 6.17678C11.2237 6.12989 11.25 6.0663 11.25 6C11.25 5.9337 11.2237 5.87011 11.1768 5.82322C11.1299 5.77634 11.0663 5.75 11 5.75Z" fill="var(--mds-color-theme-text-primary-normal)" fillOpacity="0.4" />
                        </svg>
                      </ButtonCircle>
                    }
                    variant="small"
                  >
                    <p>{t('label.speed_dial.max_reached')}</p>
                  </Popover>
                }
              </div>
            }
          >
            <WebexSpeedDials
              onPress={(item: ISpeedDialRecord) => {
                makeCrossLaunchCall(
                  item?.currentCallAddress as string,
                  item.callType === 'handset'
                );
              }}
              onAddPress={() => {
                speedDialsRef.current?.showSearchSpeedDials();
              }}
              onAudioCallPress={(item: ISpeedDialRecord) => {
                makeCrossLaunchCall(
                  item?.currentCallAddress as string,
                  true
                );
              }}
              onVideoCallPress={(item: ISpeedDialRecord) => {
                makeCrossLaunchCall(
                  item?.currentCallAddress as string,
                  false
                );
              }}
              userID={userID}
              ref={speedDialsRef}
              contacts={[]}
              addSpeedDialDisabled={addSpeedDialDisabled}
              focusSpeedDialAddButton={focusSpeedDialAddButton}
              theme={theme}
            />
          </TabBarItem>
        </TabBar>
        <div className='landing-page__wrapperVMCH'>
          {webexInstErrMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('errorWebexInst')}
                onClose={onHideWebexInstErr}
              />
            </div>
          }
          {isVmPlayErrorMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('vmPlayErrorMessage')}
                onClose={onHideHandler}
              />
            </div>
          }
          {vmListErrMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('errorListVM')}
                onClose={onHideVmListErr}
              />
            </div>}
          {chListErrMsg &&
            <div className='landing-page__bannerVM'>
              <CommonErrorAlert
                errorMessage={t('errorListCH')}
                onClose={onHideChListErr}
              />
            </div>
          }
          <TabBar>
            <TabBarItem
              label={t('recentCalls')}
              badgeCount={unreadMissedCalls}
              onTabPress={handleRecentCallsTabPress}
            >
              <WebexCallHistory
                noHistoryMessage={t('calls.no_calling_history')}
                userID={userID}
                ref={callHistoryRef}
                isLocaleGerman={isLocaleGerman}
                updateMissedCallCount={(unreadCount: number) =>
                  setUnreadMissedCalls(unreadCount)
                }
                labelUnknown={t("calls.call_with_NoName_NoAddress") || "Unknown"}
              />
            </TabBarItem>
            {isShowVMTab &&
              <TabBarItem
                label={t('voicemail')}
                badgeCount={unreadVoicemails}
                onTabPress={handleVoicemailTabPress}
                onActiveRightItem = {
                  // Voicemail cross launch icon display
                  <ButtonCircle size={40} ghost key="1" aria-label={t('callVoicemail')} onPress={vmCrossLaunchClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.3893 10.0001H19.6104C19.8852 10.0001 20.1538 9.91865 20.3822 9.76601C20.6107 9.61336 20.7887 9.3964 20.8939 9.14257C20.999 8.88873 21.0265 8.60942 20.9729 8.33995C20.9193 8.07048 20.787 7.82295 20.5927 7.62867C20.3985 7.4344 20.1509 7.30209 19.8815 7.24849C19.612 7.19489 19.3327 7.22241 19.0788 7.32755C18.825 7.43269 18.608 7.61075 18.4554 7.83919C18.3028 8.06764 18.2213 8.33622 18.2213 8.61097C18.2232 8.74302 18.2442 8.87409 18.2836 9.00013H17.7161C17.7555 8.87409 17.7765 8.74302 17.7784 8.61097C17.7784 8.33622 17.697 8.06764 17.5443 7.83919C17.3917 7.61075 17.1747 7.43269 16.9209 7.32755C16.667 7.22241 16.3877 7.19489 16.1183 7.24849C15.8488 7.30209 15.6013 7.4344 15.407 7.62867C15.2127 7.82295 15.0804 8.07047 15.0268 8.33994C14.9732 8.60941 15.0007 8.88873 15.1058 9.14257C15.211 9.3964 15.389 9.61336 15.6175 9.766C15.8459 9.91865 16.1145 10.0001 16.3893 10.0001ZM19.2213 8.61097C19.2212 8.53393 19.244 8.45861 19.2868 8.39454C19.3296 8.33046 19.3904 8.28051 19.4616 8.25101C19.5327 8.22151 19.611 8.21378 19.6866 8.22879C19.7622 8.24381 19.8316 8.2809 19.886 8.33538C19.9405 8.38985 19.9776 8.45926 19.9926 8.53482C20.0076 8.61038 19.9999 8.68869 19.9704 8.75986C19.9409 8.83102 19.891 8.89184 19.8269 8.93461C19.7628 8.97738 19.6875 9.00018 19.6104 9.00013C19.5073 8.99994 19.4084 8.95888 19.3355 8.88594C19.2625 8.813 19.2215 8.71412 19.2213 8.61097ZM16.3893 8.22181C16.4662 8.22181 16.5415 8.24464 16.6055 8.2874C16.6695 8.33016 16.7193 8.39094 16.7488 8.46205C16.7783 8.53316 16.786 8.6114 16.7709 8.68689C16.7559 8.76238 16.7189 8.83173 16.6644 8.88615C16.61 8.94058 16.5407 8.97764 16.4652 8.99265C16.3897 9.00767 16.3114 8.99996 16.2403 8.97051C16.1692 8.94105 16.1084 8.89118 16.0657 8.82718C16.0229 8.76318 16.0001 8.68794 16.0001 8.61097C16.0003 8.50782 16.0414 8.40894 16.1143 8.336C16.1872 8.26306 16.2861 8.22201 16.3893 8.22181Z" fill="var(--mds-color-theme-text-primary-normal)" fill-opacity="0.95"/>
                <path d="M20.5001 11.0001H15.5001C15.3675 11.0001 15.2403 11.0528 15.1465 11.1466C15.0528 11.2403 15.0001 11.3675 15.0001 11.5001C15.0001 11.6327 15.0528 11.7599 15.1465 11.8537C15.2403 11.9474 15.3675 12.0001 15.5001 12.0001H20.5001C20.6327 12.0001 20.7599 11.9474 20.8537 11.8537C20.9474 11.7599 21.0001 11.6327 21.0001 11.5001C21.0001 11.3675 20.9474 11.2403 20.8537 11.1466C20.7599 11.0528 20.6327 11.0001 20.5001 11.0001Z" fill="var(--mds-color-theme-text-primary-normal)" fill-opacity="0.95"/>
                <path d="M10.5536 17.4213C14.0515 20.9192 17.1854 20.6889 18.0859 19.7885L18.6045 19.2699C18.9068 18.9669 19.0766 18.5564 19.0766 18.1284C19.0766 17.7004 18.9068 17.2899 18.6045 16.987L17.3425 15.725C17.0405 15.4252 16.6318 15.2575 16.2063 15.2586C15.7807 15.2597 15.3729 15.4295 15.0724 15.7309L14.4937 16.3096C13.7991 16.1653 13.1647 15.8132 12.6749 15.3C12.1619 14.8104 11.8093 14.1769 11.6636 13.4829L12.2474 12.8997C12.543 12.5845 12.7038 12.1665 12.6958 11.7345C12.6877 11.3025 12.5114 10.8907 12.2043 10.5868L10.9879 9.37039C10.685 9.06809 10.2744 8.89832 9.84647 8.89832C9.41848 8.89832 9.00798 9.06809 8.70501 9.37039L8.18641 9.88899C7.28593 10.7894 7.05565 13.9234 10.5536 17.4213ZM9.4121 10.0775C9.52736 9.9624 9.68358 9.89776 9.84645 9.89776C10.0093 9.89776 10.1655 9.9624 10.2808 10.0775L11.4972 11.2939C11.6179 11.4097 11.6897 11.5673 11.6978 11.7344C11.7058 11.9015 11.6496 12.0653 11.5407 12.1922L10.8494 12.8828C10.3091 13.4231 11.0127 15.0521 11.9677 16.0071C13.0184 17.0577 14.5963 17.6212 15.0941 17.1233L15.7795 16.438C15.8923 16.3239 16.0458 16.2592 16.2063 16.2581C16.3667 16.257 16.5211 16.3196 16.6354 16.4321L17.8974 17.6941C18.0125 17.8093 18.0771 17.9655 18.0771 18.1284C18.0771 18.2913 18.0125 18.4475 17.8974 18.5628L17.3788 19.0814C16.845 19.6151 14.2413 19.6949 11.2606 16.7142C8.27996 13.7335 8.35971 11.1299 8.8935 10.5961L9.4121 10.0775Z" fill="var(--mds-color-theme-text-primary-normal)" fill-opacity="0.95"/>
              </svg>
                </ButtonCircle>
                        /* Voicemail unread badge counter display if there are multilines*/
                    //  { (unreadVoicemails || 0) >= 1 &&
                    //   <div className='unread-bubble' role="img" aria-label={t('unreadVoicemail')} />
                    // }} 
                }
              >
                <WebexVoicemail
                  handleSetIsVmPlayErrorMsg={handleSetIsVmPlayErrorMsg}
                  updateUnreadCount={(unreadCount: number) =>
                    setUnreadVoicemails(unreadCount)
                  }
                  ref={voicemailRef}
                  isLocaleGerman={isLocaleGerman}
                />
              </TabBarItem>
            }
          </TabBar>
        </div>   
      </section>
      <section role="status">
        {sdSuccessAdd && (
          <TextToast
            className='tostCenter'
            text={t('successAdd')}
            aria-live={t('successAdd')}
          />
        )}
        {sdSuccessDelete && (
          <TextToast
            className='tostCenter'
            text={t('successDelete')}
            aria-live={t('successDelete')}
          />
        )}
        {sdSuccessEdit && (
          <TextToast
            className='tostCenter'
            text={t('successEdit')}
            aria-live={t('successEdit')}
          />
        )}
        {sdSuccessReorder && (
          <TextToast
            className='tostCenter'
            text={t('successReorder')}
            aria-live={t('successReorder')}
          />
        )}
      </section>
    </div>
  );
};
