import { t } from "i18next";
import { Observable, ReplaySubject, from } from "rxjs";
import {
  ERROR_RESPONSE_TEXT,
  IVoiceMailDeleteResponse,
  IVoiceMailReadResponse,
  IVoiceMailResponse,
  IVoiceMailTranscriptMessage,
  IVoiceMessageContentResponse,
  IVoicemailAdapter,
  IWebexVoicemail,
  RESPONSE_STATUS,
  VM_STORAGE_KEYS,
} from "../../../../adapter-interfaces/src";
import {
  removeLocalStorageItem
} from "../../../../logger/src";
import { Logger, formatError } from "../../../../logger/src/Logger";
import { submitMetrics } from "../../services/WebexMetricsService";
import {
  deleteVoiceMail,
  getVMTranscript,
  getVoiceMailList,
  getVoiceMessage,
  readVoiceMessage,
} from "../../services/WebexVoicemailService";
import { CustomError } from '../../utils/CustomError';
import { voiceMailDurationMetric, voicemail } from "../../utils/MetricUtils";
import { CALLING_BACKEND, getCallingBackEnd, setLocalStoragewithDispatchEvent } from "../../utils/callingUtils";
import { normalizeAddress } from '../../utils/normalizeAddress';

export class WebexVoicemailAdapter implements IVoicemailAdapter {
  observables$!: ReplaySubject<IWebexVoicemail[]>;

  parseVoicemailData(response: IVoiceMailResponse): IWebexVoicemail[] {
    let output: IWebexVoicemail[] = [];
    output = response?.data?.voicemailList?.map((item) => {
      const voiceMailResponseData: IWebexVoicemail = {
        id: item?.messageId?.$,
        name: item?.callingPartyInfo?.name?.$ ? item?.callingPartyInfo?.name?.$ : (!item?.callingPartyInfo?.name?.$ && !item?.callingPartyInfo?.address?.$) ? t("calls.call_type_unknown") : '',
        address: normalizeAddress(item?.callingPartyInfo?.address?.$),
        date: new Date(Number(item?.time?.$)).toISOString(),
        unread: item?.read ? false : true,
        duration: Number(item?.duration?.$),
      };
      submitMetrics(
        {
          metricEvent: voiceMailDurationMetric,
          client_timestamp: voicemail,
          trackingId: voicemail,
          key: voicemail,
          messageId: voiceMailResponseData?.id,
          voiceMailDuration: `${(voiceMailResponseData?.duration)/1000}`,
        }
      );
      return voiceMailResponseData;
    });
    return output;
  }

  refresh() {
    const data$ = from(getVoiceMailList());
    data$.subscribe((data: IVoiceMailResponse) => {
      Logger.info(
        `WebexVoicemailAdapter.getVoiceMailList(): got status code with message: ${data?.statusCode} - ${data?.message}`
      );
      if (data.statusCode === RESPONSE_STATUS.STATUSCODE_200 || data.statusCode === RESPONSE_STATUS.STATUSCODE_204) {
        removeLocalStorageItem(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG);
        window.dispatchEvent(new Event("storage"));
        Logger.info(
          `WebexVoicemailAdapter.getVoiceMailList(): Voicemails Length: ${data?.data?.voicemailList?.length}`
        );
        this.observables$.next(this.parseVoicemailData(data));
      } else {
        if (data.message === RESPONSE_STATUS.FAILURE) {
          switch (data.statusCode) {
            case RESPONSE_STATUS.STATUSCODE_401:
              setLocalStoragewithDispatchEvent(VM_STORAGE_KEYS.WEBEX_INSTANCE_TOKEN_EXPIRED, true);
              break;
            case RESPONSE_STATUS.STATUSCODE_400:
              if (window?.isWebexInitalized && getCallingBackEnd() === CALLING_BACKEND.WXC) {
                Logger.info(
                  `WebexVoicemailAdapter.getVoiceMailList():Voicemail is not configured for WebexCalling User`
                );
              } else {
                setLocalStoragewithDispatchEvent(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG, ERROR_RESPONSE_TEXT.ERROR_400_500);
              }
              break;
            case RESPONSE_STATUS.STATUSCODE_404:
              if (window?.isWebexInitalized && getCallingBackEnd() === CALLING_BACKEND.UCM) {
                Logger.info(
                  `WebexVoicemailAdapter.getVoiceMailList():Voicemail is not configured for UCMC User`
                );
              } else {
                setLocalStoragewithDispatchEvent(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG, ERROR_RESPONSE_TEXT.ERROR_400_500);
              }
              break;
            case RESPONSE_STATUS.STATUSCODE_403:
            case RESPONSE_STATUS.STATUSCODE_408:
            case RESPONSE_STATUS.STATUSCODE_422:
            case RESPONSE_STATUS.STATUSCODE_500:
            case RESPONSE_STATUS.STATUSCODE_503:
              setLocalStoragewithDispatchEvent(VM_STORAGE_KEYS.VM_ERROR_LIST_MSG, ERROR_RESPONSE_TEXT.ERROR_400_500);
              break;
            default:
              Logger.info(
                `WebexVoicemailAdapter.getVoiceMailList(): Unhandled status code: ${data?.statusCode} - ${data?.message}`
              );
              break;
          }
        }
        Logger.error(
          `WebexVoicemailAdapter.getVoiceMailList(): got error message: '${formatError(
            data?.data?.error
          )}'.`
        );
        Logger.error(
          `WebexVoicemailAdapter.getVoiceMailList(): got status code: '${formatError(
            data?.statusCode
          )}'.`
        );
        // this.observables$.error(new Error(`Could not find voice mail data`));
        this.observables$.error(new CustomError(data.message, data.statusCode));
      }
    });
  }

  getAll(): Observable<IWebexVoicemail[]> {
    if (!this.observables$) {
      this.observables$ = new ReplaySubject<IWebexVoicemail[]>();
      this.refresh();
    }
    return this.observables$;
  }

  deleteVoicemail(ID: string): Observable<IVoiceMailDeleteResponse> {
    return from(deleteVoiceMail(ID));
  }

  markVoicemailRead(ID: string): Observable<IVoiceMailReadResponse> {
    return from(readVoiceMessage(ID));
  }

  getVoiceMessage(ID: string): Observable<IVoiceMessageContentResponse> {
    return from(getVoiceMessage(ID));
  }

  getVMTranscript(ID: string): Observable<IVoiceMailTranscriptMessage> {
    return from(getVMTranscript(ID));
  }
}
