import { Observable } from 'rxjs';

export interface IWebexVoicemail {
  id: string;
  name: string;
  address: string;
  unread: boolean;
  date: string;
  duration: number;
}

export enum RESPONSE_STATUS {
  STATUSCODE_200 = 200,
  STATUSCODE_204 = 204,
  STATUSCODE_400 = 400,
  STATUSCODE_401 = 401,
  STATUSCODE_500 = 500,
  STATUSCODE_403 = 403,
  STATUSCODE_404 = 404,
  STATUSCODE_408 = 408,
  STATUSCODE_503 = 503,
  STATUSCODE_422 = 422,
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  UNKNOWN = 'UNKNOWN'
}

export enum VoicemailTranscriptFetchStatus {
  ERROR = 'ERROR',
  FAILURE = 'FAILURE',
  NA = 'NA',
  PENDING = 'PENDING',
  READY = 'READY',
}

export enum ERROR_RESPONSE_TEXT {
  ERROR_400_500 = 'Failed to load',
  WEBEX_INST_ERROR = "Unable to reach"
}

export enum VM_STORAGE_KEYS {
  VOICEMAIL_DATA_MAP = 'voicemailDataMap',
  VOICEMAIL_ID_MAP = 'voicemailIdMap',
  VM_ERROR_LIST_MSG = 'vmErrorListMsg',
  WEBEX_INSTANCE_TOKEN_EXPIRED = 'webexInstanceTokenExpired',
  VM_LIST_ERROR_SHOW = 'vmListErrorShow',
  VM_TAB = 'Voicemail',
  CH_TAB = 'Recent calls',
  DIALPAD_TAB = 'Dialpad',
  SPEEDDIAL_TAB = 'Speed dial', 
  VM_ONE_MINUTE = 60000,
  VM_CACHED_DATA = 'vmCachedData',
  IS_HIDE_VM_TAB = 'isHideVMTab',
  IS_VM_NOT_CONFIGURED = 'isVMnotConfigured'
}

export enum VOICEMAIL_LIMIT {
  OFFSET = 0,
  OFFSET_LIMIT = 250,
}
export enum IDB_STORAGE {
  DB_NAME = 'VoiceMessageDB',
  STORE_NAME = 'voiceMessageStore',
  MAX_ITEMS = 5,
  READ_WRITE = 'readwrite',
  IDB_EXIST = 'indexedDB',
  DB_VERSION = 1
}
export enum IDB_VM_TRANSCRIPT_STORAGE {
  DB_NAME = 'VoiceMessageTranscriptDB',
  STORE_NAME = 'voiceMessageTranscriptStore',
  MAX_ITEMS = 5,
  READ_WRITE = 'readwrite',
  IDB_EXIST = 'indexedDB',
  DB_VERSION = 1
}

export enum WEBEX_STORAGE_KEYS {
  WEBEX_INSTANCE_ERROR = 'webexInstanceError',
}
export interface IDuration {
  $: string;
}

export interface IName {
  $: string;
}

export interface IUserId {
  $: string;
}

export interface IAddress {
  $: string;
}

export interface ICallingPartyInfo {
  name: IName;
  userId: IUserId;
  address: IAddress;
}

export interface ITime {
  $: string;
}

export interface IMessageId {
  $: string;
}

export interface IVoiceMailList {
  duration: IDuration;
  callingPartyInfo: ICallingPartyInfo;
  time: ITime;
  messageId: IMessageId;
  read: string;
}

export interface IVoiceMailData {
  voicemailList: IVoiceMailList[];
  error?: string;
}

export interface IVoiceMailResponse {
  statusCode: number;
  data: IVoiceMailData;
  message: string;
}

export interface IVoiceMailDeleteData { }

export interface IVoiceMailDeleteResponse {
  statusCode: number;
  data: IVoiceMailDeleteData;
  message: string;
}

export interface IVoiceMessageContent {
  type: string;
  content: string;
  error?: string;
}

export interface IVoiceMessageContentData {
  voicemailContent: IVoiceMessageContent;
}

export interface IVoiceMessageContentResponse {
  statusCode: number;
  data: IVoiceMessageContentData;
  message: string;
  voiceMessageId?: string;
  createdAt?: string;
}

export interface IVoiceMailReadData { }

export interface IVoiceMailReadResponse {
  statusCode: number;
  data: IVoiceMailReadData;
  message: string;
}
export interface IVoicemailTranscript {
  voicemailTranscript:string
}
export interface IVoiceMailTranscriptMessage {
  statusCode: number;
  data: IVoicemailTranscript;
  message: string;
  voiceMessageId?: string;
  createdAt?: string;
}

export interface IVoicemailAdapter {
  refresh(): void;

  getAll(): Observable<IWebexVoicemail[]>;

  deleteVoicemail(ID: string): Observable<IVoiceMailDeleteResponse>;

  markVoicemailRead(ID: string): Observable<IVoiceMailReadResponse>;

  getVoiceMessage(ID: string): Observable<IVoiceMessageContentResponse>;

  getVMTranscript(ID: string): Observable<IVoiceMailTranscriptMessage>;
  
  getWebexCallingBackend(): Observable<boolean>;
}
